import React from 'react'
import { useStateContext } from '../../context/StateContext';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


function CartItems() {
    const { cartItems } = useStateContext();

    const settings = {
        dots: true,
        autoplay: true,
        
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };
 
  return (
    <div className='checkout-carousel'>
        <Slider {...settings}>
        {cartItems.length >= 1 && cartItems.map((item, index) => (
            <div className="checkout-item-list" key={index}>                              
              <div className='checkout-item-header' style={{ width: '80%'}} >                   
                <img className="checkout-item-image" src={item.image.default} style={{ width: '80%'}} alt='items'/>
              </div>                            
              <div className='checkout-item-details'style={{height: '42px'}}>               
                <p className="checkout-item-name" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}>{item.name.substring(0,40)}</p>                    
              </div>                              
              {/*<p className="productcard-unit" style={{fontSize: "10px", color: '#828282', marginTop:'-10px'}}>{inside.unitOfSize.size} {inside.unitOfSize.abbreviation} - {inside.pricePerUnit}</p> */} 
            </div>                             
            ))}
        </Slider>     
    </div>
  )
}

export default CartItems