import { React, useEffect,} from 'react';
import { IoSearch, IoClose } from "react-icons/io5";
import { AnimatePresence, motion } from "framer-motion";
import  MoonLoader  from 'react-spinners/MoonLoader';
import useDebounce from '../../../Hooks/debounceHook';
import ProductSearch from './ProductSearch';
import { useStateContext } from '../../../context/StateContext';
import {NavLink} from 'react-router-dom'

function SearchBar() {
    const {  isExpanded, parentRef, isClickedOutside, inputRef, searchQuery, isLoading, SearchedProducts, 
        noProducts, isEmpty, changeHandler, expandContainer, collapseContainer, containerVariant, containerTransition,
        searchProducts} = useStateContext();  

        


    useEffect(() => {
        if(isClickedOutside) collapseContainer();
        // eslint-disable-next-line
    }, [isClickedOutside]);

    useDebounce(searchQuery, 500, searchProducts);      

  return (
    <motion.div className='SearchBarContainer' 
        animate={isExpanded ? 'expanded' : 'collapsed'} 
        variants={containerVariant}
        transition={containerTransition}
        ref={parentRef}
    >

        <div className='SearchInputContainer'>
            <input className='SearchInput' 
                placeholder='What are you looking for ?' 
                onFocus={expandContainer}
                ref={inputRef}
                value={searchQuery}
                onChange={changeHandler}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        window.location = `/products/search/${searchQuery}`
                    }
                }}
            />
            <span className='SearchIcon' onClick={() => window.location = `/products/search/${searchQuery}`}> < IoSearch/></span>
            <AnimatePresence>
                {isExpanded && ( <motion.span className='CloseIcon' 
                    onClick={collapseContainer} 
                    key='close-icon'
                    initial = {{ opacity: 0 }}
                    animate = {{ opacity: 1 }}
                    exit = {{ opacity: 0 }}
                    transition = {{ duration: 0.2}}
                > 
                    < IoClose /> 
                </motion.span>
                )}
            </AnimatePresence>           
        </div>
        {isExpanded && (<span className='LineSeparator'></span>)}
        {isExpanded && (<div className='SearchContent'>
            {isLoading && ( 
                <div className='LoadingWrapepr'>
                <MoonLoader loading color='#000' size={20}/>
                </div>
            )}    

            {!isLoading && noProducts && 
            <div className='LoadingWrapepr'> 
                <span className='SearchWarningMessage'>Product Not Found!</span>
            </div>}

            {!isLoading &&  !isEmpty && 
                <>
                    {SearchedProducts?.list.slice(0,10).map((products) => (
                        <NavLink to={`/products/${products.id}/${products.name}`}>
                            <ProductSearch
                                key ={products.name} //id
                                thumbnailSrc={products.image} 
                                name={products.name} 
                            />
                        </NavLink>      
                    ))}
                    <NavLink to={`/products/search/${searchQuery}`}>
                        <p className='SeeAll-Search'>See All</p>
                    </NavLink> 
                </>
            }

            {!isLoading && isEmpty && !noProducts &&
                <div className='LoadingWrapepr'> 
                    <span className='SearchWarningMessage'>Search is empty</span>
                </div>
            } 

        </div>
        )}
    </motion.div>
  )
}


export default SearchBar