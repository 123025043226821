import {React, useState} from 'react'
import { useStateContext } from '../../context/StateContext';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom'

function DetailsOrders() {

    const [status, setStatus] = useState('pending');
    //const [category, setCategory] = useState('');

    const navigate = useNavigate();
    const {carts} = useStateContext();
    const {_id} = useParams();

    const cartinfo = { 
      status,

    }

    const handleCheckout = async () => {
      const response = await fetch(`https://api.click-n.com/update_cart/${_id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(cartinfo),
      });
      if(response.statusCode === 500) return; 
      // eslint-disable-next-line
      const data = await response.json();

      navigate('/order-list');

    } 

      return (  
        <div >
          {carts.filter((cart) => cart._id === _id).map((cart) => (
            <div className='OrderList-container'>
              <div>
                <div className='OrderList-inside-Data'>
                  <h2 className='OrderList-Cart'> Cart ID: <span className='ORderList-Span'> {cart._id}</span></h2>
                  <h2 className='OrderList-Cart'> Date Creation: <span className='ORderList-Span'>{cart.created_at.substring(0,11)}</span></h2> 
                      <select 
                        className='OrderList-select'
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}>
                        <option value='pending'> Pending </option>
                        <option value='closed'> Delivered </option>
                      </select>   
                </div>
                  <div className='OrderList-inner'>
                    {cart.items.map((inside)  => (
                      <div className='OrderListDetails-products'>
                        <div className='OrderListDetails-products-inner'>
                          <div className='OrderListDetails-img-div'>
                          <img className='OrderListDetails-img' src={inside.image} alt=''/>                        
                          </div>
                          <div className='OrderList-inside-product-name-div'>
                            <h2 className='OrderListDetails-product-Name'>
                              {inside.name}
                            </h2>
                            <h2 className='OrderListDetails-product-Name'>
                              {inside.quantity}
                            </h2>
                          </div>                      
                        </div>
                      </div>                    
                    ))}
                  </div>
                <button className='OrderListDetails-button' onClick={handleCheckout}>Update</button>
              </div>              
            </div>                     
          ))}       
      </div>  
    )
  
  
}

export default DetailsOrders




