import React from 'react'


function ProductSearch(props) {
    const { thumbnailSrc, name} = props;
  return (
    <div className='ProductSearchcontainer'>
      <div className='ProductSearchThumbnail'>
        <img className='img-thumbnail' src= {thumbnailSrc} alt=''/>
      </div>
      <h3 className='ProductSearchName'> {name} </h3>        
    </div>
  )
}

export default ProductSearch