import React from 'react'
import {OrderList} from "../Components/index";

function Orders() {
  return (
    <div className='order-container'>
        <OrderList />
    </div>
  )
}

export default Orders