//React
import React, {useState} from "react";
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import {QueryClient,QueryClientProvider,} from 'react-query'

//Pages
import Products  from "./Pages/Products";
import ProductDetails from './Pages/ProductDetails'
import EditProfile from './Pages/EditProfile'
import CategoryPage from './Pages/CategoryPage';
//import SubCat from './Pages/SubCat';
import CheckoutPage from './Pages/CheckoutPage';
import DetailsOrder from './Pages/DetailsOrder';
import Orders from './Pages/Orders';
import Terms from './Pages/terms';
import Privacy from './Pages/privacy';
//import SubCategoriesAll from './Pages/SubCategoriesAll'
import ShopByCategory from "./Pages/ShopByCategory";
import ShopByStore from "./Pages/ShopByStore";
import ComparePrices from './Pages/ComparePrices';
import StripePayement from './Pages/StripePayement';
import AdminDash from './Pages/AdminDash';
import SearchedProducts from './Pages/SearchedProducts';
import ClickNChallenge from "./Pages/ClickNChallenge";
import VersionTwo from "./Pages/VersionTwo";


//Logic
import {StateContext} from './context/StateContext'

//Component
import { Navbar, Footer, Header} from './Components/index'

//Extra Stuff
import { Toaster} from 'react-hot-toast';
import ComparePricesNew from "./Pages/ComparePricesNew";


 function App() {
   
   const [user] = useState(false);
   //useEffect(() => {
     //const fetchUser = async () => {
       //await fetch("https://dummyjson.com/users/1")
         //.then((res) => res.json())
        // .then((data) => setUser(data));
     //};
    // fetchUser();

   //}, []);

     //console.log(user)

   const queryClient = new QueryClient()

  return (
  <QueryClientProvider client={queryClient}>
  <StateContext>
    <Toaster />
    <Router>
      <div className="Top-header" style={{position: 'fixed', top: '0', width: '100%', zIndex: 9999, height: '60px'}}>
        <Navbar user={user}/>
        <Header/>  
      </div>        
      <Routes>
        {/* User Side */}
        <Route path='/' exact element={<Products/>} />    
        <Route path="/version-2" exact element={<VersionTwo/>} />     
        <Route path='/products/:id/:name' exact element={<ProductDetails/>}/>
        <Route path='/products/search/:name' exact element={<SearchedProducts/>}/>
        <Route path='/category/:category/:store' exact element={<CategoryPage/>}/>
        <Route path='/shopby/:category' exact element={<ShopByCategory/>}/>
        <Route path='/store/:store' exact element={<ShopByStore/>}/>
        <Route path='/compare/:id/:category/:store' exact element={<ComparePrices/>}/>
        <Route path='/compare2/:id/:category/:store' exact element={<ComparePricesNew/>}/>
        <Route path='/profilesidebar/' exact element={<EditProfile/>}/>   
        <Route path='/checkout/' exact element={<CheckoutPage/>}/>
        <Route path='/checkout/payement' exact element={<StripePayement/>}/>        

        <Route path='/terms' exact element={<Terms/>} /> 
        <Route path='/privacy' exact element={<Privacy/>} />                  

        {/* Delivery */}
        <Route path='/order-list' exact element={<Orders/>} />  
        <Route path='/order-details/:_id' exact element={<DetailsOrder/>} /> 

        {/*Admin} */}
        <Route path='/order-list' exact element={<Orders/>} />         
        <Route path='/admindash' exact element={<AdminDash/>} />


        {/*<Route path='/subcat/sub=:query/:cat' exact element={<SubCat/>}/> 
        <Route path='/all/sub=:query/:cat' exact element={<SubCategoriesAll/>}/>         
                       
        <Route path='/edit-profile' exact element={user ? <EditProfile/>: <Navigate to='/'></Navigate>}  />*/}
        <Route path='/wishlist' exact element={user ? <EditProfile/>: <Navigate to='/'></Navigate>}  />
        <Route path='/orders' exact element={user ? <EditProfile/>: <Navigate to='/'></Navigate>}  />
        <Route path='/challenge' exact element={<ClickNChallenge/>}/>
      </Routes>
    </Router>
    {/*<Newsletter/>*/}
    <Footer/> 
  </StateContext>
  </QueryClientProvider>
 );
}
export default App;