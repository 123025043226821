import { React, useEffect, useState, Fragment } from "react";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { useStateContext } from "../context/StateContext";
import { StoresData } from "../Components/Filters/StoresData";
import {
  RiHeartLine,
  RiArrowLeftLine,
  RiArrowDropRightLine,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";

function ComparePricesNew() {
  const navigate = useNavigate();
  const { onAdd } = useStateContext();
  const { category, store, id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const getApiData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `https://api.click-n.com/find_similar?id=${id}`
        );
        //const response = await fetch(`https://api.click-n.com/find_similar_hybrid?id=${id}`);
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    getApiData();
  }, [id]);

  const dataLength = data.length;
  const preSaving = dataLength ? data[dataLength - 1].price - data[0].price : 0;
  const saving = Math.round(preSaving * 100) / 100;
  const prePercentageSaving =
    (1 - (dataLength ? data[0].price / data[dataLength - 1].price : 0)) * 100;
  const percentageSaving = Math.round(prePercentageSaving * 100) / 100;

  const price = dataLength ? data[0].price : 0; 
  console.log('price', price)// Price of the product
  const measurement = dataLength ? data[0].measurement : 0; // Measurement of the product
  const measurementUnit = dataLength ? measurement.split(" ")[0] : 0;
  console.log('price', measurementUnit) // Extracting the unit from the measurement (e.g. "2 l" => "l")
  const pricePerUnit = (price / parseFloat(measurementUnit)).toFixed(2);

  return (
    <div>
      <div className="BreadCrumbs" style={{ marginTop: "170px" }}>
        <RiArrowLeftLine
          style={{ marginRight: "30px", cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        <NavLink to={`/`}>
          {" "}
          <h2 className="Path"> Home </h2>{" "}
        </NavLink>{" "}
        <RiArrowDropRightLine />
        <NavLink to={`/shopby/${category}`}>
          {" "}
          <h2 className="Path"> Shop By </h2>{" "}
        </NavLink>{" "}
        <RiArrowDropRightLine />
        {category.split(",").map((cat, index) => (
          <>
            <NavLink to={`/category/${cat.trim()}/${store}`}>
              <h2 className="Path">{cat.trim()}</h2>
            </NavLink>
            {index !== category.split(",").length - 1 && (
              <RiArrowDropRightLine />
            )}
          </>
        ))}
        <RiArrowDropRightLine /> <h2 className="Page"> Price Comparison (Text) </h2>
      </div>
      <>
        {isLoading === false && (
          <>
            {dataLength !== 0 ? (
              <>
                <>
                  {data
                    ?.sort((a, b) => {
                      return a.price - b.price;
                    })
                    .slice(0, 1)
                    .map((product, index) => (
                      <div key={index}>
                        {StoresData.filter(
                          (img) => img.store === product.store
                        ).map((img) => (
                          <>
                            <div class="Price-Compare-logo">
                              <img
                                src={img.shopbyimg}
                                className="Price-Compare-logo-image"
                                alt=""
                              />
                            </div>
                            <div className="Price-Compare-Big-Container">
                              <>
                                <div className="Price-Compare-Big-image-Container">
                                  <img
                                    src={product.image}
                                    className="Price-Compare-image"
                                    alt=""
                                  />
                                </div>
                                <div className="Price-Compare-Big-details-Container">
                                  <div className="Compare-prices-details-big">
                                    <p className="Compare-prices-productname-big">
                                      {" "}
                                      {product.name}{" "}
                                    </p>
                                  </div>
                                  <div className="Compare-prices-details-big">
                                    <p
                                      style={{
                                        marginBottom: "-35px",
                                        marginTop: "-30px",
                                        fontWeight: "500",
                                        fontSize: "20px",
                                        color: "#828282",
                                      }}
                                    >
                                      {" "}
                                      {product.measurement}{" "}
                                    </p>
                                  </div>
                                  <div className="Compare-prices-details-big">
                                    <p className="Compare-prices-productprice-big">
                                      {" "}
                                      ${product.price}{" "}
                                    </p>
                                    <p className="Compare-prices-save-money">
                                      {" "}
                                      Save: ${saving}{" "}
                                    </p>
                                  </div>
                                  <p className="Compare-prices-productprice-big">
                                      {" "}
                                      price per unit: ${pricePerUnit}
                                    </p>
                                  <div className="Compare-prices-savings">
                                    <p className="Compare-prices-save-percentage">
                                      {" "}
                                      Save up to {percentageSaving}%{" "}
                                    </p>
                                  </div>
                                  <div className="Compare-prices-detail-store-big">
                                    <p className="Compare-prices-detail-store">
                                      {" "}
                                      store: {product.store}
                                    </p>
                                    <p className="Compare-prices-detail-store">
                                      {" "}
                                      brand: {product.brand}
                                    </p>
                                  </div>
                                  <button
                                    className="Compare-prices-add-to"
                                    onClick={() => onAdd(product, 1)}
                                  >
                                    {" "}
                                    Add to cart
                                  </button>
                                </div>
                              </>
                            </div>
                          </>
                        ))}
                      </div>
                    ))}
                </>
                <div class="name-everything">
                  {data
                    ?.sort((a, b) => {
                      return a.price - b.price;
                    })
                    .slice(1, data.length)
                    .map((product) => (
                      <div style={{ marginTop: "150px" }}>
                        {StoresData.filter(
                          (img) => img.store === product.store
                        ).map((img) => (
                          <>
                            <div class="Price-Compare-logo-big">
                              <img
                                src={img.shopbyimg}
                                className="Price-Compare-logo-image-big"
                                alt=""
                              />
                            </div>
                            <div className="item-container-big">
                              <div className="item-card" key={product.id}>
                                <div
                                  className="item-card-div"
                                  style={{ marginBottom: "40px" }}
                                >
                                  <div
                                    className="wishlist-button"
                                    title="wishlist-button"
                                  >
                                    <RiHeartLine className="sales-span-icon" />
                                  </div>
                                  <div className="item-car-header">
                                    <NavLink
                                      to={`/products/${
                                        product.id
                                      }/${encodeURIComponent(
                                        `${product.name}`
                                      )}`}
                                    >
                                      {" "}
                                      <img
                                        className="item-image"
                                        src={product.image}
                                        alt="items"
                                        onClick={() =>
                                          (window.location = `/products/${
                                            product.id
                                          }/${encodeURIComponent(
                                            `${product.name}`
                                          )}`)
                                        }
                                      />
                                    </NavLink>
                                  </div>

                                  <span>{product.category}</span>
                                  <div className="details-div">
                                    <div className="item-prices">
                                      <p className="product-price">
                                        ${product.price}
                                      </p>
                                      {product.sale?.old_price !== undefined &&
                                      product.sale?.old_price >
                                        product.price ? (
                                        <p className="product-avg-price">
                                          ${product.sale.old_price}
                                        </p>
                                      ) : null}
                                    </div>

                                    <div
                                      className="item-details"
                                      style={{ marginBottom: "40px" }}
                                    >
                                      <p className="item-name">
                                        {product.name.substring(0, 40)}{" "}
                                        <span className="item-unit">
                                          {product.measurement}
                                        </span>
                                      </p>
                                    </div>
                                    {/*<div className='item-prices'>
                                  <p className="product-store">{product.store}</p>
                                  </div> */}
                                  </div>
                                </div>
                                <button
                                  style={{ marginTop: "40px" }}
                                  className="product-addtocart"
                                  onClick={() => onAdd(product, 1)}
                                >
                                  {" "}
                                  Add to cart
                                </button>
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <>
                <p className="noPriceComparison">
                  {" "}
                  No other product available for price comparison{" "}
                </p>
                <p
                  className="secondPriceComparison"
                  onClick={() => navigate(-1)}
                >
                  {" "}
                  Return to the previous page{" "}
                </p>
              </>
            )}
          </>
        )}
        {isLoading === true && (
          <p className="noPriceComparison"> Loading ... </p>
        )}
      </>
    </div>
  );
}

export default ComparePricesNew;
