export const secondMenuItems = [
    {
      title: 'Home, Beauty & Baby',
      url: '',
      submenu: [
        {
          title: 'Household',
          url: '/shopby/Household',
          submenu: [
            {title: 'Snow Removal', id: 101, name: 'Household, Snow Removal', url: '/shopby/Household, Snow Removal',},
            {title: 'Bathroom Tissue & Paper Products', id: 102, name: 'Household, BATHROOM TISSUE & PAPER PRODUCTS', url: '/shopby/Household, BATHROOM TISSUE & PAPER PRODUCTS',},
            {title: 'FOOD WRAP & BAGS', id: 103, name: 'Household, FOOD WRAP & BAGS', url: '/shopby/Household, FOOD WRAP & BAGS',},
            {title: 'FLOOR CARE', id: 104, name: 'Household, FLOOR CARE', url: '/shopby/Household, FLOOR CARE',},
            {title: 'HOUSEHOLD CLEANERS', id: 105, name: 'Household, HOUSEHOLD CLEANERS', url: '/shopby/Household, HOUSEHOLD CLEANERS',},
            {title: 'AIR FRESHENERS', id: 106, name: 'Household, AIR FRESHENERS', url: '/shopby/Household, AIR FRESHENERS',},
            {title: 'DISHWASHING', id: 107, name: 'Household, DISHWASHING', url: '/shopby/Household, DISHWASHING',},
            {title: 'LAUNDRY NEEDS', id: 108, name: 'Household, LAUNDRY NEEDS', url: '/shopby/Household, LAUNDRY NEEDS',},
            {title: 'TRASH BAGS', id: 109, name: 'Household, TRASH BAGS', url: '/shopby/Household, TRASH BAGS',}, 
            {title: 'Floral and Garden', id: 107, name: 'Floral and Garden', url: '/shopby/Floral and Garden',},
            {title: 'CLEANING ACCESSORIES & SPONGES', id: 107, name: 'Household, CLEANING ACCESSORIES & SPONGES', url: '/shopby/Household, CLEANING ACCESSORIES & SPONGES',},
            {title: 'LIGHT BULBS', id: 108, name: 'Household, LIGHT BULBS', url: '/shopby/Household, LIGHT BULBS',},
            {title: 'BATTERIES', id: 109, name: 'Household, BATTERIES', url: '/shopby/Household, BATTERIES',},  
            {title: 'miscellaneous', id: 109, name: 'Household, miscellaneous', url: '/shopby/Household, miscellaneous',},   
          ],
          id: 3,
          category:"Household"         
        },
        {
          title: 'Health & Beauty',
          url: '/shopby/Health & Beauty',
          submenu: [
            //{title: 'All', id: 507, name: 'Bakery, All', url: '/shopby/Bakery',},
            {title: 'Body Care', id: 501, name: 'Health & Beauty, Body Care', url: '/shopby/Health & Beauty, Body Care',},
            {title: 'Cosmetics', id: 502, name: 'Health & Beauty, Cosmetics', url: '/shopby/Health & Beauty, Cosmetics',},
            {title: 'Feminine Care', id: 503, name: 'Health & Beauty, Feminine Care', url: '/shopby/Health & Beauty, Feminine Care',},
            {title: 'NUTRITION & WELLNESS', id: 504, name: 'Health & Beauty, NUTRITION & WELLNESS', url: '/shopby/Health & Beauty, NUTRITION & WELLNESS',},
            {title: 'Deodorant', id: 505, name: 'Health & Beauty, Deodorant', url: '/shopby/Health & Beauty, Deodorant',},
            {title: 'Eye & Ear care', id: 506, name: 'Health & Beauty, Eye & Ear care', url: '/shopby/Health & Beauty, Eye & Ear care',},
            {title: 'Foot Care', id: 502, name: 'Health & Beauty, Foot Care', url: '/shopby/Health & Beauty, Foot Care',},
            {title: 'Hair Care', id: 503, name: 'Health & Beauty, Hair Care', url: '/shopby/Health & Beauty, Hair Care',},
            {title: 'Health Care', id: 503, name: 'Health & Beauty, Health Care', url: '/shopby/Health & Beauty, Health Care',},
            {title: 'Oral Hygiene', id: 504, name: 'Health & Beauty, Oral Hygiene', url: '/shopby/Health & Beauty, Oral Hygiene',},
            {title: 'Grooming & Shaving', id: 505, name: 'Health & Beauty, Grooming & Shaving', url: '/shopby/Health & Beauty, Grooming & Shaving',},
            {title: 'Adult Care', id: 506, name: 'Health & Beauty, Adult Care', url: '/shopby/Health & Beauty, Adult Care',},         
            {title: 'Skin Care', id: 504, name: 'Health & Beauty, Skin Care', url: '/shopby/Health & Beauty, Skin Care',},
            {title: 'Sun Protection & Bug Spray', id: 505, name: 'Health & Beauty, Sun Protection & Bug Spray', url: '/shopby/Health & Beauty, Sun Protection & Bug Spray',},
            //{title: 'August Deals', id: 505, name: 'Health & Beauty, August Deals', url: '/shopby/Health & Beauty, August Deals',},
            {title: 'FRAGRANCES & SCENT', id: 505, name: 'Health & Beauty, FRAGRANCES & SCENT', url: '/shopby/Health & Beauty, FRAGRANCES & SCENT',},
            {title: 'Miscellaneous', id: 506, name: 'Health & Beauty, miscellaneous', url: '/shopby/Health & Beauty, miscellaneous',},
          ],
          id: 1,
          category:"Health & Beauty"        
        }, 
        {
          title: 'Baby',
          url: '/shopby/Baby',        
          submenu: [
            {title: 'Bath', id: 301, name: 'Baby, Bath', url: '/shopby/Baby, Bath',},
            {title: 'Diapers & Wipes', id: 302, name: 'Baby, Diapers & Wipes', url: '/shopby/Baby, Diapers & Wipes',},
            {title: 'Bottles & Formula', id: 303, name: 'Baby, Bottles & Formula', url: '/shopby/Baby, Bottles & Formula',},
            {title: 'Baby Healthcare', id: 305, name: 'Baby, Baby Healthcare', url: '/shopby/Baby, Baby Healthcare',},
            {title: 'baby food & drinks', id: 306, name: 'Baby, baby food & drinks', url: '/shopby/Baby, baby food & drinks',},
            //{title: 'Miscellaneous', id: 315, name: 'Baby, miscellaneous', url: '/shopby/Baby, miscellaneous',},  
            
          ],
          id: 2,
          category:"Baby"             
        },   
        {
          title: 'Pets Supplies',
          image: 'https://i.ibb.co/TmNtPz9/cat.png',
          url: '/shopby/Pets Supplies',        
          submenu: [
            {title: 'AMPHIBIANS & REPTILES', id: 601, name: 'Pets Supplies, AMPHIBIANS & REPTILES', url: '/shopby/Pets Supplies, AMPHIBIANS & REPTILES',},
            {title: 'Birds', id: 602, name: 'Pets Supplies, Birds', url: '/shopby/Pets Supplies, Birds',},
            {title: 'Cats', id: 603, name: 'Pets Supplies, Cats', url: '/shopby/Pets Supplies, Cats',},
            {title: 'Dogs', id: 604, name: 'Pets Supplies, Dogs', url: '/shopby/Pets Supplies, Dogs',},
            {title: 'FERRET', id: 605, name: 'Pets Supplies, FERRET', url: '/shopby/Pets Supplies, FERRET',},
            {title: 'FISH & AQUATIC ANIMALS', id: 606, name: 'Pets Supplies, FISH & AQUATIC ANIMALS', url: '/shopby/Pets Supplies, FISH & AQUATIC ANIMALS',},
            {title: 'SMALL ANIMALS', id: 606, name: 'Pets Supplies, SMALL ANIMALS', url: '/shopby/Pets Supplies, SMALL ANIMALS',},
            //{title: 'Miscellaneous', id: 609, name: 'Pets Supplies, Miscellaneous', url: '/shopby/Pets Supplies, Miscellaneous',},  
          ],
          id: 16,
          category:"Pets Supplies"
        },  
      ],
    },
  ];
  
  
  
  