import React from 'react'
import { CheckoutMain, CheckoutSide} from "../Components/index";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";



const stripePromise = loadStripe("pk_test_51LQrEwHiUlHcQpgzU0LtsqEUxgrOhvYwRhwlHypTEINfMyaAvQg9keEKvRhmsVynUxF8B9x6dh4g94Cz62JDvBGQ00JnhEskxv");

function CheckoutPage() {


  return (
    <div className='checkoutPage-container'>

      <div className='checkoutPage-container-inner'>
        <div className='checkoutPage-container-inner-left'>
          <Elements stripe={stripePromise}>
            <CheckoutMain />
          </Elements>
        </div>
        <div className='checkoutPage-container-inner-right'>
          <CheckoutSide/>           
        </div>
      </div>
    </div>
  )
}

export default CheckoutPage