import React, { useRef } from 'react';
import '../../Styles/Global.css';
import {NavLink, useNavigate} from 'react-router-dom'
import { AiOutlineMinus, AiOutlinePlus, AiOutlineLeft, AiOutlineShopping } from 'react-icons/ai';
import { TiDeleteOutline } from 'react-icons/ti';
import { useStateContext} from '../../context/StateContext';



function Cart() {

  const cartRef = useRef();
  const {  totalPrice, totalQuantities, cartItems,  setShowCart, toggleCartItemQuanitity, onRemove} = useStateContext();

 
  
  const navigate = useNavigate();

    const navigateCheckout = () => {
    navigate('/checkout');
    setShowCart(false)
  };

  

  
  //const carts = { status: 'pending', items: (cartItems)}

    //const handleCheckout = async () => {
    //const stripe = await getStripe();

    //const response = await fetch('http://127.0.0.1:5000/create_cart', {
      //method: 'POST',
     // headers: {
        //'Content-Type': 'application/json',
      //},
     // body: JSON.stringify(carts),
    //});

    //if(response.statusCode === 500) return;
    
    //const data = await response.json();

    //toast.loading('Redirecting...');

    //stripe.redirectToCheckout({ sessionId: data.id });
  //}  


  return (
    
    <div className="cart-wrapper" ref={cartRef}>
      <div className="cart-container">
        <button
        type="button"
        className="cart-heading"
        onClick={() => setShowCart(false)}>
          <AiOutlineLeft />
          <span className="heading">Your Cart</span>
          <span className="cart-num-items">({totalQuantities} items)</span>
        </button>

        {cartItems.length < 1 && (
          <div className="empty-cart">
            <AiOutlineShopping size={150} />
            <h3>Your shopping bag is empty</h3>
            <NavLink to="/">
              <button
                type="button"
                onClick={() => setShowCart(false)}
                className="btn btn-empty"
              >
                Continue Shopping
              </button>
            </NavLink>
          </div>
        )}        

        <div className="product-container">
          {cartItems.length >= 1 && cartItems.map((item) => (
              <div className="product" key={item.id}>
                <img src={item.image} className="cart-product-image" alt='product'/>
                <div className="item-desc">
                  <div className="flex top">
                    <h5 className='cart-title'>{item.name.substring(0,30)}</h5>
                    <h4 className='cart-price'>${item.price}</h4>
                  </div>
                {/*<div className="flex middle">
                    <p>Avg Price:</p>
                    <p className='price-cart'>${item.sale.old_price}</p>                  
                  </div>  
                  <div className="flex middle">
                    <p>Savings:</p>
                    <p className='saving-cart'>{sales}</p>                  
                  </div>   */}                      
                  <div className="flex bottom">
                    <div>
                    <p className="quantity-desc">
                    <span className="minus" onClick={() => toggleCartItemQuanitity(item.id, 'dec') }>
                      <AiOutlineMinus />
                      </span>
                      <span className="num" >{item.quantity}</span>
                      <span className="plus" onClick={() => toggleCartItemQuanitity(item.id, 'inc') }>
                        <AiOutlinePlus /></span>
                    </p>
                    </div>
                    <button
                      type="button"
                      className="remove-item"
                      onClick={() => onRemove(item)}
                    >
                      <TiDeleteOutline />
                    </button>
                  </div>
                </div>
              </div>
          ))}            
        </div>


        {cartItems.length >= 1 && (
          <div className="cart-bottom">
            <div className="total">
              <h3>Subtotal:</h3>
              <h3>$ {totalPrice} </h3>
            </div>
            <div className="btn-container">
              <button type="button" className="btn" onClick={navigateCheckout}>
                Check out
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Cart