import React from 'react'
//import {ProductData} from '../Sliders/Data/ProductData';
import {NavLink} from 'react-router-dom';
//import { useStateContext } from '../../context/StateContext';
//import {useParams} from 'react-router';
import {CarouselData} from '../Sliders/Data/CarouselData'

function CategoriesFilter() {

    /*function refreshPage() {
    window.location.reload(false);
  }*/

    //const { filters} = useStateContext();
    //const {category} = useParams();
    //const [items, setItems] = useState([]);

  /*const getData = () => {
    fetch(`http://127.0.0.1:5000/get_category`)
    .then((res) => res.json())
    .then((res) => {
      console.log(res)
      setItems(res)
    })
  }

  useEffect(() => {
    getData()
    //eslint-disable-next-line
  }, [])*/

  return (
    <div className='Categories-container'>
        <div className='Categories-header'>
            <div className='Categories-items'>
            {CarouselData.map((item) => (
                <div className='Categories-inner-item' key={item.id}>
                    <NavLink to={`/shopby/${item.category}/`} className='Categories-img-container'>
                        <img className='Categories-img' src={item.image} alt={item.category} key={item.id}/> 
                    </NavLink>
                    <p className='Categories-title'>{item.title}</p>
                </div>
            ))}
            </div>
        </div>
    </div>
  )
}

export default CategoriesFilter