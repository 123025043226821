import React from 'react'

function EditProfileMain() {
  return (
    <div className='Profile-main-content'>
        <div className='Profile-main-content-inner'>
            <div className='sidebar-content-profile'>
                <h3 className='Profile-main-title'> Personal Information</h3>
                <div className='Profile-main-personal'>
                    <div className='Profile-main-personal-inner'>
                        <h4 className='Profile-main-personal-title' style={{fontSize: '15px', marginTop: '10px'}}>First Name</h4>
                        <input type='text' className='Profile-main-personal-input' placeholder=''/>
                    </div>
                    <div className='Profile-main-personal-inner'>
                        <h4 className='Profile-main-personal-title' style={{fontSize: '15px', marginTop: '10px'}}>Last Name</h4>
                        <input type='text' className='Profile-main-personal-input' placeholder=''/>
                    </div>                    
                </div>
                <div className='Profile-main-email'>
                <div className='Profile-main-personal'>
                    <div className='Profile-main-personal-inner'>
                        <h4 className='Profile-main-personal-title' style={{fontSize: '15px', marginTop: '10px'}}>First Address</h4>
                        <input type='text' className='Profile-main-personal-input' placeholder=''/>
                    </div>
                    <div className='Profile-main-personal-inner'>
                        <h4 className='Profile-main-personal-title' style={{fontSize: '15px', marginTop: '10px'}}>Second Address</h4>
                        <input type='text' className='Profile-main-personal-input' placeholder=''/>
                    </div>                    
                </div>
                </div>
                <div className='Profile-main-email'>
                <div className='Profile-main-personal-inner'>
                        <h4 className='Profile-main-personal-title' style={{fontSize: '15px', marginTop: '10px'}}>Phone / Mobile</h4>
                        <input type='text' className='Profile-main-personal-input' placeholder=''/>
                    </div>                      
                </div>
            </div>
            
            <div className='sidebar-content-profile-second'>
            <h3 className='Profile-main-title' style={{marginTop: '20px'}}> Account Information</h3>
                <div className='Profile-main-personal'>
                    <div className='Profile-main-personal-inner'>
                        <h4 className='Profile-main-personal-title' style={{fontSize: '15px', marginTop: '10px'}}>Email</h4>
                        <input type='text' className='Profile-main-personal-input' placeholder=''/>
                    </div>                   
                </div>
                <div className='Profile-main-email'>
                <div className='Profile-main-personal'>
                    <div className='Profile-main-personal-inner'>
                        <h4 className='Profile-main-personal-title' style={{fontSize: '15px', marginTop: '10px'}}>Password</h4>
                        <input type='text' className='Profile-main-personal-input' placeholder=''/>
                    </div>
                    <div className='Profile-main-personal-inner'>
                        <h4 className='Profile-main-personal-title' style={{fontSize: '15px', marginTop: '10px'}}>Confirm Password</h4>
                        <input type='text' className='Profile-main-personal-input' placeholder=''/>
                    </div>                    
                </div>
                </div>             
            </div>
            <button className='sidebar-content-button'> Save Changes</button>
        </div>
    </div>
  )
}

export default EditProfileMain