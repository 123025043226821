import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { useClickOutside } from 'react-click-outside-hook';



const Context = createContext();

const cartItemsFromLocalStorage = JSON.parse(localStorage.getItem('cartItems') || '[]');
const totalPriceFromLocalStorage = JSON.parse(localStorage.getItem('totalPrice') || '0');
const totalQuantitiesFromLocalStorage = JSON.parse(localStorage.getItem('totalQuantities') || '0');


export const StateContext = ({ children }) => {

  const [items, setItems] = useState([]) //for now, remove later json-server
  const [showCart, setShowCart] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showRecover, setShowRecover] = useState(false);
  const [data, setData] = useState([]);
  // eslint-disable-next-line
  const [singleItem, setSingleItem] = useState([]);
  const [filters, setFilters] = useState(data);
  const [firstIndex, setFirstIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(6);
  const [firstIndexSlider, setFirstIndexSlider] = useState(0);
  const [lastIndexSlider, setLastIndexSlider] = useState(1);
  const [cartItems, setCartItems] = useState(cartItemsFromLocalStorage);
  //const [wishlistItems, setwishlistItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(totalPriceFromLocalStorage);
  const [Wishlist, setWishlist] = useState(0);
  const [totalQuantities, setTotalQuantities] = useState(totalQuantitiesFromLocalStorage);
  const [sales, setSales] = useState(0);
  const [totalSavings, setTotalSavings] = useState(0);
  const [qty, setQty] = useState(1);
  const [user, setUser] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [content, setContent] = useState();
  const [isExpanded, setExpanded] = useState(false);
  const [parentRef, isClickedOutside] = useClickOutside();
  const inputRef = useRef();
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [SearchedProducts, setSearchedProducts] = useState([]);
  const [noProducts, setNoProducts] = useState(false);
  const [carts, setCarts] = useState([]);
  const [ctegoryItems, setCategoryItems] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [categorydata, setCategorydata] = useState([]);
  const [categoryFilters, setCategoryFilters] = useState(data);
  const [error, setError] = useState('');


  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);
  useEffect(() => {
    localStorage.setItem('totalQuantities', JSON.stringify(totalQuantities));
  }, [totalQuantities]);
  useEffect(() => {
    localStorage.setItem('totalPrice', JSON.stringify(totalPrice));
  }, [totalPrice]);    


  const limit = 12;
  let foundProduct;
  let index;
  let foundProductIndex;




// Get Carts
const getApiCarts = async () => {
  const response = await fetch(
    "https://api.click-n.com/get_carts"
  ).then((response) => response.json());

  setCarts(response);
};

  useEffect(() => {
    getApiCarts()
     // eslint-disable-next-line
  }, [])
  

  //Adding Items to wishlist
  /*
  const addToWishlist = (id) => {
    const check_wishlist = wishlist.findIndex((item) => item.id === id);
    console.log(check_wishlist);
    if (check_wishlist !== -1) {
      setWishlist([
       ...wishlist
       products.find((item) => item.id === id);
      ]);
    } else {
      const newList = wishlist.filter((item) => item.id !== id);
      setWishlist(newList);
    }
    console.log(wishlist);
    };*/ 


  // Adding Items to cart
  const onAdd = (product, quantity) => {
    const checkProductInCart = cartItems.find((item) => item.id === product.id);
    
    setTotalPrice((prevTotalPrice) => (prevTotalPrice + (Math.round((product.price * quantity)*100)/100)));
    setTotalQuantities((prevTotalQuantities) => prevTotalQuantities + quantity);

    
    if(checkProductInCart) {
       // eslint-disable-next-line
      const updatedCartItems = cartItems.map((cartProduct) => {
        if(cartProduct.id === product.id) return {
          ...cartProduct,
          quantity: cartProduct.quantity + quantity          
        }
        return cartProduct

      })

      setCartItems(updatedCartItems);
    } else {
      product.quantity = quantity;
      

      setCartItems([{ ...product }, ...cartItems]);
    }

    toast.success(` ${product.name} added to the cart.`);

  
  } 

  // Removing Items from cart
  const onRemove = (product) => {
    foundProduct = cartItems.find((item) => item.id === product.id);
    const newCartItems = cartItems.filter((item) => item.id !== product.id);

    setTotalPrice((prevTotalPrice) => prevTotalPrice - (Math.round((foundProduct.price * foundProduct.quantity)*100)/100));
    setTotalQuantities(prevTotalQuantities => prevTotalQuantities - foundProduct.quantity);
    setCartItems(newCartItems);
  }

  const toggleCartItemQuanitity = (id, value) => {
    const cartItemsCopy = [...cartItems];
    foundProductIndex = cartItemsCopy.findIndex(item => item.id === id);
  
    if (value === "inc") {
      cartItemsCopy[foundProductIndex].quantity++;
      setCartItems([...cartItemsCopy]);
      setTotalPrice(prevTotalPrice =>Math.round((prevTotalPrice + cartItemsCopy[foundProductIndex].price) * 100) / 100);
      setTotalQuantities(prevTotalQuantities => prevTotalQuantities + 1);
      
    } else if (value === "dec") {
      if (cartItemsCopy[foundProductIndex].quantity > 1) {
        cartItemsCopy[foundProductIndex].quantity--;
        setCartItems([...cartItemsCopy]);
        setTotalPrice(prevTotalPrice =>Math.round((prevTotalPrice - cartItemsCopy[foundProductIndex].price) * 100) / 100);
        setTotalQuantities(prevTotalQuantities => prevTotalQuantities - 1);
      }
    } 
  };




  //inc Quantity
  const incQty = () => {
    setQty((prevQty) => prevQty + 1);
  }

  //dec quantity
  const decQty = () => {
    setQty((prevQty) => {
      if(prevQty - 1 < 1) return 1;
    
      return prevQty - 1;
    });
  }

  


  //Search Bar
const isEmpty = !SearchedProducts || SearchedProducts.length === 0;

  const changeHandler = (e) => {
    e.preventDefault();
    if(e.target.value.trim() === '')setNoProducts(false);
    setSearchQuery(e.target.value);       
  }

  const expandContainer = () => {
    setExpanded(true);
  }

  const collapseContainer = () => {
    setExpanded(false);
    setSearchQuery('');
    setLoading(false);
    setSearchedProducts([]);
    setNoProducts(false);
    if(inputRef.current) inputRef.current.value = '';
  }

  const containerVariant = {
    expanded : {
      marginTop: '260px',
      height: '20em',
    },
    collapsed: {
      height: '40px'
    }
  }
   
  const containerTransition = {type: 'spring', damping: 22, stiffness: 150};


  const prepareSearchQuery = (query) => {
    const url = `https://api.click-n.com/find_name?find=${query}&items=10`;
    return encodeURI(url);
  }

  const searchProducts = async() => {
    if (!searchQuery || searchQuery.trim() === '')
      return;
    setLoading(true);

    const URL = prepareSearchQuery(searchQuery);
    const response =  await fetch(URL)
    .then((response) => response.json());
      if (response && response.length === 0)
          setNoProducts(true);
      setSearchedProducts(response);
      console.log(response)
      setLoading(false);
  };


  //checks if user conencted or not
  //useEffect(() => {
    //const fetchUser = async () => {
      //await fetch("https://dummyjson.com/users/1")
        //.then((res) => res.json())
        //.then((data) => setUser(data));
    //};
    //fetchUser();

  //}, []);

  //console.log(user)




  return (
    <Context.Provider
      value={{
        showCart,
        setShowCart,
        showSidebar,
        setShowSidebar,
        showLogin,
        setShowLogin,
        showRegister,
        setShowRegister,
        showRecover,
        setShowRecover,                  
        cartItems,
        data,
        singleItem,
        setData,
        filters,
        setFilters,
        //getProduct,
        firstIndex,
        setFirstIndex,
        lastIndex,
        setLastIndex,
        qty,
        totalPrice,
        setTotalPrice,
        totalQuantities,
        setTotalQuantities,
        setQty,
        incQty,
        decQty,
        onAdd,
        index,
        sales,
        setSales,
        totalSavings,
        setTotalSavings,
        onRemove,
        toggleCartItemQuanitity,
        //filterProduct,
        setUser,
        user,
        searchText,
        setSearchText,
        content,
        setContent,
        firstIndexSlider,
        lastIndexSlider,
        setFirstIndexSlider,
        setLastIndexSlider,
        isExpanded,
        setExpanded,
        parentRef,
        isClickedOutside,
        inputRef,
        searchQuery,
        setSearchQuery,
        isLoading,
        setLoading,
        SearchedProducts,
        setSearchedProducts,
        noProducts,
        setNoProducts,
        isEmpty,
        changeHandler,
        expandContainer,
        collapseContainer,
        containerVariant,
        containerTransition,
        searchProducts,
        prepareSearchQuery,
        items,
        setItems,
        setWishlist,
        Wishlist,
        carts,
        setCarts,
        ctegoryItems,
        setCategoryItems,
        //handleAddToCart
        limit,
        pageCount,
        setpageCount,
        categorydata,
        setCategorydata,
        categoryFilters,
        setCategoryFilters,
        error,
        setError

      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useStateContext = () => useContext(Context);