import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function AdsBanner() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

  return (
    <Slider  {...settings}>
        <div className='first-div'>
            <h1> first</h1>
        </div>
        <div className='second-div'></div>        
    </Slider> 
  )
}

export default AdsBanner