import React from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {NavLink} from 'react-router-dom';
//import { useStateContext } from '../../context/StateContext';


function ItemsSlider() {
    //const {onAdd} = useStateContext();
    const settings = {
        dots: true,
        autoplay: true,
        
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 5,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };
    
    return (
        <div className='ItemSlider-Container'>
            <Slider {...settings}>
                <div className="ItemSlider-Inner">                              
                    <div className='ItemSlider-Image' style={{ width: '80%'}}>                  
                      <NavLink to={''}> <img className="ProductCard-image"
                        src='' style={{ width: '80%'}} alt='items'/>
                      </NavLink>
                    </div>                     
                    <div className='ProductCard-details'style={{height: '42px'}}>               
                        <p className="ProductCard-name" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}>Product Name</p>                    
                    </div>                              
                    <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                        <p className="ProductCard-price" style={{fontSize: "13px"}}>Current Price</p>
                        <p className='ProductCard-avg-price' style={{ fontSize: "13px", textDecoration: 'line-through'}}>Avg Price</p> 
                    </div> 
                    {/*<button className='product-addtocart-slider' onClick={() => onAdd(product, 1)}> Add to cart</button>*/}
                </div>                              
            </Slider>
        </div>
    )
}

export default ItemsSlider