export const StoresData = [

    /*{
       image: "https://i.imgur.com/hsP2Gt8.jpg",
       shopbyimg: "https://i.imgur.com/8CduwIr.png",       
       title: "Costco",      
       id: 1,
       store:"costco"
           
   },*/

   {
       image: "https://i.imgur.com/W9FsoRK.jpg",
       shopbyimg: "https://i.imgur.com/keGID5q.png",             
       title: "Loblaws",
       id: 2,
       store:"loblaws"            
   },
   {
       image: "https://i.imgur.com/0K5xkdc.jpg",
       shopbyimg: "https://i.imgur.com/UT4Id3r.png",             
       title: "NoFrills",
       id: 3,
       store:"nofrills"            
   },
   {
       image: "https://i.ibb.co/wpzxNMr/superstoretransparent.png",
       shopbyimg: "https://i.imgur.com/PGkKhLr.png",             
       title: "Real Canadian Super Store ",
       id: 4,
       store:"realcanadiansuperstore"            
   },
   {
       image: "https://i.imgur.com/mSRiS3U.jpg",
       shopbyimg: "https://i.imgur.com/GEyL6vf.png",             
       title: "Save On Foods",
       id: 5,
       store:"saveonfoods"            
   },
   {
       image: "https://i.imgur.com/IGgBwhW.jpg",
       shopbyimg: "https://i.imgur.com/8q6UvZy.png",             
       title: "T&T Superstore",      
       id: 6,
       store:"TNT"            
   },
{
        image: "https://i.imgur.com/i37AIdf.jpg",
        shopbyimg: "https://i.imgur.com/AJIzaps.png",              
        title: "Walmart",          
        id: 7,
        store:"wallmart"            
   },
   
 
]












