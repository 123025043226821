import React from 'react'
import { useStateContext } from '../../context/StateContext';
import { HiOutlineMail, HiX } from "react-icons/hi";



function Login() {
    const {   setShowLogin, setShowRecover,  } = useStateContext();
    const handleSwitch = () => {
        setShowRecover(false)        
        setShowLogin(true)  
    }
    // eslint-disable-next-line no-lone-blocks
    {/* const [error, setError] = useState('');
    const { user, setUser} = useStateContext();
    const [details, setDetails] = useState({name:'', email:'', password:''});

    const Login = details => {
        console.log(details)
        if(details.email == user.email && details.password == user.password) {
            console.log('Logged in');
        } else {
            console.log('Details do not match')
        }


    }

    const Logout = details => {
        console.log(Logout)
    }    

    const submitHandler = e => {
        e.preventDefault();
        Login(details);
    }
*/}

  return (

    <div className="Authentification">
        <div className='AuthContainer'>
            <div className='Authforms'>
                <div className='Authform login'>
                <div className='authentification-title'>
                    <span className='formTitle'>Recover Password</span>
                        <button className='authentification-close' onClick={() => setShowRecover(false)}>
                            <HiX/>
                        </button>
                    </div>                    
                    <form action='#'>
                        <div className='input-field'>
                            <input type='text' placeholder='Enter your email' required/>
                            <HiOutlineMail className="input-icon left-icon" />
                        </div>
                        <div className='input-field button'>
                            <input type='button' value={'Recover Password'}/>                            
                        </div>                  
                    </form>
                    <div className='login-signup'>
                        <span className='text-check'>Don't have an account? </span>
                        <span className='text-check signup-text' onClick={() => handleSwitch(true)}>Login</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Login