import {React, useState } from 'react'
import  logo  from "../../Assets/Images/Logo.png"
import { BsPersonFill } from 'react-icons/bs';
import { HiShoppingCart } from 'react-icons/hi';
import {FiLogOut} from 'react-icons/fi';
import { AiOutlineClose} from 'react-icons/ai';
import { useStateContext } from '../../context/StateContext';
import {NavLink} from 'react-router-dom';
import {CarouselData} from '../Sliders/Data/CarouselData'



function Sidebar() {

    const { setShowSidebar} = useStateContext();
    const [user] = useState(false); //for now, remove later

  return (
    <div >
  
        <div className='side-wrapper'>
                <div className="side-container">
                    <div className='sidebar-header'>
                        <div className="side-logo">
                        <NavLink to={`/`}><img src={logo} className="side-logo-img" alt='' onClick={() => setShowSidebar(false)}/></NavLink>
                        </div>
                        <div className='sidebar-header-icon'>
                            <AiOutlineClose className='side-h-icon' onClick={() => setShowSidebar(false)}/>
                            </div>
                    </div>             
                    <div className="side-user">
                        <div className='side-flex-details'>
                            <BsPersonFill className="side-icon"/>
                            { user ?  <h4> {user.firstName} {user.lastName}</h4>  : (<h4 > Guest</h4>)}  
                        </div>
                        { user ?
                        <div>
                            <p className='side-paragraph'>Check profile</p>
                            <p className='side-paragraph'>Edit profile</p>
                        </div>
                        :
                        <div>
                            <p className='side-paragraph'>Login</p>
                            <p className='side-paragraph'>Register</p>
                        </div>                
                        }
                    </div>
                        <div className='side-horizontal'></div>
                            <div className='side-shop-product'>                                                    
                                <div className='side-flex-details'>
                                    <HiShoppingCart className="side-icon"/>
                                    <h4> Shop by product</h4>
                                </div>
                                <ul>
                                {CarouselData.map((item )=> (
                                <NavLink to={`/category/${item.category}`}  onClick={() => window.location = `/category/${item.category}`}>
                                    <li className='side-paragraph' key={item.id}> {item.category} </li>  
                                </NavLink>
                             ))} 
                                </ul>                                
                            </div>  

                        {/* <div className='side-horizontal'></div>    

                        <div className="side-shop-shop">
                            <div className='side-flex-details'>
                                <FaStoreAlt />
                                <p> Shop by store</p>
                            </div>
                            <p className='side-paragraph'> Costco </p>  
                            <p className='side-paragraph'> Loblaws</p>  
                            <p className='side-paragraph'> Real canadian store</p>  
                            <p className='side-paragraph'> Save on food</p>  
                            <p className='side-paragraph'> T&T supermarket</p> 
                            <p className='side-paragraph'> Walmart</p>                    
                        </div> */}


                        <div className='side-horizontal'></div>    

                        {/*<div className="side-shop-shop">
                            <div className='side-flex-details'>
                                <HiShoppingCart />
                                <p> Shop by brand</p>
                            </div>
                            <div>
                                {items.map(item => {
                                    return ( 
                                        <ul>
                                            {item.items.map(inside => {
                                            return ( 
                                                <li className='side-paragraph'> {inside.brand}.groupBy() </li>  
                                            )
                                        })}
                                        </ul>
                                    
                                )})}
                            </div>                     
                        </div>            

                        <div className='side-horizontal'></div>*/}

                        <div className="side-logout">
                            { user ?
                            <div>
                                <FiLogOut className="side-icon"/>
                                <h4 className='side-logout-paragraph'>Logout</h4> 
                            </div>
                            : null}
                        </div>                                          
                </div>
        </div>
      
      </div>        
  )
}

export default Sidebar