import React from 'react';
import { AiOutlineSend } from "react-icons/ai";

function Newsletter() {
  return (
    <div className='NewsletterContainer'>
        <h3 className='NewsletterTitle'> Newsletter</h3>
        <p className='NewsletterDescription'> Keep track of your favorite products</p>
        <div className='NewsletterInputContainer'>
            <input className='NewsletterInput' placeholder='Your Email'></input>
            <button className='NewsletterButton'>
                <AiOutlineSend className='NewsletterIcon'/>
            </button>
        </div>    
    </div>
  )
}

export default Newsletter