import React, {useState, useEffect} from 'react'
import useCollapse from 'react-collapsed';
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";


function Section(props) {

  const config = {
      defaultExpanded: props.defaultExpanded || false,
      collapsedHeight: props.collapsedHeight || 0
  };
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);
return (
  <div className="collapsible">
      <div className="header" {...getToggleProps()}>
          <div className="data-title">{props.title}</div>
          <div className="icon">
            {isExpanded ?
              <RiArrowDropUpLine/>
            :
              <RiArrowDropDownLine/>
            }
              
          </div>
      </div>
      <div {...getCollapseProps()}>
          <div className="content">
              {props.children}
          </div>
      </div>
  </div>
);
}



function FetchedData() {
  const [data, setData] = useState();
  const [jsonData, setjsonData] = useState();
  const Total = data?.loblaws?.Total + data?.realcanadiansuperstore?.Total + data?.nofrills?.Total + data?.saveonfoods?.Total + data?.wallmart?.Total + data?.TNT?.Total
  const loblawsItems = data?.loblaws?.Total - jsonData?.['Loblaws json']
  const realcanadiansuperstoreItems = data?.realcanadiansuperstore?.Total - jsonData?.['RCSS json']
  const nofrillsItems = data?.nofrills?.Total - jsonData?.['Nofirlls json']
  const saveonfoods = data?.saveonfoods?.Total - jsonData?.['SaveOnFoods json']
  const wallmartItems = data?.wallmart?.Total - jsonData?.['Walmart json']
  const tntItems = data?.TNT?.Total - jsonData?.['TNT json']

  // Function to collect data
  const getApiData = async () => {
    //setIsLoading(true);
    const response = await fetch(
      `https://api.click-n.com/admin_stats`      
    ).then((response) => response.json());
    //setIsLoading(false);

    // update the state
    setData(response[0]);
    setjsonData(response[1]);
    console.log('new data', data)
  };

  useEffect(() => {
  getApiData();
  // eslint-disable-next-line
}, []);


  




  return (
    <div className='FetchedData-container'>
        <>
          <div className='Banners'>
            <div className='FetchedData-itemsCount'> {/* All Items*/}
              <h1 className='FetchedData-itemsCount-title'> Items</h1>
              {!isNaN(Total) ?
                <p className='FetchedData-itemsCount-paragraph'>{Total}</p>
              : <p style={{textAlign: 'center'}} > calculating ...</p>} 
            </div>
            <div className='FetchedData-itemsCount'> {/* All Stores*/}
              <h1 className='FetchedData-itemsCount-title'> Stores</h1>          
              <p className='FetchedData-itemsCount-paragraph'>6</p>
            </div>
            <div className='FetchedData-itemsCount'> {/* All Categories*/}
              <h1 className='FetchedData-itemsCount-title'>Categories</h1>              
              <p className='FetchedData-itemsCount-paragraph'>17</p>
            </div>                       
          </div>

            <>
              <div className='Dash-data-container-flex' style={{marginTop: '20px', marginLeft: 'auto', marginRight: 'auto'}}>
                <div className='Dash-data-container' style={{width: '80%', marginTop: '20px', marginLeft: 'auto', marginRight: 'auto'}}>
                  <div className='OrderList-data-container-inner-flex'>
                  <div style={{paddingTop:'20px'}}>
                      <div className='OrderList-inside-Data'>
                        <h2 className='OrderList-Cart' style={{textAlign: 'center'}}> Script Count </h2>       
                      </div>
                        <h4 className='OrderList-Cart' style={{fontSize:"15px", fontWeight:'600'}}> loblaws: <span className='ORderList-Span'style={{fontWeight:'400'}}>{jsonData?.['Loblaws json']}</span></h4 >
                        <h4  className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> realcanadiansuperstore: <span className='ORderList-Span'style={{fontWeight:'400'}}>{jsonData?.['RCSS json']}</span></h4>
                        <h4  className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> nofrills: <span className='ORderList-Span'style={{fontWeight:'400'}}>{jsonData?.['Nofirlls json']}</span></h4>
                        <h4  className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}>  saveonfoods: <span className='ORderList-Span'style={{fontWeight:'400'}}>{jsonData?.['SaveOnFoods json']}</span></h4>
                        <h4  className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}>  walmart: <span className='ORderList-Span'style={{fontWeight:'400'}}>{jsonData?.['Walmart json']}</span></h4>
                        <h4  className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}>  T&T Store: <span className='ORderList-Span'style={{fontWeight:'400'}}>{jsonData?.['TNT json']}</span></h4>
                    </div>   
                    <div style={{borderLeft: '1px solid #828282', padding:'20px'}}>
                    <div className='OrderList-inside-Data'>
                        <h2 className='OrderList-Cart' style={{textAlign: 'center'}}> Database Count </h2>       
                      </div>
                      <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> loblaws: <span className='ORderList-Span'style={{fontWeight:'400'}}>{data?.loblaws?.Total}</span></h2> 
                      <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> realcanadiansuperstore: <span className='ORderList-Span'style={{fontWeight:'400'}}>{data?.realcanadiansuperstore?.Total}</span></h2>
                      <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> nofrills: <span className='ORderList-Span'style={{fontWeight:'400'}}>{data?.nofrills?.Total}</span></h2>
                      <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}>  saveonfoods: <span className='ORderList-Span'style={{fontWeight:'400'}}>{data?.saveonfoods?.Total}</span></h2>
                      <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}>  walmart: <span className='ORderList-Span'style={{fontWeight:'400'}}>{data?.wallmart?.Total}</span></h2>
                      <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}>  T&T Store: <span className='ORderList-Span'style={{fontWeight:'400'}}>{data?.TNT?.Total}</span></h2>    
                    </div>
                    <div style={{borderLeft: '1px solid #828282', padding:'20px'}}>
                      <div className='OrderList-inside-Data'>
                        <h2 className='OrderList-Cart' style={{textAlign: 'center'}}> Diffrence </h2>       
                      </div>
                        {!isNaN(loblawsItems) ?
                          <h4 className='OrderList-Cart' style={{fontSize:"15px", fontWeight:'600'}}> loblaws: <span className='ORderList-Span' style={{fontWeight:'400'}}>{loblawsItems}</span></h4 >
                        : <p style={{textAlign: 'center'}} > calculating ...</p>} 
                        {!isNaN(realcanadiansuperstoreItems) ?
                          <h4  className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> realcanadiansuperstore: <span className='ORderList-Span' style={{fontWeight:'400'}}>{realcanadiansuperstoreItems}</span></h4>
                        : <p style={{textAlign: 'center'}} > calculating ...</p>}
                        {!isNaN(nofrillsItems) ? 
                          <h4  className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> nofrills: <span className='ORderList-Span' style={{fontWeight:'400'}}>{nofrillsItems}</span></h4>
                        : <p style={{textAlign: 'center'}} > calculating ...</p>}
                        {!isNaN(saveonfoods) ? 
                          <h4  className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}>  saveonfoods: <span className='ORderList-Span' style={{fontWeight:'400'}}>{saveonfoods}</span></h4>
                        : <p style={{textAlign: 'center'}} > calculating ...</p>}
                        {!isNaN(wallmartItems) ?
                          <h4  className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}>  walmart: <span className='ORderList-Span' style={{fontWeight:'400'}}>{wallmartItems}</span></h4>
                        : <p style={{textAlign: 'center'}} > calculating ...</p>}
                        {!isNaN(tntItems) ?
                          <h4  className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}>  T&T Store: <span className='ORderList-Span' style={{fontWeight:'400'}}>{tntItems}</span></h4>
                        : <p style={{textAlign: 'center'}} > calculating ...</p>}
                    </div>                             
                  </div>
                </div>
              </div>
              <div className='Dash-data-container' style={{width: '80%', marginTop: '20px', marginLeft: 'auto', marginRight: 'auto'}}>
              <>
              <Section className='address-section first-secion' title="Loblaws" defaultExpanded="true">
                {data ?
                  <>
                  <Section className='address-section first-secion' title="Baby" defaultExpanded="false">
                    {Object.entries(data?.loblaws).slice(0, 7).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i} >
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Bakery" defaultExpanded="false">
                    {Object.entries(data?.loblaws).slice(7, 14).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Beverages & Drinks" defaultExpanded="false">
                    {Object.entries(data?.loblaws).slice(14, 26).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Dairy & Eggs" defaultExpanded="false">
                    {Object.entries(data?.loblaws).slice(26, 36).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Deli" defaultExpanded="false">
                    {Object.entries(data?.loblaws).slice(36, 45).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Fish & Sea food" defaultExpanded="false">
                    {Object.entries(data?.loblaws).slice(45, 54).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Floral and Garden" defaultExpanded="false">
                    {Object.entries(data?.loblaws).slice(54, 55).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Frozen" defaultExpanded="false">
                    {Object.entries(data?.loblaws).slice(55, 66).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Fruits & Vegetables" defaultExpanded="false" style={{marginBottom:'-50px'}}>
                    {Object.entries(data?.loblaws).slice(66, 74).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Health & Beauty" defaultExpanded="false">
                    {Object.entries(data?.loblaws).slice(74, 92).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Household" defaultExpanded="false">
                    {Object.entries(data?.loblaws).slice(92, 106).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="International Foods" defaultExpanded="false">
                    {Object.entries(data?.loblaws).slice(106, 113).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Meat" defaultExpanded="false">
                    {Object.entries(data?.loblaws).slice(113, 120).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Natural Foods" defaultExpanded="false">
                    {Object.entries(data?.loblaws).slice(120, 131).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Pantry" defaultExpanded="false">
                    {Object.entries(data?.loblaws).slice(131, 147).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Pets Supplies" defaultExpanded="false">
                    {Object.entries(data?.loblaws).slice(147, 155).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Prepared Foods" defaultExpanded="false">
                    {Object.entries(data?.loblaws).slice(155, 165).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Snacks & Candy" defaultExpanded="false">
                    {Object.entries(data?.loblaws).slice(165, -1).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  </>  
                : null}
              </Section>
              <Section className='address-section first-secion' title="Real Canadian super Store" defaultExpanded="false">
                {data ?
                  <>
                  <Section className='address-section first-secion' title="Baby" defaultExpanded="false">
                    {Object.entries(data?.realcanadiansuperstore).slice(0, 7).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Bakery" defaultExpanded="false">
                    {Object.entries(data?.realcanadiansuperstore).slice(7, 14).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Beverages & Drinks" defaultExpanded="false">
                    {Object.entries(data?.realcanadiansuperstore).slice(14, 26).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Dairy & Eggs" defaultExpanded="false">
                    {Object.entries(data?.realcanadiansuperstore).slice(26, 36).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Deli" defaultExpanded="false">
                    {Object.entries(data?.realcanadiansuperstore).slice(36, 45).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Fish & Sea food" defaultExpanded="false">
                    {Object.entries(data?.realcanadiansuperstore).slice(45, 54).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Floral and Garden" defaultExpanded="false">
                    {Object.entries(data?.realcanadiansuperstore).slice(54, 55).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Frozen" defaultExpanded="false">
                    {Object.entries(data?.realcanadiansuperstore).slice(55, 66).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Fruits & Vegetables" defaultExpanded="false">
                    {Object.entries(data?.realcanadiansuperstore).slice(66, 74).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Health & Beauty" defaultExpanded="false">
                    {Object.entries(data?.realcanadiansuperstore).slice(74, 92).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Household" defaultExpanded="false">
                    {Object.entries(data?.realcanadiansuperstore).slice(92, 106).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="International Foods" defaultExpanded="false">
                    {Object.entries(data?.realcanadiansuperstore).slice(106, 113).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Meat" defaultExpanded="false">
                    {Object.entries(data?.realcanadiansuperstore).slice(113, 120).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Natural Foods" defaultExpanded="false">
                    {Object.entries(data?.realcanadiansuperstore).slice(120, 131).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Pantry" defaultExpanded="false">
                    {Object.entries(data?.realcanadiansuperstore).slice(131, 147).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Pets Supplies" defaultExpanded="false">
                    {Object.entries(data?.realcanadiansuperstore).slice(147, 155).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Prepared Foods" defaultExpanded="false">
                    {Object.entries(data?.realcanadiansuperstore).slice(155, 165).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Snacks & Candy" defaultExpanded="false">
                    {Object.entries(data?.realcanadiansuperstore).slice(165, -1).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  </>  
                : null}
              </Section>
              <Section className='address-section first-secion' title="NoFrills" defaultExpanded="false">
                {data ?
                  <>
                  <Section className='address-section first-secion' title="Baby" defaultExpanded="false">
                    {Object.entries(data?.nofrills).slice(0, 7).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Bakery" defaultExpanded="false">
                    {Object.entries(data?.nofrills).slice(7, 14).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Beverages & Drinks" defaultExpanded="false">
                    {Object.entries(data?.nofrills).slice(14, 26).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Dairy & Eggs" defaultExpanded="false">
                    {Object.entries(data?.nofrills).slice(26, 36).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Deli" defaultExpanded="false">
                    {Object.entries(data?.nofrills).slice(36, 45).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Fish & Sea food" defaultExpanded="false">
                    {Object.entries(data?.nofrills).slice(45, 54).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Floral and Garden" defaultExpanded="false">
                    {Object.entries(data?.nofrills).slice(54, 55).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Frozen" defaultExpanded="false">
                    {Object.entries(data?.nofrills).slice(55, 66).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Fruits & Vegetables" defaultExpanded="false">
                    {Object.entries(data?.nofrills).slice(66, 74).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Health & Beauty" defaultExpanded="false">
                    {Object.entries(data?.nofrills).slice(74, 92).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Household" defaultExpanded="false">
                    {Object.entries(data?.nofrills).slice(92, 106).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="International Foods" defaultExpanded="false">
                    {Object.entries(data?.nofrills).slice(106, 113).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Meat" defaultExpanded="false">
                    {Object.entries(data?.nofrills).slice(113, 120).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Natural Foods" defaultExpanded="false">
                    {Object.entries(data?.nofrills).slice(120, 131).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Pantry" defaultExpanded="false">
                    {Object.entries(data?.nofrills).slice(131, 147).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Pets Supplies" defaultExpanded="false">
                    {Object.entries(data?.nofrills).slice(147, 155).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Prepared Foods" defaultExpanded="false">
                    {Object.entries(data?.nofrills).slice(155, 165).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Snacks & Candy" defaultExpanded="false">
                    {Object.entries(data?.nofrills).slice(165, -1).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  </>  
                : null}
              </Section>
              <Section className='address-section first-secion' title="Wallmart" defaultExpanded="false">
                {data ?
                  <>
                  <Section className='address-section first-secion' title="Baby" defaultExpanded="false">
                    {Object.entries(data?.wallmart).slice(0, 7).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Bakery" defaultExpanded="false">
                    {Object.entries(data?.wallmart).slice(7, 14).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Beverages & Drinks" defaultExpanded="false">
                    {Object.entries(data?.wallmart).slice(14, 26).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Dairy & Eggs" defaultExpanded="false">
                    {Object.entries(data?.wallmart).slice(26, 36).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Deli" defaultExpanded="false">
                    {Object.entries(data?.wallmart).slice(36, 45).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Fish & Sea food" defaultExpanded="false">
                    {Object.entries(data?.wallmart).slice(45, 54).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Floral and Garden" defaultExpanded="false">
                    {Object.entries(data?.wallmart).slice(54, 55).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Frozen" defaultExpanded="false">
                    {Object.entries(data?.wallmart).slice(55, 66).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Fruits & Vegetables" defaultExpanded="false">
                    {Object.entries(data?.wallmart).slice(66, 74).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Health & Beauty" defaultExpanded="false">
                    {Object.entries(data?.wallmart).slice(74, 92).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Household" defaultExpanded="false">
                    {Object.entries(data?.wallmart).slice(92, 106).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="International Foods" defaultExpanded="false">
                    {Object.entries(data?.wallmart).slice(106, 113).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Meat" defaultExpanded="false">
                    {Object.entries(data?.wallmart).slice(113, 120).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Natural Foods" defaultExpanded="false">
                    {Object.entries(data?.wallmart).slice(120, 131).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Pantry" defaultExpanded="false">
                    {Object.entries(data?.wallmart).slice(131, 147).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Pets Supplies" defaultExpanded="false">
                    {Object.entries(data?.wallmart).slice(147, 155).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Prepared Foods" defaultExpanded="false">
                    {Object.entries(data?.wallmart).slice(155, 165).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Snacks & Candy" defaultExpanded="false">
                    {Object.entries(data?.wallmart).slice(165, -1).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  </>  
                : null}
              </Section>
              <Section className='address-section first-secion' title="Saveonfoods" defaultExpanded="false">
                {data ?
                  <>
                  <Section className='address-section first-secion' title="Baby" defaultExpanded="false">
                    {Object.entries(data?.saveonfoods).slice(0, 7).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Bakery" defaultExpanded="false">
                    {Object.entries(data?.saveonfoods).slice(7, 14).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Beverages & Drinks" defaultExpanded="false">
                    {Object.entries(data?.saveonfoods).slice(14, 26).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Dairy & Eggs" defaultExpanded="false">
                    {Object.entries(data?.saveonfoods).slice(26, 36).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Deli" defaultExpanded="false">
                    {Object.entries(data?.saveonfoods).slice(36, 45).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Fish & Sea food" defaultExpanded="false">
                    {Object.entries(data?.saveonfoods).slice(45, 54).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Floral and Garden" defaultExpanded="false">
                    {Object.entries(data?.saveonfoods).slice(54, 55).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Frozen" defaultExpanded="false">
                    {Object.entries(data?.saveonfoods).slice(55, 66).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Fruits & Vegetables" defaultExpanded="false">
                    {Object.entries(data?.saveonfoods).slice(66, 74).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Health & Beauty" defaultExpanded="false">
                    {Object.entries(data?.saveonfoods).slice(74, 92).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Household" defaultExpanded="false">
                    {Object.entries(data?.saveonfoods).slice(92, 106).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="International Foods" defaultExpanded="false">
                    {Object.entries(data?.saveonfoods).slice(106, 113).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Meat" defaultExpanded="false">
                    {Object.entries(data?.saveonfoods).slice(113, 120).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Natural Foods" defaultExpanded="false">
                    {Object.entries(data?.saveonfoods).slice(120, 131).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Pantry" defaultExpanded="false">
                    {Object.entries(data?.saveonfoods).slice(131, 147).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Pets Supplies" defaultExpanded="false">
                    {Object.entries(data?.saveonfoods).slice(147, 155).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Prepared Foods" defaultExpanded="false">
                    {Object.entries(data?.saveonfoods).slice(155, 165).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Snacks & Candy" defaultExpanded="false">
                    {Object.entries(data?.saveonfoods).slice(165, -1).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  </>  
                : null}
              </Section>
              <Section className='address-section first-secion' title="T&T Store" defaultExpanded="false">
                {data ?
                  <>
                  <Section className='address-section first-secion' title="Baby" defaultExpanded="false">
                    {Object.entries(data?.TNT).slice(0, 7).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Bakery" defaultExpanded="false">
                    {Object.entries(data?.TNT).slice(7, 14).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Beverages & Drinks" defaultExpanded="false">
                    {Object.entries(data?.TNT).slice(14, 26).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Dairy & Eggs" defaultExpanded="false">
                    {Object.entries(data?.TNT).slice(26, 36).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Deli" defaultExpanded="false">
                    {Object.entries(data?.TNT).slice(36, 45).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Fish & Sea food" defaultExpanded="false">
                    {Object.entries(data?.TNT).slice(45, 54).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Floral and Garden" defaultExpanded="false">
                    {Object.entries(data?.TNT).slice(54, 55).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Frozen" defaultExpanded="false">
                    {Object.entries(data?.TNT).slice(55, 66).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Fruits & Vegetables" defaultExpanded="false">
                    {Object.entries(data?.TNT).slice(66, 74).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Health & Beauty" defaultExpanded="false">
                    {Object.entries(data?.TNT).slice(74, 92).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Household" defaultExpanded="false">
                    {Object.entries(data?.TNT).slice(92, 106).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="International Foods" defaultExpanded="false">
                    {Object.entries(data?.TNT).slice(106, 113).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Meat" defaultExpanded="false">
                    {Object.entries(data?.TNT).slice(113, 120).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Natural Foods" defaultExpanded="false">
                    {Object.entries(data?.TNT).slice(120, 131).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Pantry" defaultExpanded="false">
                    {Object.entries(data?.TNT).slice(131, 147).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Pets Supplies" defaultExpanded="false">
                    {Object.entries(data?.TNT).slice(147, 155).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Prepared Foods" defaultExpanded="false">
                    {Object.entries(data?.TNT).slice(155, 165).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  <Section className='address-section first-secion' title="Snacks & Candy" defaultExpanded="false">
                    {Object.entries(data?.TNT).slice(165, -1).map(([key, val], i)  => (
                      <div className='OrderList-data-container-inner' key={i}>
                        <h2 className='OrderList-Cart'style={{fontSize:"15px", fontWeight:'600'}}> {key}: <span style={{marginLeft:'30px', fontWeight:'500'}}>{val}</span> </h2>         
                      </div>
                    ))} 
                  </Section>
                  </>  
                : null}
              </Section>
              </>
            </div>
          </> 
        </>   
    </div>
  )
}

export default FetchedData