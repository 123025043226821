import React, { useEffect, useState } from "react";
import {
  CategoriesFilter,
  ProductCard,
  StoresFilter,
} from "../Components/index";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HomePageSkeleton } from "../Components/index";
import { useMemo } from "react";


export default function Products() {
  const [products, setProducts] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const cachedProducts = useMemo(() => {
    const cachedProducts = sessionStorage.getItem("products");
    if (cachedProducts) {
      return JSON.parse(cachedProducts);
    }
    return null;
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const cachedResponse = sessionStorage.getItem("productResponse");
        if (cachedResponse) {
          setProducts(JSON.parse(cachedResponse));
          setIsLoading(false);
        } else {
          const res = await fetch(
            `https://api.click-n.com/find_category?find=&items=100`
          );
          const data = await res.json();
          setProducts(data);
          sessionStorage.setItem("productResponse", JSON.stringify(data));
          setIsLoading(false);
        }
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    if (cachedProducts) {
      setProducts(cachedProducts);
      setIsLoading(false);
    } else {
      fetchProduct();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (products) {
      sessionStorage.setItem("products", JSON.stringify(products));
    }
  }, [products]);

  if (isLoading) {
    return <HomePageSkeleton />;
  }

  if (error) {
    return <h2>{error.message}</h2>;
  }

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <>
      {/*<Slider/>*/}
      <div className="Products-page-Ads" style={{ marginTop: "170px" }}>
        <div className="Products-page-Bigbanner">
          <div className="first-div">
            <Slider {...settings} className="bigslider">
              <div className="iner-ads-div">
                <div className="iner-ads-div-content">
                  <h1 style={{ color: "#0AAD0A", fontSize: "600" }}>
                    Make your shopping esier
                  </h1>
                  <p>
                    ClickN makes online grocery shopping fast and easy. Get
                    groceries delivered and order the best of seasonal farm
                    fresh food.
                  </p>
                </div>
                <div className="iner-ads-div-image">
                  <img
                    className="iner-ads-div-image-image"
                    src="https://i.ibb.co/Gc2b5NB/app-thumbnail-2.webp"
                    alt="app-thumbnail-2"
                    border="0"
                  />
                </div>
              </div>
              <div className="iner-ads-div">
                <div className="iner-ads-div-content">
                  <h1 style={{ color: "#0AAD0A", fontSize: "600" }}>
                    Make your shopping esier
                  </h1>
                  <p>
                    ClickN makes online grocery shopping fast and easy. Get
                    groceries delivered and order the best of seasonal farm
                    fresh food.
                  </p>
                </div>
                <div className="iner-ads-div-image">
                  <img
                    className="iner-ads-div-image-image"
                    src="https://i.ibb.co/Gc2b5NB/app-thumbnail-2.webp"
                    alt="app-thumbnail-2"
                    border="0"
                  />
                </div>
              </div>
            </Slider>
          </div>
        </div>

        <div className="Products-page-small">
          <div className="Product-sage-small-head">
            <h5
              className="Products-page-small-title"
              style={{
                marginTop: "20px",
                textAlign: "center",
                fontWeight: "600",
                fontSize: "26px",
                color: "#DE3634",
              }}
            >
              Savings of the day
            </h5>
          </div>
          <div className="first-div">
            <Slider {...settings} className="smalllider">
              {products.list
                .filter(
                  (product) =>
                    product.sale?.old_price !== undefined &&
                    Math.round(
                      ((1 - product.price / product.sale.old_price) *
                        100 *
                        100) /
                        100
                    ) >= 5 &&
                    product.price > 5
                )
                .slice(0, 10)
                .map((product, i) => (
                  <div key={i}>
                    <div className="iner-small-div" key={product.id}>
                      <img
                        className="item-image"
                        src={product.image}
                        alt="items"
                      />
                      <h6
                        style={{
                          marginBottom: "10px",
                          marginTop: "13px",
                          textAlign: "center",
                          fontWeight: "400",
                        }}
                      >
                        {product.name}
                      </h6>
                      {product.sale?.old_price !== undefined &&
                      product.sale?.old_price > product.price ? (
                        <>
                          <div
                            className="iner-small-div-price"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "15px",
                            }}
                          >
                            <h6
                              style={{
                                fontSize: "16px",
                                fontWeight: "600",
                                color: "#2a8703",
                                marginTop: "2px",
                              }}
                            >
                              {" "}
                              Now ${product.price}
                            </h6>
                            <h6
                              style={{
                                textDecoration: "line-through",
                                color: " #74767c",
                                fontSize: "16px",
                                marginTop: "2px",
                              }}
                            >
                              ${product.sale.old_price}
                            </h6>
                            {/*<span className='sales-span-banner' style={{width: '35%', textAlign: 'center'}}> Save Up to {Math.round((((1 - (product.price / product.sale.old_price)) *100) *100) / 100)} %</span> */}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className="homepage-banner"></div>
      <div className="Products-page-items">
        <div className="Products-page-border">
          <h4 className="ProductCard-Bigtitle"> Shop By Stores</h4>
        </div>
        <StoresFilter />
      </div>
      <div className="Products-page-items">
        <h4 className="ProductCard-Bigtitle"> Shop By Categories</h4>
        <CategoriesFilter />
      </div>
      <div className="Products-page-carousel">
        <ProductCard />
      </div>

      {/*<div className="Products-page-items">
      <StoresFilter />
    </div>*/}
    </>
  );
}
