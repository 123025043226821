import React, {useState, useEffect} from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import { useStateContext } from '../../context/StateContext';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//import {useQuery} from 'react-query'
//import {useParams} from 'react-router';

//import {CarouselData} from '.././Sliders/Data/CarouselData'



//import { ProductData } from './Data/ProductData';


function SubcategoryStores() {

  const {onAdd} = useStateContext();
  const [data, setData] = useState();
  const search = useLocation().search;
  const query = new URLSearchParams(search).get('sub');
  //const {cat} = useParams();
  //


  const getApiData = async () => {
    const response = await fetch(
      //"https://api.click-n.com/find?find=&items=5000"
      `https://api.click-n.com/find_category?find=,+${query}&items=20000`
           
    ).then((response) => response.json());

    // update the state
    setData(response);
  };

  useEffect(() => {
  getApiData();
     // eslint-disable-next-line
}, []);

   
    const settings = {
        dots: true,
        autoplay: true,
        
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 5,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };

    return (
      <div >
        <div className='ProductCard-container' >
        <div className='ProductCard-items' style={{ marginTop: '40px'}}>               
            <div className='ProductCard-items'> 
            {/*<div className ='Info-Slider'>
              {CarouselData.map((item )=> ( 
                <>
                {item.subCategory !== undefined ?
                  <>
                    {item.subCategory.map((subItem) => ( 
                      <>
                        <h1> Real Canadian super Store</h1>
                        <NavLink to={`/all/sub=${subItem.title}/${item.category}?sub=${subItem.title}`}>
                          <p> See All</p> 
                        </NavLink>
                      </>
                    ))} 
                  </>
                : null} 
                </>
              ))}
                    </div>  */}                        
              <Slider {...settings} className='ProductCard-slider'>
                {data?.list.filter((product) => product.store ===  'realcanadiansuperstore').slice(0,20).map((product) => (
                  <div className="ProductCard-item-list"  key={product.category} style={{ marginBottom: '20px', width: '60px'}}>                              
                    <div className='item-car-header' style={{ width: '80%'}}>                  
                      <NavLink to={`/products/${product.id}/${product.name}`}> <img className="ProductCard-image"
                        src={product.image} style={{ width: '80%'}} alt='items'/>
                      </NavLink>
                    </div>                                    
                    <div className='ProductCard-details'style={{height: '42px'}}>               
                      <p className="ProductCard-name" style={{fontSize: "13px", width: '75%', marginBottom: '20px', fontWeight: 'Bold'}}>{product.name.substring(0,40)}</p>                   
                    </div>
                    <div className='ProductCard-details'style={{height: '42px'}}>               
                      <p className="ProductCard-name" style={{fontSize: "13px", width: '75%', marginBottom: '20px', color: '#828282'}}>{product.store}</p>                     
                    </div>                             
                    <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                      <p className="ProductCard-price" style={{fontSize: "13px"}}>${product.price}</p>
                      { product.sale?.old_price !== undefined ?
                        <p className='ProductCard-avg-price' style={{ fontSize: "13px", textDecoration: 'line-through'}}>${product.sale.old_price}</p> 
                      : null}  
                    </div>
                    {/*<p className="productcard-unit" style={{fontSize: "10px", color: '#828282', marginTop:'-10px'}}>{product.unitOfSize.size} {product.unitOfSize.abbreviation} - {product.pricePerUnit}</p> */}
                    <button className='product-addtocart-slider' onClick={() => onAdd(product, 1)}> Add to cart</button> 
                    <button className='product-addtocart-slider-compare' onClick={() => onAdd(product, 1)}> Compare Prices</button> 
                  </div>                                                              
                ))}
              </Slider> 
            </div>
          </div>
          <div className='ProductCard-items' style={{ marginTop: '40px'}}>               
            <div className='ProductCard-items'>  
            {/*<div className ='Info-Slider'> 
              <h1> Loblaws</h1>
              <NavLink to={`/subcat/all/sub=${query}/${cat}`}>
              <p> See All</p> 
              </NavLink> 
                      </div>  */}                          
              <Slider {...settings} className='ProductCard-slider'>
                {data?.list.filter((product) => product.store ===  'loblaws').slice(0,20).map((product) => (
                  <div className="ProductCard-item-list"  key={product.category} style={{ marginBottom: '20px', width: '60px'}}>                              
                    <div className='item-car-header' style={{ width: '80%'}}>                  
                      <NavLink to={`/products/${product.id}/${product.name}`}> <img className="ProductCard-image"
                        src={product.image} style={{ width: '80%'}} alt='items'/>
                      </NavLink>
                    </div>                                    
                    <div className='ProductCard-details'style={{height: '42px'}}>               
                      <p className="ProductCard-name" style={{fontSize: "13px", width: '75%', marginBottom: '20px', fontWeight: 'Bold'}}>{product.name.substring(0,40)}</p>                   
                    </div>
                    <div className='ProductCard-details'style={{height: '42px'}}>               
                      <p className="ProductCard-name" style={{fontSize: "13px", width: '75%', marginBottom: '20px', color: '#828282'}}>{product.store}</p>                     
                    </div>                                                      
                    <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                      <p className="ProductCard-price" style={{fontSize: "13px"}}>${product.price}</p>
                      { product.sale?.old_price !== undefined ?
                        <p className='ProductCard-avg-price' style={{ fontSize: "13px", textDecoration: 'line-through'}}>${product.sale.old_price}</p> 
                      : null}  
                    </div>
                    {/*<p className="productcard-unit" style={{fontSize: "10px", color: '#828282', marginTop:'-10px'}}>{product.unitOfSize.size} {product.unitOfSize.abbreviation} - {product.pricePerUnit}</p> */}
                    <button className='product-addtocart-slider' onClick={() => onAdd(product, 1)}> Add to cart</button>  
                    <button className='product-addtocart-slider-compare' onClick={() => onAdd(product, 1)}> Compare Prices</button> 
                  </div>                                                              
                ))}
              </Slider> 
            </div>
          </div>
          <div className='ProductCard-items' style={{ marginTop: '40px'}}>               
            <div className='ProductCard-items'>  
            {/*<div className ='Info-Slider'> 
              <h1> Loblaws</h1>
              <NavLink to={`/subcat/all/sub=${query}/${cat}`}>
              <p> See All</p> 
              </NavLink> 
                      </div>  */}                          
              <Slider {...settings} className='ProductCard-slider'>
                {data?.list.filter((product) => product.store ===  'realcanadiansuperstore').slice(0,20).map((product) => (
                  <div className="ProductCard-item-list"  key={product.category} style={{ marginBottom: '20px', width: '60px'}}>                              
                    <div className='item-car-header' style={{ width: '80%'}}>                  
                      <NavLink to={`/products/${product.id}/${product.name}`}> <img className="ProductCard-image"
                        src={product.image} style={{ width: '80%'}} alt='items'/>
                      </NavLink>
                    </div>                                    
                    <div className='ProductCard-details'style={{height: '42px'}}>               
                      <p className="ProductCard-name" style={{fontSize: "13px", width: '75%', marginBottom: '20px', fontWeight: 'Bold'}}>{product.name.substring(0,40)}</p>                   
                    </div>
                    <div className='ProductCard-details'style={{height: '42px'}}>               
                      <p className="ProductCard-name" style={{fontSize: "13px", width: '75%', marginBottom: '20px', color: '#828282'}}>{product.store}</p>                     
                    </div>                                                      
                    <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                      <p className="ProductCard-price" style={{fontSize: "13px"}}>${product.price}</p>
                      { product.sale?.old_price !== undefined ?
                        <p className='ProductCard-avg-price' style={{ fontSize: "13px", textDecoration: 'line-through'}}>${product.sale.old_price}</p> 
                      : null}  
                    </div>
                    {/*<p className="productcard-unit" style={{fontSize: "10px", color: '#828282', marginTop:'-10px'}}>{product.unitOfSize.size} {product.unitOfSize.abbreviation} - {product.pricePerUnit}</p> */}
                    <button className='product-addtocart-slider' onClick={() => onAdd(product, 1)}> Add to cart</button>  
                    <button className='product-addtocart-slider-compare' onClick={() => onAdd(product, 1)}> Compare Prices</button> 
                  </div>                                                              
                ))}
              </Slider> 
            </div>
          </div>          
          <div className='ProductCard-items' style={{ marginTop: '40px'}}>             
            <div className='ProductCard-items'>  
            {/*<div className ='Info-Slider'> 
              <h1> Save On foods</h1>
              <NavLink to={`/subcat/all/sub=${query}/${cat}`}>
              <p> See All</p> 
              </NavLink>
                      </div> */}                           
              <Slider {...settings} className='ProductCard-slider'>
                {data?.list.filter((product) => product.store ===  'saveonfoods').slice(0,20).map((product) => (
                  <div className="ProductCard-item-list"  key={product.category} style={{ marginBottom: '20px', width: '60px'}}>                              
                    <div className='item-car-header' style={{ width: '80%'}}>                  
                      <NavLink to={`/products/${product.id}/${product.name}`}> <img className="ProductCard-image"
                        src={product.image} style={{ width: '80%'}} alt='items'/>
                      </NavLink>
                    </div>                                    
                    <div className='ProductCard-details'style={{height: '42px'}}>               
                      <p className="ProductCard-name" style={{fontSize: "13px", width: '75%', marginBottom: '20px', fontWeight: 'Bold'}}>{product.name.substring(0,40)}</p>                   
                    </div>
                    <div className='ProductCard-details'style={{height: '42px'}}>               
                      <p className="ProductCard-name" style={{fontSize: "13px", width: '75%', marginBottom: '20px', color: '#828282'}}>{product.store}</p>                     
                    </div>                                                      
                    <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                      <p className="ProductCard-price" style={{fontSize: "13px"}}>${product.price}</p>
                      { product.sale?.old_price !== undefined ?
                        <p className='ProductCard-avg-price' style={{ fontSize: "13px", textDecoration: 'line-through'}}>${product.sale.old_price}</p> 
                      : null}  
                    </div>
                    {/*<p className="productcard-unit" style={{fontSize: "10px", color: '#828282', marginTop:'-10px'}}>{product.unitOfSize.size} {product.unitOfSize.abbreviation} - {product.pricePerUnit}</p> */}
                    <button className='product-addtocart-slider' onClick={() => onAdd(product, 1)}> Add to cart</button>  
                    <button className='product-addtocart-slider-compare' onClick={() => onAdd(product, 1)}> Compare Prices</button> 
                  </div>                                                              
                ))}
              </Slider> 
            </div>
          </div>                                                
        </div>                            
      </div>
    )
}

export default SubcategoryStores