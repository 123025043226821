import {React, useEffect, useState} from 'react'
//import { useStateContext } from '../../context/StateContext';
//import {useParams} from 'react-router';
import {NavLink} from 'react-router-dom';



function OrderList() {

  //const [status, setStatus] = useState('pending');
  //const [currentPage, setCurrentPage] = useState(0);
  const [carts, setCarts] = useState([]);

  //const {carts} = useStateContext();
  //const {id} = useParams();

// Get Carts
const getApiCarts = async () => {
  const response = await fetch(
    "https://api.click-n.com/get_carts"
  ).then((response) => response.json());

  setCarts(response);
};

  useEffect(() => {
    getApiCarts()
     // eslint-disable-next-line
  }, [])  






  return (
    <div className='OrderList-container'>
    <h2 className='OrderList-Title'> Order List: </h2>
    {carts.filter((cart) => cart.status ===  'pending' ).map((cart) => (
   /*<Table className='OrderList-table'>

      <Thead className='OrderList-thed'>
        <Tr className='OrderList-tr-header'>
          <Th className='OrderList-th'>Cart ID</Th>
          <Th className='OrderList-th'>Date Creation </Th>
          <Th className='OrderList-th'>Cart Status</Th>
          <Th className='OrderList-th'>Client</Th>
          <Th className='OrderList-th'>Address</Th>           
          <Th className='OrderList-th'>Zip Code</Th>           
          <Th className='OrderList-th'>Client Number</Th>      
        </Tr>
      </Thead>
      <Tbody className='OrderList-tbody'>
        
        <Tr className='OrderList-tr'>
          <NavLink to={`/order-details/${cart._id}`}>
            <Th className='OrderList-th-id'>{cart._id.substring(0,8)}</Th>
          </NavLink>
          <Th className='OrderList-th'>{cart.created_at.substring(0,11)}</Th>          
          <Th className='OrderList-th'>{cart.status}</Th>
          <Th className='OrderList-th'>{cart.buisness}</Th>
          <Th className='OrderList-th'>{cart.address1}</Th>
          <Th className='OrderList-th'>{cart.zip}</Th>
          <Th className='OrderList-th'>{cart.primarynumber}</Th>       
        </Tr>
         
      </Tbody> 
    
    </Table>*/
    <div className='OrderList-data-container'>
      <div className='OrderList-data-container-inner'>
        <div className='OrderList-inside-Data'>
          <h2 className='OrderList-Cart'> Cart ID: <span className='ORderList-Span'> {cart._id.substring(0,8)}</span></h2>
          <h2 className='OrderList-Cart'> Date Creation: <span className='ORderList-Span'>{cart.created_at.substring(0,11)}</span></h2>
          <h2 className='OrderList-Cart'> Cart Status: <span className='ORderList-Span'>{cart.status}</span></h2>        
        </div>

          <h2 className='OrderList-Cart'> Client Name: <span className='ORderList-Span'>{cart.buisness}</span></h2>
          <h2 className='OrderList-Cart'> Client Number: <span className='ORderList-Span'>{cart.address1}</span></h2>
  

          <h2 className='OrderList-Cart'> Zip Address: <span className='ORderList-Span'>{cart.address1}</span></h2>
          <h2 className='OrderList-Cart'> Zip Code: <span className='ORderList-Span'>{cart.zip}</span></h2>
        
      </div> 
      <NavLink to={`/order-details/${cart._id}`}> 
        <button className='OrderList-Button'> More Details</button> 
      </NavLink> 
    </div>

    ))} 
    </div>
  )
}

export default OrderList