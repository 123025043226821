import {React,  useState} from 'react'
import {NavLink, useLocation} from 'react-router-dom'
//import {useParams} from 'react-router';
//import ReactPaginate from "react-paginate";
import { RiHeartLine} from "react-icons/ri";
//import {ProductData} from '../Sliders/Data/ProductData';
import { useStateContext } from '../../context/StateContext';
//import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import {useQuery} from 'react-query'
import { HiArrowNarrowLeft, HiArrowNarrowRight } from "react-icons/hi";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
//import Slider from "react-slick";

function Items() {
  const {onAdd} = useStateContext();
  //const [items, setItems] = useState([]);
  //const [count, setCount] = useState(1);
  //const [pageCount, setpageCount] = useState(0);

  let limit = 45;
  //const {cat} = useParams();
  const search = useLocation().search;
  const query = new URLSearchParams(search).get('sub');
  console.log('query just cat', query)


const fetchUsers = async (page) => {
  const res = await fetch(`https://api.click-n.com/find_category?find=,+${query}&pages=${page}&items=${limit}`);
  return res.json();
}


  const [page, setPage] = useState(0);

  const {
    isLoading,
    isError,
    error,
    data,
    //isFetching,
  } = useQuery(['users', page], () => fetchUsers(page), { keepPreviousData: true });

//100-50%=99.5
  if (isLoading) {
    return <h2 className='Loading'>Loading...</h2>
  }

  if (isError) {
    return <h2>{error.message}</h2>
  }


  return (
    <div>
      <h1> Test </h1>
    <div className='item-container' >
      <div className='item-container-sub-container'>    
        {data?.list.map((product) => (
          <div className="item-card"  key={product.id}>
            <div className='item-card-div'>
              <div className="wishlist-button" title="wishlist-button">
                <div className="sales-div">
                  { product.sale?.old_price !== undefined && product.sale?.old_price > product.price?
                    <span className='sales-span-banner'> Save Up to { Math.round((((1 - (product.price / product.sale.old_price)) *100) *100) / 100)} %</span>
                  : null}
                </div>                 
                <RiHeartLine className='sales-span-icon'/>
              </div>  
                
              {/*<div className="wishlist-button" title="wishlist-button">
                <div className="sales-div">
                  { product.sale?.old_price !== undefined && product.sale?.old_price > product.price?
                    <span className='sales-span-banner'> Save Up to $ {product.sale.old_price - product.price } </span>
                  : null}
                </div>                  
              </div>  */}

              <div className='item-car-header'>                  
                <NavLink to={`/products/${product.id}/${product.name}`}> <img className="item-image"
                  src={product.image} alt='items' onClick={() => window.location = `/products/${product.id}/${product.name}`}/>
                </NavLink>
              </div>
              <span>{product.category}</span> 
                <div className='details-div'>
                  <div className='item-prices'>
                    <p className="product-price">{product.price}</p>
                      { product.sale?.old_price !== undefined && product.sale?.old_price > product.price?
                        <p className='product-avg-price'>${product.sale.old_price}</p>
                      : null}
                    </div>    
                    <div className='item-details'>               
                      <p className="item-name">{product.name.substring(0,40)} <span className='item-unit'>{product.measurement}</span></p>
                    </div>                             
                    <div className='item-prices'>
                      <p className="product-store">{product.store}</p>
                    </div>                    
                  </div>
                  {/*<div className="quantity-addtocart">
                    <div className="quantity-desc-addtocart">
                      <button className="minus" onClick={decQty}><AiOutlineMinus /></button>
                      <span className="num">{qty}</span>
                      <button className="plus" onClick={incQty}><AiOutlinePlus /></button>
                    </div>
                  </div>*/}
                </div>
                <button className='product-addtocart' onClick={() => onAdd(product, 1)}> Add to cart</button>
                <button className='product-compareprices' onClick={() => onAdd(product, 1)}> Compare Prices</button>
          </div>
        ))}
                  {/*<button onClick={handlePageClickinc}>next</button>
                  <button onClick={handlePageClickdec}>previous</button>*/}
            </div>
      </div>
      <div className='nav btn-container pagination-prev'>
        <button className='pagination-button' onClick={() => setPage(prevState => Math.max(prevState - 1, 0))} disabled={page === 0}> <HiArrowNarrowLeft style={{marginRight: "10px"}}/> Prev Page</button>

        <button assName='next-button' style={{color: "#0AAD0A", backgroundColor: 'transparent', border: "1px solid #0AAD0A", borderRadius: '4px', padding: '8px'}} onClick={() => setPage(prevState => prevState + 1)}>  Next Page <HiArrowNarrowRight style={{marginLeft: "10px"}}/></button>
      </div>    
    </div>
  )
}

export default Items