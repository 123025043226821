export const CarouselData = [

    {
       image: "https://i.ibb.co/26VTJd3/BUBCAT-3-Bubcat-Bread-220208-1644346379881.png",
       title: "Bakery",
       subCategory: [
            {title: 'All', id: 507, name: 'Bakery'},
            {title: 'Bagels, Buns & Rolls', id: 501, name: 'Bakery, Bagels, Buns & Rolls', image: "https://i.ibb.co/8BrQH1d/pexels-anna-tarazevich-6937426.jpg"},
            {title: 'Cakes & Desserts', id: 502, name: 'Bakery, Cakes & Desserts', image: "https://i.ibb.co/hK0N8Fm/pexels-11719204.jpg"},
            {title: 'Bakery, Bread', id: 504, name: 'Bakery, Bread', image: "https://i.ibb.co/dbxtRKT/pexe"},
            {title: 'Breakfast', id: 503, name: 'Bakery, Breakfast', image: "https://i.ibb.co/dbxtRKT/pexels-ash-376464.jpg"},
            {title: 'Wraps, Pitas & Flatbread', id: 504, name: 'Bakery, Wraps, Pitas & Flatbread', image: "https://i.ibb.co/dBvByZ2/pexels-dhiraj-jain-12737660.jpg"},
            {title: 'Pizza Crusts & Crumbs', id: 505, name: 'Bakery, Pizza Crusts & Crumbs', image: "https://i.ibb.co/dBvByZ2/pexels-dhiraj-jain-12737660.jpg"},
            //{title: 'Miscellaneous', id: 506, name: 'Bakery, Miscellaneous', image: "https://i.ibb.co/26VTJd3/BUBCAT-3-Bubcat-Bread-220208-1644346379881.png"},
        ],       
       id: 1,
       category:"Bakery"
           
   }, 

   {
       image: "https://i.ibb.co/8Y6fT8W/10wk1-Grocery-Bubcat-Bubcat-Chips-201005-1601921713063.png",
       title: "Snacks & Candy",
       subCategory: [
            {title: 'All', id: 316, name: 'Snacks & Candy', image: "https://i.ibb.co/VHSjFct/pexels-nataliya-vaitkevich-5792322.jpg"},
            {title: 'Candy', id: 301, name: 'Snacks & Candy, Candy', image: "https://i.ibb.co/qyknjNv/pexels-dan-cristian-p-dure-1193742.jpg"},
            {title: 'Chocolate', id: 302, name: 'Snacks & Candy, Chocolate', image: "https://i.ibb.co/7YbM6c6/pexels-elli-1854664.jpg"},
            {title: 'chips', id: 303, name: 'Snacks & Candy, Chips', image: "https://i.ibb.co/c3syXHz/pexels-iconcom-479628.jpg"},
            {title: 'Popcorn & Pretzels', id: 304, name: 'Snacks & Candy, Popcorn & Pretzels', image: "https://i.ibb.co/3FdMmty/pexels-mikhail-nilov-7676087.jpg"},
            {title: 'Snack Bars', id: 305, name: 'Snacks & Candy, Snack Bars', image: "https://i.ibb.co/3Mnz61J/pexels-polina-tankilevitch-4187499.jpg"},
            {title: 'Gum & Mints', id: 306, name: 'Snacks & Candy, Gum & Mints', image: "https://i.ibb.co/6R8SN3N/pexels-katie-rainbow-8556478.jpg"},
            //{title: 'Dips', id: 307, name: 'Snacks & Candy, Dips', image: "https://i.ibb.co/XD0RLWd/pexels-polina-tankilevitch-6419581.jpg"},
            {title: 'Cookies', id: 308, name: 'Snacks & Candy, Cookies', image: "https://i.ibb.co/3mq5tN5/pexels-pixabay-301972.jpg"},
            {title: 'Jerky', id: 309, name: 'Snacks & Candy, Jerky', image: "https://i.ibb.co/vQypJD0/R-1.jpg"},
            {title: 'Nuts', id: 310, name: 'Snacks & Candy, Nuts'},
            //{title: 'Dips', id: 310, name: 'Snacks & Candy, Dips'},
            {title: 'Snack cups', id: 311, name: 'Snacks & Candy, Snack cups'},
            {title: 'Fruit-Snacks', id: 312, name: 'Snacks & Candy, Fruit-Snacks'},
            {title: 'Crispy', id: 311, name: 'Snacks & Candy, Crispy'},
            //{title: 'Crisps', id: 311, name: 'Snacks & Candy, Crisps'},
            {title: 'Crackers', id: 313, name: 'Snacks & Candy, Crackers'},
            {title: 'Ice-Cream-Cones-and-Toppings', id: 314, name: 'Snacks & Candy, Ice-Cream-Cones-and-Toppings'},
            //{title: 'chips Crisps Crispy Crackers', id: 314, name: 'chips Crisps Crispy Crackers'},            
            {title: 'Miscellaneous', id: 315, name: 'Snacks & Candy, miscellaneous'}, 
            //{title: 'miscellaneous, Chocolate', id: 315, name: 'Snacks & Candy, miscellaneous, Chocolate'},            
        ],
       id: 2,
       category:"Snacks & Candy"            
   },
   {
       image: "https://i.ibb.co/ZVnWJY1/5wk4-Grocery-Cat-Navs-Dairy226138-200504-1588611899978.png",
       title: "Dairy & eggs",
       subCategory: [
        {title: 'All', id: 610, name: 'Dairy & eggs'},
        {title: 'Cheese', id: 101, name: 'Dairy & Eggs, Cheese', url: '/shopby/Dairy & Eggs, Cheese',},
        {title: 'Eggs', id: 102, name: 'Dairy & Eggs, Eggs', url: '/shopby/Dairy & Eggs, Eggs',},
        {title: 'Milk', id: 103, name: 'Dairy & Eggs, Milk', url: '/shopby/Dairy & Eggs, Milk',},
        {title: 'Yogurt', id: 104, name: 'Dairy & Eggs, Yogurt', url: '/shopby/Dairy & Eggs, Yogurt',},
        {title: 'Butter', id: 105, name: 'Dairy & Eggs, Butter', url: '/shopby/Dairy & Eggs, Butter',},
        {title: 'Cream', id: 106, name: 'Dairy & Eggs, Cream', url: '/shopby/Dairy & Eggs, Cream',},
        {title: 'Sour Cream', id: 107, name: 'Dairy & Eggs, Sour Cream & Dip', url: '/shopby/Dairy & Eggs, Sour Cream & Dip',},
        {title: 'Coffee Creamer', id: 108, name: 'Dairy & Eggs, Coffee Creamer', url: '/shopby/Dairy & Eggs, Coffee Creamer',},
        {title: 'Miscellaneous', id: 109, name: 'Dairy & Eggs, miscellaneous', url: '/shopby/Dairy & Eggs, miscellaneous',}, 
       ],
       id: 3,
       category:"Dairy & Eggs"            
   },
   {
       image: "https://i.ibb.co/K51C5w7/CATNAV2-Dips-201113-1605300197875.png",
       title: "Deli ",
       subCategory: [
        {title: 'All', id: 610, name: 'Deli'},
        {title: 'Deli Meat', id: 601, name: 'Deli, Deli Meat'},
        {title: 'Deli Cheese', id: 602, name: 'Deli, Deli Cheese'},
        {title: 'Antipasto, Dips, spreads', id: 603, name: 'Deli, Antipasto, Dips, Spreads'},
        {title: 'Crackers & Condiments', id: 604, name: 'Deli, Crackers & Condiments'},
        {title: 'Vegan & Vegetarian', id: 605, name: 'Deli, Vegan & Vegetarian'},
        {title: 'Lunch & Snack Kits', id: 606, name: 'Deli, Lunch & Snack Kits'},
        {title: 'Party Trays', id: 607, name: 'Deli, Party Trays'},
        //{title: 'Miscellaneous', id: 609, name: 'Deli, miscellaneous'},
    ],
       id: 4,
       category:"Deli"            
   },
   {
       image: "https://i.ibb.co/VqTyvGj/9-Wk5-Beverages-CN-1190710-190823-1566596249823.png",
       title: "Drinks",
       subCategory: [
            {title: 'All', id: 212, name: 'Drinks'},
            {title: 'Sodas & Soft Drinks', id: 201, name: 'Beverages & Drinks, Sodas & Soft Drinks'},
            {title: 'Sports Drinks', id: 202, name: 'Beverages & Drinks, Sports & Energy Drinks'},
            {title: 'Sports Drinks', id: 202, name: 'Beverages & Drinks, Sports Drinks'},
            {title: 'Coffee', id: 203, name: 'Beverages & Drinks, Coffee'},
            {title: 'Water', id: 204, name: 'Beverages & Drinks, Water'},
            //{title: 'Energy Drinks', id: 205, name: 'Drinks, Energy Drinks'},
            {title: 'Juice', id: 206, name: 'Beverages & Drinks, Juice'},
            {title: 'Tea & Hot Drinks', id: 207, name: 'Beverages & Drinks, Tea & Hot Drinks'},
            {title: 'Tea', id: 207, name: 'Beverages & Drinks, Tea', url: '/shopby/Beverages & Drinks, Tea',}, 
            {title: 'Drink Mixes', id: 208, name: 'Beverages & Drinks, Drink Mixes'},
            {title: 'Soy, rice & Nut Drinks', id: 209, name: 'Beverages & Drinks, Soy, Rice & Nut Drinks'},
            {title: 'Non Alcoholic Drinks', id: 210, name: 'Beverages & Drinks, Non Alcoholic Drinks'},
            //{title: 'Miscellaneous', id: 211, name: 'Drinks, Miscellaneous'},
        ],
       id: 5,
       category:"Beverages & Drinks"            
   },
   {
       image: "https://i.ibb.co/nkdJQgn/5wk4-Grocery-Cat-Navs-Frozen-Meats-200511-1589218368584.png",
       title: "Frozen food",
       subCategory: [
        {title: 'All', id: 412, name: 'Frozen'},
        {title: 'Frozen Pizzas & Meals', id: 401, name: 'Frozen, Frozen Pizzas & Meals'},
        {title: 'Frozen Ice Cream & Popsicles', id: 402, name: 'Frozen, Ice Cream & Popsicles'},
        {title: 'Frozen Fruits & Vegetables', id: 403, name: 'Frozen, Frozen Fruits & Vegetables'},
        {title: 'Frozen Breakfast', id: 404, name: 'Frozen, Frozen Breakfast'},
        {title: 'Frozen Meat & Seafood', id: 405, name: 'Frozen, Frozen Meat & Seafood'},
        {title: 'Frozen Desserts', id: 406, name: 'Frozen, Frozen Desserts'},
        {title: 'Frozen Appetizers & Snacks', id: 407, name: 'Frozen, Frozen Appetizers & Snacks'},
        {title: 'Frozen Breads & Doughs', id: 408, name: 'Frozen, Frozen Breads & Doughs'},
        {title: 'Frozen Drinks & Ice', id: 409, name: 'Frozen, Frozen Drinks & Ice'},
        {title: 'Frozen Plant-based Proteins', id: 410, name: 'Frozen, Frozen Plant-based Proteins'},
        //{title: 'Miscellaneous', id: 411, name: 'Frozen, Miscellaneous'},
    ],       
       id: 6,
       category:"Frozen"            
   },
   {
        image: "https://i.ibb.co/bQy6KBv/5wk4-Grocery-Cat-Navs-Produce226138-200504-1588627319062.png",
        title: "Fruits & vegetables",
        subCategory: [
            {title: 'All', id: 1309, name: 'Fruits & Vegetables'},
            {title: 'Fresh Vegetables', id: 1301, name: 'Fruits & Vegetables, Fresh Vegetables'},
            {title: 'Fresh Fruits', id: 1302, name: 'Fruits & Vegetables, Fresh Fruits'},
            {title: 'Package Salad & Dressing', id: 1303, name: 'Fruits & Vegetables, Package Salad & Dressing'},
            {title: 'Herbs', id: 1304, name: 'Fruits & Vegetables, Herbs'},
            {title: 'Fresh cut fruits & vegetables', id: 1305, name: 'Fruits & Vegetables, Fresh cut fruits & vegetables'},
            {title: 'Dried fruits', id: 1306, name: 'Fruits & Vegetables, Dried fruits'},
            {title: 'Fresh juice & smoothies', id: 1307, name: 'Fruits & Vegetables, Fresh juice & smoothies'},
            //{title: 'Miscellaneous', id: 1308, name: 'Fruits & Vegetables, Miscellaneous'},
            ],          
        id: 7,
        category:"Fruits & Vegetables"            
   },
   {
       image: "https://i.ibb.co/Zd2bmwS/9-Wk5-Grocery-Deli-CN-3190739-190917-1568750924949.png",
       title: "Prepared Foods",
       subCategory: [
            {title: 'All', id: 1011, name: 'Prepared Foods'},
            {title: 'Rotisserie & Fried chicken', id: 1001, name: 'Prepared Foods, Rotisserie & Fried chicken'},
            {title: 'Fresh Pasta & Sauce', id: 1002, name: 'Prepared Foods, Fresh Pasta & Sauce'},
            {title: 'Entrees & Appetizers', id: 1003, name: 'Prepared Foods, Entrees & Appetizers'},
            {title: 'Salads & Soups', id: 1004, name: 'Prepared Foods, Salads & Soups'},
            {title: 'Sandwiches', id: 1005, name: 'Prepared Foods, Sandwiches'},
            {title: 'Sushi', id: 1006, name: 'Prepared Foods, Sushi'},
            {title: 'Fried & Sides', id: 1007, name: 'Prepared Foods, Fried & Sides'},
            {title: 'Quiches & Fies', id: 1008, name: 'Prepared Foods, Quiches & Pies'},
            {title: 'Snacks & Dips', id: 1009, name: 'Prepared Foods, Snacks & Dips'},
            //{title: 'Miscellaneous', id: 1010, name: 'Prepared Foods, Miscellaneous'},
        ],
       id: 8,
       category:"Prepared Foods"            
   },
   {
       image: "https://i.ibb.co/qWBBLMj/5wk4-Grocery-Cat-Navs-Pork-200507-1588873718508.png",
       title: "Meat",
       subCategory: [
            {title: 'All', id: 1109, name: 'Meat'},
            {title: 'Chiken & Turkey', id: 1101, name: 'Meat, Chicken & Turkey'},
            {title: 'Beef', id: 1102, name: 'Meat, Beef'},
            {title: 'Bacon & Sausage & hot dogs', id: 1103, name: 'Meat, Bacon & Sausage & hot dogs'},
            {title: 'Pork', id: 1104, name: 'Meat, Pork'},
            {title: 'Lamb & Veal', id: 1105, name: 'Meat, Lamb & Veal'},
            {title: 'Kebabs & Marinated Meat', id: 1106, name: 'Meat, Kebabs & Marinated Meat'},
            {title: 'offals, Game Meat & fowl', id: 1107, name: 'Meat, offals, Game Meat & fowl'},
            {title: 'Miscellaneous', id: 1108, name: 'Meat, miscellaneous'},
        ],       
       id: 9,
       category:"Meat"            
   },
   {
    title: 'Fish & Sea food',
    image: 'https://i.ibb.co/g4jsdMQ/fish.png',        
    subCategory: [
      {title: 'All', id: 814, name: 'Fish & Sea food'},
      {title: 'Shrimps', id: 601, name: 'Fish & Sea food, Shrimps', },
      {title: 'Salmon', id: 602, name: 'Fish & Sea food, Salmon', },
      {title: 'Fish', id: 603, name: 'Fish & Sea food, Fish', },
      {title: 'Smoked Fish', id: 604, name: 'Fish & Sea food, Smoked Fish',},
      {title: 'Seafood Appetizers', id: 605, name: 'Fish & Sea food, Seafood Appetizers', },
      {title: 'Shellfish', id: 606, name: 'Fish & Sea food, Shellfish',},
      {title: 'Squid & Octopus', id: 606, name: 'Fish & Sea food, Squid & Octopus', },          
      {title: 'Miscellaneous', id: 609, name: 'Fish & Sea food, Miscellaneous',},  
    ],
    id: 10,
    category:"Fish & Sea food"
    }, 
    {
        image: "https://i.ibb.co/M9ch2XP/Natural-food.png",
        title: "Natural Foods",
        subCategory: [
            {title: 'All', id: 814, name: 'Natural Foods'},
            {title: 'Condiments & sauces', id: 801, name: 'Natural Foods, Condiments & sauces', url: '/shopby/Natural Foods, Condiments & sauces',},
            {title: 'Chocolate, Gum & Candy', id: 802, name: 'Natural Foods, Chocolate, Gum & Candy', url: '/shopby/Natural Foods, Chocolate, Gum & Candy',},
            {title: 'Cereal & Granola', id: 803, name: 'Natural Foods, Cereal & Granola', url: '/shopby/Natural Foods, Cereal & Granola',},
            {title: 'Spread & Syrups', id: 804, name: 'Natural Foods, Spread & Syrups', url: '/shopby/Natural Foods, Spread & Syrups',},
            {title: 'Beverages', id: 805, name: 'Natural Foods, Beverages', url: '/shopby/Natural Foods, Beverages',},
            {title: 'Baking', id: 806, name: 'Natural Foods, Baking', url: '/shopby/Natural Foods, Baking',},
            {title: 'Canned', id: 807, name: 'Natural Foods, Canned', url: '/shopby/Natural Foods, Canned',},
            {title: 'Oil & Vinegar', id: 808, name: 'Natural Foods, Oil & Vinegar', url: '/shopby/Natural Foods, Oil & Vinegar',},
            {title: 'Easy prep & sides', id: 809, name: 'Natural Foods, Easy prep & sides', url: '/shopby/Natural Foods, Easy prep & sides',},
            {title: 'Snacks', id: 810, name: 'Natural Foods, Snacks', url: '/shopby/Natural Foods, Snacks',},
            //{title: 'Miscellaneous', id: 813, name: 'Natural Foods, Miscellaneous', url: '/shopby/Natural Foods, Miscellaneous',},
         ],     
        id: 11,
        category:"Natural Foods"            
    },     
    {
        image: "https://i.ibb.co/tXBrxtC/International-food.png",
        title: "International foods",
        url: '/shopby/International foods',   
        subCategory: [
            {title: 'All', id: 814, name: 'International foods'},
            {title: 'Asian', id: 601, name: 'International Foods, Asian', url: '/shopby/International Foods, Asian',},
            {title: 'European', id: 602, name: 'International Foods, European', url: '/shopby/International Foods, European',},
            {title: 'Indian & Middle Eastern', id: 603, name: 'International Foods, Indian & Middle Eastern', url: '/shopby/International Foods, Indian & Middle Eastern',},
            {title: 'Latin & Mexican', id: 604, name: 'International Foods, Latin & Mexican', url: '/shopby/International Foods, Latin & Mexican',},
            //{title: 'Miscellaneous', id: 609, name: 'International foods, miscellaneous', url: '/shopby/International foods, miscellaneous',},  
          ],      
        id: 12,
        category:"International foods"            
    },
   {
       image: "https://i.ibb.co/wzNcbMk/10wk1-Grocery-Bubcat-Canned-Packaged-Foods-200901-1598994624706.png",
       title: "Pantry food",
       subCategory: [
            {title: 'All', id: 814, name: 'Pantry food'},
            {title: 'Canned & Pickeled', id: 801, name: 'Pantry, Canned & Pickeled'},
            {title: 'Pasta & Pasta Sauce', id: 802, name: 'Pantry, Pasta & Pasta Sauce'},
            {title: 'Baking Essentials', id: 803, name: 'Pantry, Baking Essentials'},
            {title: 'Easy Meals & sides', id: 804, name: 'Pantry, Easy Meals & sides'},
            {title: 'Cereal & Breakfast', id: 805, name: 'Pantry, Cereal & Breakfast'},
            {title: 'Honey, Syrup & spread', id: 806, name: 'Pantry, Honey, Syrup & spread'},
            {title: 'Rice', id: 807, name: 'Pantry, Rice'},
            {title: 'Oils & Vinegar', id: 808, name: 'Pantry, Oils & Vinegar'},
            {title: 'Condiments', id: 809, name: 'Pantry, Condiments'},
            {title: 'Spices & Seasonings', id: 810, name: 'Pantry, Spices & Seasonings'},
            {title: 'Bulk', id: 811, name: 'Pantry, Bulk'},    
            {title: 'Snack Cups', id: 811, name: 'Pantry, Snack Cups'},        
            {title: 'Dried Beans, Vegetables & Grains', id: 812, name: 'Pantry, Dried Beans, Vegetables & Grains'},
            {title: 'Condiments & Sauces', id: 812, name: 'Pantry, Condiments & Sauces'},
            {title: 'Miscellaneous', id: 813, name: 'Pantry, miscellaneous'},
        ],     
       id: 13,
       category:"Pantry"            
    },
    {
        image: "https://i.ibb.co/4mnXHmy/Household.png",
        title: 'Household',
        subCategory: [
          {title: 'All', id: 814, name: 'Household'},
          {title: 'Snow Removal', id: 101, name: 'Household, Snow Removal', url: '/shopby/Household, Snow Removal',},
          {title: 'Bathroom Tissue & Paper Products', id: 102, name: 'Household, BATHROOM TISSUE & PAPER PRODUCTS', url: '/shopby/Household, BATHROOM TISSUE & PAPER PRODUCTS',},
          {title: 'FOOD WRAP & BAGS', id: 103, name: 'Household, FOOD WRAP & BAGS', url: '/shopby/Household, FOOD WRAP & BAGS',},
          {title: 'FLOOR CARE', id: 104, name: 'Household, FLOOR CARE', url: '/shopby/Household, FLOOR CARE',},
          {title: 'HOUSEHOLD CLEANERS', id: 105, name: 'Household, HOUSEHOLD CLEANERS', url: '/shopby/Household, HOUSEHOLD CLEANERS',},
          {title: 'AIR FRESHENERS', id: 106, name: 'Household, AIR FRESHENERS', url: '/shopby/Household, AIR FRESHENERS',},
          {title: 'DISHWASHING', id: 107, name: 'Household, DISHWASHING', url: '/shopby/Household, DISHWASHING',},
          {title: 'LAUNDRY NEEDS', id: 108, name: 'Household, LAUNDRY NEEDS', url: '/shopby/Household, LAUNDRY NEEDS',},
          {title: 'TRASH BAGS', id: 109, name: 'Household, TRASH BAGS', url: '/shopby/Household, TRASH BAGS',}, 
          {title: 'Floral and Garden', id: 107, name: 'Floral and Garden', url: '/shopby/Floral and Garden',},
          {title: 'CLEANING ACCESSORIES & SPONGES', id: 107, name: 'Household, CLEANING ACCESSORIES & SPONGES', url: '/shopby/Household, CLEANING ACCESSORIES & SPONGES',},
          {title: 'LIGHT BULBS', id: 108, name: 'Household, LIGHT BULBS', url: '/shopby/Household, LIGHT BULBS',},
          {title: 'BATTERIES', id: 109, name: 'Household, BATTERIES', url: '/shopby/Household, BATTERIES',},  
          {title: 'miscellaneous', id: 109, name: 'Household, miscellaneous', url: '/shopby/Household, miscellaneous',},     
        ],
        id: 14,
        category:"Household"         
    },
    {
        title: 'Health & Beauty',
        image: "https://i.ibb.co/stRtJQZ/Beauty.png",
        subCategory: [
            {title: 'All', id: 507, name: 'Health & Beauty'},
            {title: 'Body Care', id: 501, name: 'Health & Beauty, Body Care', url: '/shopby/Health & Beauty, Body Care',},
            {title: 'Cosmetics', id: 502, name: 'Health & Beauty, Cosmetics', url: '/shopby/Health & Beauty, Cosmetics',},
            {title: 'Feminine Care', id: 503, name: 'Health & Beauty, Feminine Care', url: '/shopby/Health & Beauty, Feminine Care',},
            {title: 'NUTRITION & WELLNESS', id: 504, name: 'Health & Beauty, NUTRITION & WELLNESS', url: '/shopby/Health & Beauty, NUTRITION & WELLNESS',},
            {title: 'Deodorant', id: 505, name: 'Health & Beauty, Deodorant', url: '/shopby/Health & Beauty, Deodorant',},
            {title: 'Eye & Ear care', id: 506, name: 'Health & Beauty, Eye & Ear care', url: '/shopby/Health & Beauty, Eye & Ear care',},
            {title: 'Foot Care', id: 502, name: 'Health & Beauty, Foot Care', url: '/shopby/Health & Beauty, Foot Care',},
            {title: 'Hair Care', id: 503, name: 'Health & Beauty, Hair Care', url: '/shopby/Health & Beauty, Hair Care',},
            {title: 'Health Care', id: 503, name: 'Health & Beauty, Health Care', url: '/shopby/Health & Beauty, Health Care',},
            {title: 'Oral Hygiene', id: 504, name: 'Health & Beauty, Oral Hygiene', url: '/shopby/Health & Beauty, Oral Hygiene',},
            {title: 'Grooming & Shaving', id: 505, name: 'Health & Beauty, Grooming & Shaving', url: '/shopby/Health & Beauty, Grooming & Shaving',},
            {title: 'Adult Care', id: 506, name: 'Health & Beauty, Adult Care', url: '/shopby/Health & Beauty, Adult Care',},         
            {title: 'Skin Care', id: 504, name: 'Health & Beauty, Skin Care', url: '/shopby/Health & Beauty, Skin Care',},
            {title: 'Sun Protection & Bug Spray', id: 505, name: 'Health & Beauty, Sun Protection & Bug Spray', url: '/shopby/Health & Beauty, Sun Protection & Bug Spray',},
            //{title: 'August Deals', id: 505, name: 'Health & Beauty, August Deals', url: '/shopby/Health & Beauty, August Deals',},
            {title: 'FRAGRANCES & SCENT', id: 505, name: 'Health & Beauty, FRAGRANCES & SCENT', url: '/shopby/Health & Beauty, FRAGRANCES & SCENT',},
            {title: 'Miscellaneous', id: 506, name: 'Health & Beauty, miscellaneous', url: '/shopby/Health & Beauty, miscellaneous',},
        ],
        id: 15,
        category:"Health & Beauty"        
    },
    {
        title: 'Baby',
        image: 'https://i.ibb.co/CPrccBm/baby.png',        
        subCategory: [
          {title: 'All', id: 507, name: 'Baby'},
          {title: 'Bath', id: 301, name: 'Baby, Bath', url: '/shopby/Baby, Bath',},
          {title: 'Diapers & Wipes', id: 302, name: 'Baby, Diapers & Wipes', url: '/shopby/Baby, Diapers & Wipes',},
          {title: 'Bottles & Formula', id: 303, name: 'Baby, Bottles & Formula', url: '/shopby/Baby, Bottles & Formula',},
          {title: 'Baby Healthcare', id: 305, name: 'Baby, Baby Healthcare', url: '/shopby/Baby, Baby Healthcare',},
          {title: 'baby food & drinks', id: 306, name: 'Baby, baby food & drinks', url: '/shopby/Baby, baby food & drinks',},
          //{title: 'Miscellaneous', id: 315, name: 'Baby, miscellaneous', url: '/shopby/Baby, miscellaneous',},  
          
        ],
        id: 16,
        category:"Baby"             
      },  
      {
        title: 'Pets Supplies',
        image: 'https://i.ibb.co/TmNtPz9/cat.png',        
        subCategory: [
          {title: 'All', id: 507, name: 'Pets Supplies'},
          {title: 'AMPHIBIANS & REPTILES', id: 601, name: 'Pets Supplies, AMPHIBIANS & REPTILES', url: '/shopby/Pets Supplies, AMPHIBIANS & REPTILES',},
          {title: 'Birds', id: 602, name: 'Pets Supplies, Birds', url: '/shopby/Pets Supplies, Birds',},
          {title: 'Cats', id: 603, name: 'Pets Supplies, Cats', url: '/shopby/Pets Supplies, Cats',},
          {title: 'Dogs', id: 604, name: 'Pets Supplies, Dogs', url: '/shopby/Pets Supplies, Dogs',},
          {title: 'FERRET', id: 605, name: 'Pets Supplies, FERRET', url: '/shopby/Pets Supplies, FERRET',},
          {title: 'FISH & AQUATIC ANIMALS', id: 606, name: 'Pets Supplies, FISH & AQUATIC ANIMALS', url: '/shopby/Pets Supplies, FISH & AQUATIC ANIMALS',},
          {title: 'SMALL ANIMALS', id: 606, name: 'Pets Supplies, SMALL ANIMALS', url: '/shopby/Pets Supplies, SMALL ANIMALS',},
          //{title: 'Miscellaneous', id: 609, name: 'Pets Supplies, Miscellaneous', url: '/shopby/Pets Supplies, Miscellaneous',},  
        ],
        id: 17,
        category:"Pets Supplies"
      },  
 
]












