import React from 'react'
import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";
import { SliderData } from './Data/SliderData';
import { useStateContext } from '../../context/StateContext';

function Slider() {
    const {firstIndexSlider, lastIndexSlider, setLastIndexSlider, setFirstIndexSlider} = useStateContext();

  return (
    <div className='Slider-container'>
        {firstIndexSlider > 0 && (
            <div className='Slider-arrow-left' onClick={() => { setFirstIndexSlider(firstIndexSlider - 1); setLastIndexSlider(lastIndexSlider - 1);}}>
                <HiArrowNarrowLeft/>
            </div>
        )}        
        <div className='Slider-wrapper'>
            {SliderData.map((SliderData, i) => {
                    if (i >= firstIndexSlider && i < lastIndexSlider) {
                        return (
                            <div className='Slider-slide' key={SliderData.id}>
                                <div className='Slider-slide-img'>
                                    <img className="Slide-image" src={SliderData.image} key={SliderData.id} alt=''/>
                                </div>
                                <div className='Slider-slide-info'>
                                    <h1 className='Slider-slide-title'> {SliderData.title}</h1>
                                    <p className='Slider-slide-description'> {SliderData.Description}</p>

                                </div>                
                            </div>
                        );
                    } else {
                    return null;
                }
            })}   
        </div>
        {lastIndexSlider < SliderData.length && (        
        <div className='Slider-arrow-right' onClick={() => { setFirstIndexSlider(firstIndexSlider + 1); setLastIndexSlider(lastIndexSlider + 1);}}>
            <HiArrowNarrowRight/>
        </div> 
            )}                
    </div>
  )
}

export default Slider


