import {React, useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom'
import {useParams} from 'react-router';
import { useStateContext } from '../../context/StateContext';
import ReactPaginate from "react-paginate";
import { AiOutlineMinus } from "react-icons/ai";
import { RiHeartLine, RiAddLine} from "react-icons/ri";

function Items() {
  const {onAdd} = useStateContext();
  const [items, setItems] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const {name} = useParams();
  const [qtyState, setQtyState] = useState({});
  // eslint-disable-next-line
  const [currentQty, setCurrentQty] = useState(1);

  let limit = 20;


  useEffect(() => {
    const getComments = async () => {
      const res = await fetch(
        `https://api.click-n.com/find_name?find=${name}&pages=0&items=${limit}`
      );
      const data = await res.json();
      const total = data.count;
      setpageCount(Math.ceil((total / limit)-1))
      setItems(data.list);
    };

    getComments();
     // eslint-disable-next-line
  }, [limit]);

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `https://api.click-n.com/find_name?find=${name}&pages=${currentPage}&items=${limit}`
    );
    const data = await res.json();
    return data.list;
  };

  const handlePageClick = async (data) => {
    console.log(data.selected);

    let currentPage = data.selected + 1;

    const commentsFormServer = await fetchComments(currentPage);

    setItems(commentsFormServer);

  };

  useEffect(() => {
    const initialQtyState = {};
    items.forEach((product) => {
      initialQtyState[product.id] = 1;
    });
    setQtyState(initialQtyState);
  }, [items]);

  const incQty = (id) => {
    setQtyState((prevState) => ({
      ...prevState,
      [id]: prevState[id] + 1
    }));
    setCurrentQty(prevState => prevState+1);
  };
  
  const decQty = (id) => {
    setQtyState((prevState) => ({
      ...prevState,
      [id]: prevState[id] > 1 ? prevState[id] - 1 : 1
    }));
    setCurrentQty(prevState => prevState > 1 ? prevState-1 : 1);
  };
  
  return (
    <div >
      <div className='ShopBy_Header' style={{ marginTop: '170px',}}>
        <h1 className='ShopBy_Title'>{name}</h1>
      </div>
    <div className='item-container'>
          <div className='item-container'>    
            {items.map((product, index) => (
              <div className="item-card" key={index}>

                <div className='item-card-div'>                              
                  <div className="wishlist-button" title="wishlist-button">
                    <div className="sales-div">
                      { product.sale?.old_price !== undefined && product.sale?.old_price > product.price?
                        <span className='sales-span-banner'> Save Up to { Math.round((((1 - (product.price / product.sale.old_price)) *100) *100) / 100)} %</span>
                        : null}
                    </div>                 
                    <RiHeartLine className='sales-span-icon'/>
                  </div>  
                  <div className='item-car-header'>                  
                    <NavLink to={`/products/${product.id}/${product.name}`}> <img className="item-image"
                        src={product.image} alt='items' onClick={() => window.location = `/products/${product.id}/${product.name}`}/>
                      </NavLink>
                    </div>

                    <span>{product.category}</span> 
                    <div className='details-div'>
                      <div className='item-prices'>
                        <p className="product-price">${product.price}</p>
                        { product.sale?.old_price !== undefined && product.sale?.old_price > product.price?
                          <p className='product-avg-price'>${product.sale.old_price}</p>
                        : null}
                      </div>    
      
                      <div className='item-details'>               
                        <p className="item-name">{product.name.substring(0,40)} <span className='item-unit'>{product.measurement}</span></p>
                      </div>                              
                      <div className='item-prices'>
                        <p className="product-store">{product.store}</p>
                      </div>                    
                  </div>
                </div>
                <div className="quantity-addtocart">
                              <div className="quantity-desc-addtocart">
                                <button className="minus" onClick={() => decQty(product.id)}><AiOutlineMinus /></button>
                                <span className="num">{qtyState[product.id]}</span>
                                <button className="plus" onClick={() => incQty(product.id)}><RiAddLine /></button>
                              </div>
                            </div>                
                <button className='product-addtocart' onClick={() => onAdd(product, qtyState[product.id])}> Add to cart</button>
                <NavLink to={`/compare/${product.id}/${product.category}/${product.store}`} >
                  <button className='product-compareprices'> Compare Prices</button>
                </NavLink>
              </div>
              ))}
            </div>
      </div>
      { pageCount !== 0 ?
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />    
        : null} 
        
    </div>    
  )
}

export default Items