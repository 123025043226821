import React from 'react'
import {NavLink} from 'react-router-dom'
//import {useParams} from 'react-router';
//import {ProductData} from '../Sliders/Data/ProductData';
import { useStateContext } from '../../context/StateContext';


function MarqueeProducts() {

    const {filters} = useStateContext(); 
    //const {category} = useParams();

    return (
      <div >
        <div className='marquee-container'>
          {filters.map(product => {
            return (
            <div>                
                <div className="marquee-card"  key={product.id}>
                  <div className='marquee-car-header'>
                    <NavLink to={`/products/${product.id}`}> <img className="marquee-image"
                        src={product.image} alt='items'/>
                    </NavLink>
                  </div>
                  <span>{product.category}</span> 
                  <div className='marquee-details'>               
                    <p className="marquee-name">{product.name.substring(0,20)}</p>
                    <p className="marquee-unit"> {product.measurement}</p>            
                  </div>                              
                  <div className='marquee-prices'>
                    <p className="marquee-price">${product.price}</p>

                    { product.sale?.old_price !== undefined ?
                      <p className='marquee-avg-price' style={{ fontSize: "13px", textDecoration: 'line-through'}}>${product.sale.old_price}</p> 
                    : null}  
                  </div>
                </div>
            </div>
            )
          })}
          </div>
      </div>
    )
  }

export default MarqueeProducts