import React from 'react'
import { CarouselData } from './Data/CarouselData'
import {IoIosArrowForward, IoIosArrowBack} from "react-icons/io";
import { useStateContext } from '../../context/StateContext';


function Carousel() {
    const { CategoryfilterProduct,  firstIndex, lastIndex, setLastIndex, setFirstIndex} = useStateContext();

    
  
    return (
        <div className="Carousel-container">

            {firstIndex > 0 && (
                    <button className='left-button' style={{ marginTop: '40px', width:'20%'}} onClick={() => { setFirstIndex(firstIndex - 1); setLastIndex(lastIndex - 1);}}><IoIosArrowBack/></button>
            )}

            <div className="Carousel-content">
                {CarouselData.map((CarouselData, i) => {
                    if (i >= firstIndex && i < lastIndex) {
                        return (
                            <div className="image-wrapper" key={CarouselData.id}>
                                <img className="Carousel-img" onClick={()=> CategoryfilterProduct(CarouselData.category)} src={CarouselData.image}  key={CarouselData.id} alt={CarouselData.title}/>
                                <p className="Carousel-text"> {CarouselData.title}</p> 
                            </div>
                        );
                        } else {
                        return null;
                    }
                })}            
            </div>

            {lastIndex < CarouselData.length && (               
                <button className='right-button' style={{ marginTop: '40px', width:'20%'}} onClick={() => { setFirstIndex(firstIndex + 1); setLastIndex(lastIndex + 1);}}><IoIosArrowForward/></button>   
            )}  

        </div>
    );
  }
  


export default Carousel