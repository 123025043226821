import {React, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {NavLink} from 'react-router-dom';
import {useStateContext} from './../context/StateContext';
import {StoresData} from './../Components/Filters/StoresData';
import {CarouselData} from './../Components/Sliders/Data/CarouselData'
import {RiArrowDropRightLine} from "react-icons/ri";
import {ShopBySkeleton} from "../Components/index";


function ShopByStore() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { onAdd } = useStateContext();
  const { store } = useParams();

  // Function to fetch data from API
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const cachedData = sessionStorage.getItem(`store_${store}_data`);
      if (cachedData) {
        setData(JSON.parse(cachedData));
      } else {
        const response = await fetch(
          `https://api.click-n.com/find_product_by_store?find=${store}&items=1000`
        ).then((res) => res.json());
        setData(response);
        sessionStorage.setItem(`store_${store}_data`, JSON.stringify(response));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [store]);


const settings = {
  dots: true,
  autoplay: true,
  
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 5,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

  /*const Loading = () => {
    return(
      <>
        <div className='ShopBy-Container'>
        </div>
      </>
    )
  }*/

  return (
    <>
      <div className='BreadCrumbs'style={{ marginTop: '170px',}}>
      <NavLink to={`/`}> <h2 className='Path'> Home </h2> </NavLink> <RiArrowDropRightLine/>
      <h2 className='Page'> {store} </h2>          
      </div>
      {isLoading ? ( <ShopBySkeleton/>) : (
        <div className='ShopBy-Container'>
          {StoresData.filter((img) => img.store ===  store ).map((img) => (
            <>
              <div className='ShopBy_Header'>
                <div className='ShopBy-imagefit'>
                  <img className="ProductCard-image-logo" src={img.shopbyimg} style={{ width: '80%'}} alt='items'/>
                </div>
              </div>
              {CarouselData.map((CarouselData) => (
                <>
                  { data?.list.filter((product) => product.category.includes(CarouselData.category) && product.store ===  img.store )?.length !== 0 ?
                    <>
                      <div className='ShopBy-Content-Header'>
                        <div className='ShopBy-imagefit' style={{width: '300px'}}>
                          <h1 className='ShopBy_Title' style={{fontSize: '28px'}}>{CarouselData.title}</h1>
                        </div>
                        <NavLink to={`/category/${CarouselData.title}/${img.store}`} style={{marginLeft: '5px', color: '#828282'}}>
                        <p className='ShopBy-Seeall'style={{marginTop: '-80px'}}> See All {img.store} & {CarouselData.title} Products </p>
                        </NavLink> 
                      </div>      
                      <div className='ShopBy-Content'>
                        <div className='ShopBy-Content-Content'>
                        
                          <Slider {...settings}>
                            {data?.list.filter((product) => product.category.includes(CarouselData.category) && product.store ===  img.store).slice(0,20).map((product) => (
                              <div className="ProductCard-item-list"  key={product.category} style={{ marginBottom: '20px', width: '60px'}}>                              
                                <div className='item-car-header'>                  
                                  <NavLink to={`/products/${product.id}/${encodeURIComponent(`${product.name}`)}`} >
                                    <img className="ProductCard-image" src={product.image}  alt='items'onClick={() => window.location = `/products/${product.id}/${encodeURIComponent(`${product.name}`)}`}/>
                                  </NavLink>
                                </div>                   
                                <div className='ProductCard-details'style={{height: '42px'}}>               
                                  <p className="ProductCard-name" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}>{product.name.substring(0, 40)}  {product.pricePerUnit && (<>{product.pricePerUnit}</>)}</p>                    
                                </div>                              
                                <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                                  <p className="ProductCard-price" style={{fontSize: "13px", marginTop: "20px"}}>${product.price}</p>
                                    { product.sale?.old_price !== undefined ?
                                      <p className='ProductCard-avg-price' style={{ fontSize: "13px", textDecoration: 'line-through', marginTop: "20px"}}>${product.sale.old_price}</p> 
                                    : null}  
                                </div> 
                                <button className='product-addtocart-slider' onClick={() => onAdd(product, 1)}> Add to cart</button>  
                                <NavLink to={`/compare/${product.id}/${product.category}/${product.store}`} >
                                <button className='product-addtocart-slider-compare'> Compare Prices</button>
                              </NavLink>
                              </div>                              
                            ))}
                          </Slider>
                        
                        </div>
                      </div>
                    </>
                  : null }
                </>
                
              ))}
                        
            </>
          ))}
        </div>
      )}
      
        {StoresData.filter((img) => img.store ===  store ).map((img) => (
          <NavLink to={`/category/Bakery/${img.store}`}>
            <h6 class='ShopBy-Seeall' style={{marginTop: '60px', marginLeft: 'auto', marginRight:'auto', textAlign: 'center'}}>
              Shop more {img.title} categories 
            </h6>
          </NavLink>
        ))}
      
    </>
  )
}

export default ShopByStore