import React from 'react'
import { AiOutlineUser, AiOutlineShopping, AiOutlineHeart } from "react-icons/ai";
import {NavLink} from 'react-router-dom';


function ProfileSidebar() {
    return (
        <div className='Profile-sidebar'>
            <div className='Profile-sidebar-inner'>
                <div>
                    <NavLink to={`/profilesidebar/`} className='sidebar-profile'>
                        <AiOutlineUser className='Profile-sidebar-icon'/>
                        <h3 className='Profile-sidebar-title'> Account Settings</h3>
                    </NavLink>
                </div>
                <div>
                    <NavLink to={`/orders`} className='sidebar-profile' disable>
                        <AiOutlineShopping className='Profile-sidebar-icon'/>
                        <h3 className='Profile-sidebar-title'> Orders</h3>
                    </NavLink>
                </div>
                <div>
                    <NavLink to={`/wishlist`} className='sidebar-profile' disable>
                        <AiOutlineHeart className='Profile-sidebar-icon'/>
                        <h3 className='Profile-sidebar-title'> Wishlist</h3>
                    </NavLink>
                </div>
            </div>                
        </div>
    )
}

export default ProfileSidebar