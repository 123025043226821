
import {React,useState,useEffect} from 'react'
import { RiMapPin2Line} from "react-icons/ri";

function AddressFetcher() {
    const [ip, setIP] = useState('');

      //creating function to load ip address from the API
    const getData = async () => {
        const res = await fetch('https://ipapi.co/json/')
        //https://geolocation-db.com/json/
        //http://ip-api.com/json/
        //https://ipapi.co/json/
        
        const data = await res.json();
        setIP(data)

    }
    
    useEffect( () => {
        getData()
    }, [])

  return (
    <div className='address-fetcher'>
        <RiMapPin2Line className='address-icon'/>
        <h4 className='zip-code'>
            {ip.region}, {ip.postal}
        </h4>
    </div>
  )
}

export default AddressFetcher