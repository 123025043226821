import React from 'react'

function CategoryPageSkeleton() {

  const j = 4;
  const n = 10;

  return (
    <>
      {[...Array(n)].map((e, i) =>  
        <div key={i} style={{
          display: 'flex', 
          gap: '0px', 
          justifyContent:'center', 
          flexWrap:'wrap', 
          marginLeft:'auto', 
          marginRight:'auto',
          marginTop: i > 0 ? '-180px' : '0px', 
        }}>
          {[...Array(j)].map((e, i) =>
            <div className='item-container' style={{
              marginTop: '50px', 
              width: '300px'}} 
              key={i}
            >
              <div className='item-container'>   
                <div className="item-card">
                  <div className='item-card-div'>                               
                    <div className='item-car-header'>                  
                      <div className="item-image-skeleton" style={{
                        paddingTop: '40px',
                        marginTop: '20px',
                        height: '160px',
                        width: '90%', 
                        marginLeft: 'auto', 
                        marginRight: 'auto',
                        backgroundColor: '#cccccc',
                        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
                      }}/>
                    </div>
                    <div className='details-div'>
                      <div className='item-prices'>
                        <div className="product-price skeleton" style={{
                          width: '30%',
                          height: '20px',
                          backgroundColor: '#cccccc',
                          transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'                        
                        }}>
                        </div> 
                      </div>    
                        <div className='item-details'>               
                          <div className="item-name skeleton" style={{
                            backgroundColor: '#cccccc',
                            width: '60%',
                            height: '20px',    
                            marginTop: '20px'                      
                          }}>
                            <div className='item-unit'></div>
                          </div>
                          <div className="item-name skeleton" style={{
                            backgroundColor: '#cccccc',
                            width: '60%',
                            height: '20px',    
                            marginTop: '20px'                      
                          }}>
                            <div className='item-unit'></div>
                          </div>                          
                        </div>                              
                        <div className='item-prices' >
                          <p className="product-store skeleton" style={{
                            backgroundColor: '#cccccc',
                            width: '30%',
                            height: '20px',    
                            marginTop: '20px'                      
                          }}></p>
                        </div>                    
                      </div>
                  </div>
                  <div className='product-addtocart-slider-skeleton ' style={{width: '100%', height: '40px', marginBottom: '10px'}}></div>
                  <div className='product-addtocart-slider-skeleton ' style={{width: '100%', height: '40px', marginBottom: '10px'}}></div>
                  <div className='product-addtocart-slider-skeleton ' style={{width: '100%', height: '40px', marginBottom: '10px'}}></div>
                </div>
              </div>   
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default CategoryPageSkeleton