import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import React, { useState } from 'react'
import { useStateContext } from '../../context/StateContext';


const CARD_OPTIONS = {
	iconStyle: "solid",
	style: {
		base: {
			iconColor: "#828282",
			color: "#000",
			fontWeight: 500,
			fontFamily: "Inter, sans-serif",
			fontSize: "16px",
			fontSmoothing: "antialiased",
			":-webkit-autofill": { color: "#fce883" },
			"::placeholder": { color: "#828282" },
            border: '1px, solid, #000',
		},
		invalid: {
			iconColor: "#DE3634",
			color: "#DE3634"
		}
	}
}

export default function PaymentForm() {
    const [success, setSuccess ] = useState(false)
    const stripe = useStripe()
    const elements = useElements()
    const {  totalPrice} = useStateContext();

    

    const handleSubmit = async (e) => {
        e.preventDefault()
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),

        })


    if(!error) {
        try {
            const {id} = paymentMethod
            const carts = { id, amount: totalPrice}
            
            const response = await fetch('https://api.click-n.com/create-payment-intent', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify(carts),
            });
    

            if(response.data.success) {
                console.log("Successful payment")
                setSuccess(true)
            }

        } catch (error) {
            console.log("Error", error)
        }
    } else {
        console.log(error.message)
    }
}

    return (
        <>
        {!success ? 
        <form onSubmit={handleSubmit}>
            <fieldset className="FormGroup">
                <div className="FormRow">
                    <CardElement options={CARD_OPTIONS}/>
                </div>
            </fieldset>
            <button className='ButtonForm'>Pay</button>
        </form>
        :
       <div>
           <h2>Thank you for using ClickN, please proceed with the checkout</h2>
       </div> 
        }
            
        </>
    )
}