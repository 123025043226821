import React from "react";
import {Product} from "../Components/index";



export default function CartPage() {
  return (
    <>
    <Product />   
  </>
  );
}