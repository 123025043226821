import React from 'react'

function ProductSkeleton() {
  return (
    <div className='product-bigger-wrapper'>
      <div className="product-list-wrapper"> 
        <div className="product-detail-container-skeleton">              
          <div>                    
            <div className="image-container">
              <img src='' className="product-detail-image-skeleton" alt=""/>
            </div>            
          </div>
          <div className="product-detail-desc">
            <div className="product-detail-header-title">

              <div className='product-name'></div>
            </div>
            <p className='product-unit-title'> </p>
            <div className="product-unit-price-container">
            
              <p className='product-unit-price'> $</p>

              
                <p className='product-unit-wasprice'> $</p>
              


                <p className=' product-unit-price product-savings'> </p>

            </div>              
            <h4 className='product-description-p'>Product Details: </h4>
            <div className='description'>
              <p className='product-description'></p>            
            </div>
            <div className='product-detail'>

              <div className='product-Brand'>
                <p className='product-p'> Store:</p>
                <p className='product-description-brand'></p>
              </div>

            </div>


            <div className="quantity">
              <div className="quantity-desc">

              </div>
            </div>
            <div className="buttons">
              <button type="button" className="buy-now">Add to Cart</button>  
              <button type="button" className="add-to-cart">Compare Product</button> 
                        
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductSkeleton