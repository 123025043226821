import React, { useState, useEffect } from "react";
import TinyCarousel from "./TinyCarousel";
//import { ProductData } from './Data/ProductData';

function ProductCard() {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [group1, setGroup1] = useState([]);
  const [group2, setGroup2] = useState([]);
  /*const [mondayData, setMondayData] = useState();
  const [tuesdayData, setTuesdayData] = useState();
  const [wednesdayData, setWednesdayData] = useState();
  const [thursdayData, setThursdayData] = useState();
  const [sundayData, setSundayData] = useState();*/
  
   // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
   // eslint-disable-next-line
  const [currentDay, setCurrentDay] = useState("");

  // Function to collect data
  const getApiData = async () => {
    const response = await fetch(
      "https://api.click-n.com/find_name?find=&items=1000"
    ).then((response) => response.json());

    // update the state
    setData(response);
    setIsLoading(false);
  };

  useEffect(() => {
    getApiData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const [data1, data2] = await Promise.all([
        fetch(
          "https://api.click-n.com/find_name?find=keto&pages=0&items=20"
        ).then((res) => res.json()),
        fetch(
          "https://api.click-n.com/find_name?find=plant%20base&pages=0&items=20"
        ).then((res) => res.json()),
      ]);
      setGroup1(
        [...group1, ...data1?.list, ...data2?.list].sort(
          () => Math.random() - 0.5
        )
      );

      const [data3, data4, data5, data6] = await Promise.all([
        fetch(
          "https://api.click-n.com/find_name?find=coca%20cola&pages=0&items=20"
        ).then((res) => res.json()),
        fetch(
          "https://api.click-n.com/find_name?find=pepsi&pages=0&items=20"
        ).then((res) => res.json()),
        fetch(
          "https://api.click-n.com/find_name?find=lays&pages=0&items=20"
        ).then((res) => res.json()),
        fetch(
          "https://api.click-n.com/find_name?find=pretzels&pages=0&items=20"
        ).then((res) => res.json()),
      ]);
      setGroup2(
        [
          ...group2,
          ...data3?.list,
          ...data4?.list,
          ...data5?.list,
          ...data6?.list,
        ].sort(() => Math.random() - 0.5)
      );
      setLoading(false);
    };

    fetchData();
     // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let day = new Date().getDay();
    let days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    setCurrentDay(days[day]);
  }, []);


  return (
    <div>
      <TinyCarousel
        title="Dairy & eggs"
        data={{
          list: data?.list
            .filter((product) => product.category === "Dairy & Eggs, Yogurt")
            .slice(0, 20),
        }}
        loading={isLoading}
      />
      <TinyCarousel
        title="Beverages & Drinks"
        data={{
          list: data?.list
            .filter(
              (product) => product.category === "Beverages & Drinks, Juice"
            )
            .slice(0, 20),
        }}
        loading={isLoading}
      />

      <TinyCarousel
        title="Better-for-you foods"
        data={{
          list: group1.slice(0, 20),
        }}
        loading={isLoading}
      />

      <TinyCarousel
        title="Game Day"
        data={{
          list: group2.slice(0, 20),
        }}
        loading={isLoading}
      />

      {/*{currentDay === 'Monday' ? (
          <TinyCarousel
            title="Fresh Produce"
            data={{
              list: mondayData?.list.slice(0, 40),
            }}
            loading={isLoading}
          />
        ) : currentDay === 'Tuesday' ? (
          <TinyCarousel
            title="Taco Tuesday"
            data={{
              list: group2.slice(0, 40),
            }}
            loading={isLoading}
          />

        ) : currentDay === 'Friday' || currentDay === 'Saturday'? (
          <TinyCarousel
            title="Game Day"
            data={{
              list: group2.slice(0, 40),
            }}
            loading={isLoading}
          />
        ) : currentDay === 'Sunday' ? (
          <TinyCarousel
            title="Grocery Essentials"
            data={{
              list: group2.slice(0, 40),
            }}
            loading={isLoading}
          />
        ) : (
          null
        )}*/}
    </div>
  );
}

export default ProductCard;
