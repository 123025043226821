import { React, useEffect, useState, useMemo } from "react";
import { useParams } from "react-router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";
import { RiArrowDropRightLine } from "react-icons/ri";
import { useStateContext } from "./../context/StateContext";
import { StoresData } from "./../Components/Filters/StoresData";
import { ShopBySkeleton } from "../Components/index";

function ShopByCategory() {
  const { onAdd } = useStateContext();
  const { category } = useParams();
  const encodedCategory = encodeURIComponent(category);
  const urls = [
    `https://api.click-n.com/find?&store=loblaws&category=${encodedCategory}&items=15`,
    `https://api.click-n.com/find?&store=nofrills&category=${encodedCategory}&items=15`,
    `https://api.click-n.com/find?&store=realcanadiansuperstore&category=${encodedCategory}&items=15`,
    `https://api.click-n.com/find?&store=saveonfoods&category=${encodedCategory}&items=15`,
    `https://api.click-n.com/find?&store=TNT&category=${encodedCategory}&items=15`,
    `https://api.click-n.com/find?&store=wallmart&category=${encodedCategory}&items=15`,
  ];
  const useCachedData = (urls) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    const cachedData = useMemo(() => {
      const cachedData = sessionStorage.getItem("cachedData");
      if (cachedData) {
        return JSON.parse(cachedData);
      } else {
        return {};
      }
    }, []);

    useEffect(() => {
      const fetchData = async () => {
        const requests = urls.map((url) => {
          if (cachedData[url]) {
            return Promise.resolve(cachedData[url]);
          } else {
            return fetch(url)
              .then((response) => response.json())
              .then((data) => {
                cachedData[url] = data;
                sessionStorage.setItem(
                  "cachedData",
                  JSON.stringify(cachedData)
                );
                return data;
              });
          }
        });

        try {
          const data = await Promise.all(requests);
          setData(data);
          setIsLoading(false);
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      };

      fetchData();
      // eslint-disable-next-line
    }, []);

    return { isLoading, data };
  };
  const { isLoading, data } = useCachedData(urls);
  const [
    loblawsData,
    nofrillsData,
    realcanadiansuperstoreData,
    saveonfoodData,
    tntData,
    walmartData,
  ] = data;
  const settings = {
    dots: true,
    autoplay: true,

    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="BreadCrumbs" style={{ marginTop: "170px" }}>
        <NavLink to={`/`}>
          <h2 className="Path"> Home </h2>
        </NavLink>
        <RiArrowDropRightLine />
        {category.split(",").map((cat, index) => (
          <>
            <NavLink to={`/category/${cat.trim()}`}>
              <h2 className="Path">{cat.trim()}</h2>
            </NavLink>
            {index !== category.split(",").length - 1 && (
              <RiArrowDropRightLine />
            )}
          </>
        ))}
      </div>
      {isLoading ? (
        <ShopBySkeleton />
      ) : (
        <div className="ShopBy-Container">
          <div className="ShopBy_Header">
            <h1 className="ShopBy_Title">{category}</h1>
          </div>
          <>
            {loblawsData?.list?.length !== 0 ? (
              <>
                <div className="ShopBy-Content-Header">
                  <div className="ShopBy-imagefit">
                    <img
                      className="ProductCard-image-logo"
                      src={StoresData[0].shopbyimg}
                      style={{ width: "80%" }}
                      alt="items"
                    />
                  </div>
                  <NavLink
                    to={`/category/${category}/${StoresData[0].store}`}
                    style={{ marginLeft: "5px", color: "#828282" }}
                  >
                    <p className="ShopBy-Seeall" style={{ marginTop: "-80px" }}>
                      {" "}
                      See All {category} & {StoresData[0].store} Products{" "}
                    </p>
                  </NavLink>
                </div>

                <div className="ShopBy-Content">
                  <div className="ShopBy-Content-Content">
                    <Slider {...settings}>
                      {loblawsData?.list.map((product, index) => (
                        <div key={index}>
                          <div
                            className="ProductCard-item-list"
                            key={product.category}
                            style={{ marginBottom: "20px", width: "60px" }}
                          >
                            <div className="item-car-header">
                              <NavLink
                                to={`/products/${
                                  product.id
                                }/${encodeURIComponent(`${product.name}`)}`}
                                style={{ marginLeft: "5px" }}
                              >
                                <img
                                  className="ProductCard-image"
                                  src={product.image}
                                  alt="items"
                                />
                              </NavLink>
                            </div>
                            <div
                              className="ProductCard-details"
                              style={{ height: "42px" }}
                            >
                              <p
                                className="ProductCard-name"
                                style={{
                                  fontSize: "13px",
                                  width: "75%",
                                  marginBottom: "20px",
                                }}
                              >
                                {product.name.substring(0, 40)}{" "}
                                {product.pricePerUnit && (
                                  <>{product.pricePerUnit}</>
                                )}
                              </p>
                            </div>
                            <div
                              className="ProductCard-prices"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "160px",
                              }}
                            >
                              <p
                                className="ProductCard-price"
                                style={{ fontSize: "13px", marginTop: "20px" }}
                              >
                                ${product.price}
                              </p>
                              {product.sale?.old_price !== undefined ? (
                                <p
                                  className="ProductCard-avg-price"
                                  style={{
                                    fontSize: "13px",
                                    marginTop: "20px",
                                    textDecoration: "line-through",
                                  }}
                                >
                                  ${product.sale.old_price}
                                </p>
                              ) : null}
                            </div>
                            <button
                              className="product-addtocart-slider"
                              onClick={() => onAdd(product, 1)}
                            >
                              {" "}
                              Add to cart
                            </button>
                            <NavLink
                              to={`/compare/${product.id}/${product.category}/${product.store}`}
                            >
                              <button
                                className="product-addtocart-slider-compare"
                                style={{ fontSize: "15px" }}
                              >
                                {" "}
                                Hybrid Comparison
                              </button>
                            </NavLink>
                            <NavLink
                              to={`/compare2/${product.id}/${product.category}/${product.store}`}
                            >
                              <button className="product-addtocart-slider-compare">
                                {" "}
                                Text Comparison
                              </button>
                            </NavLink>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </>
            ) : null}
          </>

          <>
            {nofrillsData?.list?.length !== 0 ? (
              <>
                <div className="ShopBy-Content-Header">
                  <div className="ShopBy-imagefit">
                    <img
                      className="ProductCard-image-logo"
                      src={StoresData[1].shopbyimg}
                      style={{ width: "80%" }}
                      alt="items"
                    />
                  </div>
                  <NavLink
                    to={`/category/${category}/${StoresData[1].store}`}
                    style={{ marginLeft: "5px", color: "#828282" }}
                  >
                    <p className="ShopBy-Seeall" style={{ marginTop: "-80px" }}>
                      {" "}
                      See All {category} & {StoresData[1].store} Products{" "}
                    </p>
                  </NavLink>
                </div>

                <div className="ShopBy-Content">
                  <div className="ShopBy-Content-Content">
                    <Slider {...settings}>
                      {nofrillsData?.list.map((product, index) => (
                        <div key={index}>
                          <div
                            className="ProductCard-item-list"
                            key={product.category}
                            style={{ marginBottom: "20px", width: "60px" }}
                          >
                            <div className="item-car-header">
                              <NavLink
                                to={`/products/${
                                  product.id
                                }/${encodeURIComponent(`${product.name}`)}`}
                                style={{ marginLeft: "5px" }}
                              >
                                <img
                                  className="ProductCard-image"
                                  src={product.image}
                                  alt="items"
                                />
                              </NavLink>
                            </div>
                            <div
                              className="ProductCard-details"
                              style={{ height: "42px" }}
                            >
                              <p
                                className="ProductCard-name"
                                style={{
                                  fontSize: "13px",
                                  width: "75%",
                                  marginBottom: "20px",
                                }}
                              >
                                {product.name.substring(0, 40)}{" "}
                                {product.pricePerUnit && (
                                  <>{product.pricePerUnit}</>
                                )}
                              </p>
                            </div>
                            <div
                              className="ProductCard-prices"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "160px",
                              }}
                            >
                              <p
                                className="ProductCard-price"
                                style={{ fontSize: "13px", marginTop: "20px" }}
                              >
                                ${product.price}
                              </p>
                              {product.sale?.old_price !== undefined ? (
                                <p
                                  className="ProductCard-avg-price"
                                  style={{
                                    fontSize: "13px",
                                    marginTop: "20px",
                                    textDecoration: "line-through",

                                  }}
                                >
                                  ${product.sale.old_price}
                                </p>
                              ) : null}
                            </div>
                            <button
                              className="product-addtocart-slider"
                              onClick={() => onAdd(product, 1)}
                            >
                              {" "}
                              Add to cart
                            </button>
                            <NavLink
                              to={`/compare/${product.id}/${product.category}/${product.store}`}
                            >
                              <button
                                className="product-addtocart-slider-compare"
                                style={{ fontSize: "15px" }}
                              >
                                {" "}
                                Hybrid Comparison
                              </button>
                            </NavLink>
                            <NavLink
                              to={`/compare2/${product.id}/${product.category}/${product.store}`}
                            >
                              <button className="product-addtocart-slider-compare">
                                {" "}
                                Text Comparison
                              </button>
                            </NavLink>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </>
            ) : null}
          </>

          <>
            {realcanadiansuperstoreData?.list?.length !== 0 ? (
              <>
                <div className="ShopBy-Content-Header">
                  <div className="ShopBy-imagefit">
                    <img
                      className="ProductCard-image-logo"
                      src={StoresData[2].shopbyimg}
                      style={{ width: "80%" }}
                      alt="items"
                    />
                  </div>
                  <NavLink
                    to={`/category/${category}/${StoresData[2].store}`}
                    style={{ marginLeft: "5px", color: "#828282" }}
                  >
                    <p className="ShopBy-Seeall" style={{ marginTop: "-80px" }}>
                      {" "}
                      See All {category} & {StoresData[2].store} Products{" "}
                    </p>
                  </NavLink>
                </div>

                <div className="ShopBy-Content">
                  <div className="ShopBy-Content-Content">
                    <Slider {...settings}>
                      {realcanadiansuperstoreData?.list.map(
                        (product, index) => (
                          <div key={index}>
                            <div
                              className="ProductCard-item-list"
                              key={product.category}
                              style={{ marginBottom: "20px", width: "60px" }}
                            >
                              <div className="item-car-header">
                                <NavLink
                                  to={`/products/${
                                    product.id
                                  }/${encodeURIComponent(`${product.name}`)}`}
                                  style={{ marginLeft: "5px" }}
                                >
                                  <img
                                    className="ProductCard-image"
                                    src={product.image}
                                    alt="items"
                                  />
                                </NavLink>
                              </div>
                              <div
                                className="ProductCard-details"
                                style={{ height: "42px" }}
                              >
                                <p
                                  className="ProductCard-name"
                                  style={{
                                    fontSize: "13px",
                                    width: "75%",
                                    marginBottom: "20px",
                                  }}
                                >
                                  {product.name.substring(0, 40)}{" "}
                                  {product.pricePerUnit && (
                                    <>{product.pricePerUnit}</>
                                  )}
                                </p>
                              </div>
                              <div
                                className="ProductCard-prices"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "160px",
                                }}
                              >
                                <p
                                  className="ProductCard-price"
                                  style={{ fontSize: "13px", marginTop: "20px" }}
                                >
                                  ${product.price}
                                </p>
                                {product.sale?.old_price !== undefined ? (
                                  <p
                                    className="ProductCard-avg-price"
                                    style={{
                                      fontSize: "13px",
                                      marginTop: "20px",
                                      textDecoration: "line-through",
                                    }}
                                  >
                                    ${product.sale.old_price}
                                  </p>
                                ) : null}
                              </div>
                              <button
                                className="product-addtocart-slider"
                                onClick={() => onAdd(product, 1)}
                              >
                                {" "}
                                Add to cart
                              </button>
                              <NavLink
                                to={`/compare/${product.id}/${product.category}/${product.store}`}
                              >
                                <button
                                  className="product-addtocart-slider-compare"
                                  style={{ fontSize: "15px" }}
                                >
                                  {" "}
                                  Hybrid Comparison
                                </button>
                              </NavLink>
                              <NavLink
                                to={`/compare2/${product.id}/${product.category}/${product.store}`}
                              >
                                <button className="product-addtocart-slider-compare">
                                  {" "}
                                  Text Comparison
                                </button>
                              </NavLink>
                            </div>
                          </div>
                        )
                      )}
                    </Slider>
                  </div>
                </div>
              </>
            ) : null}
          </>

          <>
            {saveonfoodData?.list?.length !== 0 ? (
              <>
                <div className="ShopBy-Content-Header">
                  <div className="ShopBy-imagefit">
                    <img
                      className="ProductCard-image-logo"
                      src={StoresData[3].shopbyimg}
                      style={{ width: "80%" }}
                      alt="items"
                    />
                  </div>
                  <NavLink
                    to={`/category/${category}/${StoresData[3].store}`}
                    style={{ marginLeft: "5px", color: "#828282" }}
                  >
                    <p className="ShopBy-Seeall" style={{ marginTop: "-80px" }}>
                      {" "}
                      See All {category} & {StoresData[3].store} Products{" "}
                    </p>
                  </NavLink>
                </div>

                <div className="ShopBy-Content">
                  <div className="ShopBy-Content-Content">
                    <Slider {...settings}>
                      {saveonfoodData?.list.map((product, index) => (
                        <div key={index}>
                          <div
                            className="ProductCard-item-list"
                            key={product.category}
                            style={{ marginBottom: "20px", width: "60px" }}
                          >
                            <div className="item-car-header">
                              <NavLink
                                to={`/products/${
                                  product.id
                                }/${encodeURIComponent(`${product.name}`)}`}
                                style={{ marginLeft: "5px" }}
                              >
                                <img
                                  className="ProductCard-image"
                                  src={product.image}
                                  alt="items"
                                />
                              </NavLink>
                            </div>
                            <div
                              className="ProductCard-details"
                              style={{ height: "42px" }}
                            >
                              <p
                                className="ProductCard-name"
                                style={{
                                  fontSize: "13px",
                                  width: "75%",
                                  marginBottom: "20px",
                                }}
                              >
                                {product.name.substring(0, 40)}{" "}
                                {product.pricePerUnit && (
                                  <>{product.pricePerUnit}</>
                                )}
                              </p>
                            </div>
                            <div
                              className="ProductCard-prices"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "160px",
                              }}
                            >
                              <p
                                className="ProductCard-price"
                                style={{ fontSize: "13px", marginTop: "20px" }}
                              >
                                ${product.price}
                              </p>
                              {product.sale?.old_price !== undefined ? (
                                <p
                                  className="ProductCard-avg-price"
                                  style={{
                                    fontSize: "13px",
                                    marginTop: "20px",
                                    textDecoration: "line-through",

                                  }}
                                >
                                  ${product.sale.old_price}
                                </p>
                              ) : null}
                            </div>
                            <button
                              className="product-addtocart-slider"
                              onClick={() => onAdd(product, 1)}
                            >
                              {" "}
                              Add to cart
                            </button>
                            <NavLink
                              to={`/compare/${product.id}/${product.category}/${product.store}`}
                            >
                              <button
                                className="product-addtocart-slider-compare"
                                style={{ fontSize: "15px" }}
                              >
                                {" "}
                                Hybrid Comparison
                              </button>
                            </NavLink>
                            <NavLink
                              to={`/compare2/${product.id}/${product.category}/${product.store}`}
                            >
                              <button className="product-addtocart-slider-compare">
                                {" "}
                                Text Comparison
                              </button>
                            </NavLink>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </>
            ) : null}
          </>

          <>
            {tntData?.list?.length !== 0 ? (
              <>
                <div className="ShopBy-Content-Header">
                  <div className="ShopBy-imagefit">
                    <img
                      className="ProductCard-image-logo"
                      src={StoresData[4].shopbyimg}
                      style={{ width: "80%" }}
                      alt="items"
                    />
                  </div>
                  <NavLink
                    to={`/category/${category}/${StoresData[4].store}`}
                    style={{ marginLeft: "5px", color: "#828282" }}
                  >
                    <p className="ShopBy-Seeall" style={{ marginTop: "-80px" }}>
                      {" "}
                      See All {category} & {StoresData[4].store} Products{" "}
                    </p>
                  </NavLink>
                </div>

                <div className="ShopBy-Content">
                  <div className="ShopBy-Content-Content">
                    <Slider {...settings}>
                      {tntData?.list.map((product, index) => (
                        <div key={index}>
                          <div
                            className="ProductCard-item-list"
                            key={product.category}
                            style={{ marginBottom: "20px", width: "60px" }}
                          >
                            <div className="item-car-header">
                              <NavLink
                                to={`/products/${
                                  product.id
                                }/${encodeURIComponent(`${product.name}`)}`}
                                style={{ marginLeft: "5px" }}
                              >
                                <img
                                  className="ProductCard-image"
                                  src={product.image}
                                  alt="items"
                                />
                              </NavLink>
                            </div>
                            <div
                              className="ProductCard-details"
                              style={{ height: "42px" }}
                            >
                              <p
                                className="ProductCard-name"
                                style={{
                                  fontSize: "13px",
                                  width: "75%",
                                  marginBottom: "20px",
                                }}
                              >
                                {product.name.substring(0, 40)}{" "}
                                {product.pricePerUnit && (
                                  <>{product.pricePerUnit}</>
                                )}
                              </p>
                            </div>
                            <div
                              className="ProductCard-prices"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "160px",
                              }}
                            >
                              <p
                                className="ProductCard-price"
                                style={{ fontSize: "13px", marginTop: "20px" }}
                              >
                                ${product.price}
                              </p>
                              {product.sale?.old_price !== undefined ? (
                                <p
                                  className="ProductCard-avg-price"
                                  style={{
                                    fontSize: "13px",
                                    marginTop: "20px",
                                    textDecoration: "line-through",

                                  }}
                                >
                                  ${product.sale.old_price}
                                </p>
                              ) : null}
                            </div>
                            <button
                              className="product-addtocart-slider"
                              onClick={() => onAdd(product, 1)}
                            >
                              {" "}
                              Add to cart
                            </button>
                            <NavLink
                              to={`/compare/${product.id}/${product.category}/${product.store}`}
                            >
                              <button
                                className="product-addtocart-slider-compare"
                                style={{ fontSize: "15px" }}
                              >
                                {" "}
                                Hybrid Comparison
                              </button>
                            </NavLink>
                            <NavLink
                              to={`/compare2/${product.id}/${product.category}/${product.store}`}
                            >
                              <button className="product-addtocart-slider-compare">
                                {" "}
                                Text Comparison
                              </button>
                            </NavLink>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </>
            ) : null}
          </>

          <>
            {walmartData?.list?.length !== 0 ? (
              <>
                <div className="ShopBy-Content-Header">
                  <div className="ShopBy-imagefit">
                    <img
                      className="ProductCard-image-logo"
                      src={StoresData[5].shopbyimg}
                      style={{ width: "80%" }}
                      alt="items"
                    />
                  </div>
                  <NavLink
                    to={`/category/${category}/${StoresData[5].store}`}
                    style={{ marginLeft: "5px", color: "#828282" }}
                  >
                    <p className="ShopBy-Seeall" style={{ marginTop: "-80px" }}>
                      {" "}
                      See All {category} & {StoresData[5].store} Products{" "}
                    </p>
                  </NavLink>
                </div>

                <div className="ShopBy-Content">
                  <div className="ShopBy-Content-Content">
                    <Slider {...settings}>
                      {walmartData?.list.map((product, index) => (
                        <div key={index}>
                          <div
                            className="ProductCard-item-list"
                            key={product.category}
                            style={{ marginBottom: "20px", width: "60px" }}
                          >
                            <div className="item-car-header">
                              <NavLink
                                to={`/products/${
                                  product.id
                                }/${encodeURIComponent(`${product.name}`)}`}
                                style={{ marginLeft: "5px" }}
                              >
                                <img
                                  className="ProductCard-image"
                                  src={product.image}
                                  alt="items"
                                />
                              </NavLink>
                            </div>
                            <div
                              className="ProductCard-details"
                              style={{ height: "42px" }}
                            >
                              <p
                                className="ProductCard-name"
                                style={{
                                  fontSize: "13px",
                                  width: "75%",
                                  marginBottom: "20px",
                                }}
                              >
                                {product.name.substring(0, 40)}{" "}
                                {product.pricePerUnit && (
                                  <>{product.pricePerUnit}</>
                                )}
                              </p>
                            </div>
                            <div
                              className="ProductCard-prices"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "160px",
                              }}
                            >
                              <p
                                className="ProductCard-price"
                                style={{ fontSize: "13px", marginTop: "20px" }}
                              >
                                ${product.price}
                              </p>
                              {product.sale?.old_price !== undefined ? (
                                <p
                                  className="ProductCard-avg-price"
                                  style={{
                                    fontSize: "13px",
                                    marginTop: "20px",
                                    textDecoration: "line-through",

                                  }}
                                >
                                  ${product.sale.old_price}
                                </p>
                              ) : null}
                            </div>
                            <button
                              className="product-addtocart-slider"
                              onClick={() => onAdd(product, 1)}
                            >
                              {" "}
                              Add to cart
                            </button>
                            <NavLink
                              to={`/compare/${product.id}/${product.category}/${product.store}`}
                            >
                              <button
                                className="product-addtocart-slider-compare"
                                style={{ fontSize: "15px" }}
                              >
                                {" "}
                                Hybrid Comparison
                              </button>
                            </NavLink>
                            <NavLink
                              to={`/compare2/${product.id}/${product.category}/${product.store}`}
                            >
                              <button className="product-addtocart-slider-compare">
                                {" "}
                                Text Comparison
                              </button>
                            </NavLink>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </>
            ) : null}
          </>
        </div>
      )}
    </>
  );
}

export default ShopByCategory;
