import { React, useState} from 'react'
import { FiMenu, FiShoppingCart } from 'react-icons/fi';
import  logo  from '../../Assets/Images/Logo.png';
import Sidebar from './Sidebar';
import Cart from './Cart';
import Login from '../Authentification/Login';
import Register from '../Authentification/Register';
import Recover from '../Authentification/Recover';
import SearchBar from './SearchBar/SearchBar';
import {BiUserCircle} from "react-icons/bi"
import { useStateContext} from '../../context/StateContext';
import {NavLink} from 'react-router-dom';
import { RiHeartLine, RiShoppingCartLine, RiMenuLine } from "react-icons/ri";
//import { RiUser3Line, RiArrowLeftRightFill,} from "react-icons/ri";


function Navbar({user}) {
    const { showCart, setShowCart, showSidebar, setShowSidebar, showRegister, showRecover, showLogin,  totalQuantities} = useStateContext();
    const [isOpen, setIsOpen] = useState(false); 
    const toggle = () => setIsOpen(!isOpen);

    const onMouseEnter = () => {
        if (window.innerWidth < 960) {
          setIsOpen(false);
        } else {
          setIsOpen(true);
        }
      };
    
      const onMouseLeave = () => {
        if (window.innerWidth < 960) {
          setIsOpen(false);
        } else {
          setIsOpen(false);
        }
      };    

  return (
    <div className='Navbar-container'>
        <div className='Navbar-Mobile-Logo'>
            <img
                className='Logo-top'
                src={logo}
                alt='logo'
                width="120" height="36"
            />
        </div>
        <div className='Navbar-Mobile-Menu'>
            <RiMenuLine onClick={() => setShowSidebar(true)}/>
            <RiHeartLine/>
            <button type="button" className="cart-icon-1" >
                <RiShoppingCartLine onClick={() => setShowCart(true)}/>
                <span className="cart-item-qty">{totalQuantities}</span>
            </button>
            {/*<RiArrowLeftRightFill/>*/}
            {/*<RiUser3Line/>*/}
        </div>      

    <div className='Navbar'>     
        <div className='Navbar-left'>
            <FiMenu className='Menu' />
            <NavLink to={`/`} >
                <img
                    className='Logo'
                    src={logo}
                    alt='logo'
                    onClick={() => window.location = `/`}
                />
            </NavLink>
        </div>

                
        <div className='Navbar-center'>
            <SearchBar/>
        </div>
                
        <div className='Navbar-right'>
            <div 
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <div className="name user-nav" onClick={toggle}>
                    <BiUserCircle className='name-icon' />
                        { user ? (<h2 className='User-name'> Hello, {user.firstName} {user.lastName} </h2>) 
                            : (<h2 className='User-name'>Hello, Guest</h2>)}        
                </div>
                {/*{
                    isOpen ?
                <div className='drop-down-modal-authentification'>
                
                    <ul className='header-departement-ul'>
                        <li className='header-authentification-li has-child'>
                            
                            <div className='header-authentification-modal'>
                                <p className='header-departement-title' onClick={() => setShowLogin(true)}>Login</p>
                            </div>
                            <div className='header-authentification-modal'>
                                <p className='header-departement-title' onClick={() => setShowRegister(true)}>Sign up</p>
                            </div>                        
                            
                        </li>                
                    </ul>
                </div>  
                    : null
                    }*/}
            </div>            

            <button type="button" className="cart-icon" onClick={() => setShowCart(true)}>
                <FiShoppingCart className='Cart-logo'/>
                <span className="cart-item-qty">{totalQuantities}</span>
                
            </button>
            

                {showSidebar && <Sidebar />}
                {showCart && <Cart />}
                {showLogin && <Login />}
                {showRegister && <Register />}
                {showRecover && <Recover />}                
        </div>  
    </div>

    </div>
  )
}

export default Navbar