import React from 'react'

function ClickNChallenge() {
  return (
    <div style={{marginTop: '100px', padding: '20px'}}>
       <h1 style={{marginBottom: '40px'}}> ClickN Challenge for matching algorithm</h1>
       <h2 style={{marginBottom: '20px'}}>Challenge Description: </h2>
       <p>We have a json file that contain products from different sources The items have the same field structure, We want a function that would take the id of a product and return a list of the other items in the json file that describe the same product.</p>          
       <h2 style={{marginBottom: '20px'}}> Rewards: </h2>
        <p> Cash prize of $100 & the chance to join our startup team.
        </p>     
        <h2 style={{marginBottom: '20px'}}> Submission Requirements: </h2>
        <p>Implementation of the solution. (Preferably in Python but not mandatory)
            Documentation on dependencies and how to build your solution (e.g. Makefile, shell scripts, requirements.txt files)
            Include a separate descriptive file as a writeup of how you approached solving the challenge. (Preferably as PDF)
            Submissions are done via branches based on main branch. Use relevant github account names to easily identify the person.
        </p>
        <h2 style={{marginBottom: '20px'}}> Github Page: </h2>
        <a href="https://github.com/ClickNTeam/Challenge"><span style={{color: '#0AAD0A', fontWeight: '500'}}>The link to the Github page of the challange for more details.</span></a>     
    </div>
  )
}

export default ClickNChallenge