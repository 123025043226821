import React,  {useState} from 'react'
import { useStateContext } from '../../context/StateContext';
import { HiOutlineUser, HiOutlineMail, HiOutlineLockClosed, HiOutlineEyeOff, HiOutlineEye, HiX } from "react-icons/hi";
import {NavLink} from 'react-router-dom';


function Register() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [isRevealpassword, setIsRevealpassword] = useState(false);
    const { setShowRegister} = useStateContext();



    const handleSubmit = (e) => {
        e.preventDefault();
        const user = { email, username, password}

        fetch('http://127.0.0.1ody:5000/register', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)

        }).then(()=> {
            console.log('user added');
        })
    }



  return (

    <div className="Authentification">
        <div className='AuthContainer'>
            <div className='Authforms'>
                <div className='Authform login'>
                    <div className='authentification-title'>
                        < span className='formTitle'>Registration</span>
                        <button className='authentification-close' onClick={() => setShowRegister(false)}>
                                <HiX/>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit}>
                    <div className='input-field'>
                            <input 
                            type='text' 
                            onChange={(e)=>setUsername(e.target.value)}
                            value={username}
                            placeholder='Enter your name' 
                            name='username'
                            required/>
                            <HiOutlineUser className="input-icon left-icon" />
                        </div>                        
                        <div className='input-field'>
                            <input 
                            type='text' 
                            onChange={(e)=>setEmail(e.target.value)}
                            value={email}
                            placeholder='Enter your email'
                            name='email'
                            required/>
                            <HiOutlineMail className="input-icon left-icon" />
                        </div>
                        <div className='input-field'>
                            <input type='password' placeholder='Enter your password' required/>
                            <HiOutlineLockClosed className="input-icon left-icon"/>
                            
                        </div>  
                        <div className='input-field'>
                            <input 
                                placeholder='Confirm your password' 
                                type={isRevealpassword ? "text" : "password"}
                                value={password}
                                name='password'
                                onChange={e => setPassword(e.target.value)}                                
                                required
                            />
                            <HiOutlineLockClosed className="input-icon left-icon"/>
                            <HiOutlineEyeOff className="input-icon showHidePw"
                                      title={isRevealpassword ? "Hide password" : "Show password"}
                                      src={isRevealpassword ? HiOutlineEyeOff : HiOutlineEye}
                                      onClick={() => setIsRevealpassword(prevState => !prevState)}
                            />
                        </div>                          
                        <div className='input-field button'>
                            <input type='submit'  value={'Register Now'}/>                            
                        </div>                  
                    </form>
                    <div className='login-signup'>
                        <span className='text-check'>Already Have an Account? </span>
                        <NavLink to={`/`} className='text-check signup-text'>Login</NavLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Register