import {React, useState} from 'react'
import { StripeContainer} from "../Components/index";


function StripePayement() {
	const [showItem, setShowItem] = useState(false);
	return (
		<div className='App'>
			<h1>ClickN</h1>
			{showItem ? (
				<StripeContainer />
			) : (
				<>
					<h3>$10.00</h3>
					
					<button onClick={() => setShowItem(true)}>Test</button>
				</>
			)}
		</div>
	);
}


export default StripePayement