import React from 'react'
import { useStateContext } from '../../context/StateContext';


function CheckoutSide() {
  const {  totalPrice} = useStateContext();
  
  return (
    <div className='checkoutSide-Container'>
      <div className='checkoutSide-Container-inner '>
        <div className='checkoutSide-small-container'>
          <h5 className='checkoutSide-Container-inner-title'>
            Product Subtotal:
          </h5>
          <h6 className='checkoutSide-Container-inner-text'>
            $ {totalPrice}
          </h6>
        </div>
        <div className='checkoutSide-small-container'>
          <h5 className='checkoutSide-Container-inner-title'>
            Shipping Fees:
          </h5>
          <h6 className='checkoutSide-Container-inner-text'>
            $ 0
          </h6>
        </div>  
        <div className='checkoutSide-small-container'>
          <h5 className='checkoutSide-Container-inner-title'>
            Service Fees:
          </h5>
          <h6 className='checkoutSide-Container-inner-text'>
            $ 0
          </h6>
        </div>
        <div className='checkoutSide-small-container'>
          <h5 className='checkoutSide-Container-inner-title'>
            Service Fees Tax:
          </h5>
          <h6 className='checkoutSide-Container-inner-text'>
            $ 0
          </h6>
        </div>
        <div className='checkoutSide-small-container subtotal-last'>
          <h5 className='checkoutSide-Container-inner-title subtotal-last-title'>
            Subtotal:
          </h5>
          <h6 className='checkoutSide-Container-inner-text'>
            $ {totalPrice}
          </h6>
        </div>                              
      </div>
      <div className='checkoutSide-Container-inner'>
        <div className='checkoutSide-Container-inner-paragraphs'>
          <p className='checkoutSide-Container-paragraph first-paragraph' >
            By placing your order, you agree to be bound by the ClickN Terms of Service and Privacy. Your credit/debit card data will not saved.
          </p>
          <p className='checkoutSide-Container-paragraph'>
          A bag fee may be added to your final total if required by law or the retailer. The fee will be visible on your receipt after delivery.
          </p>          
        </div>
      </div>
    </div>
  )
}

export default CheckoutSide