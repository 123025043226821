import React, {useState, useEffect} from 'react'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
//import {NavLink, useLocation} from 'react-router-dom'
import {useParams} from 'react-router';
import { useStateContext } from '../../context/StateContext';
//import {ProductData} from '../Sliders/Data/ProductData';
import {NavLink} from 'react-router-dom';
import { RiArrowDropRightLine} from "react-icons/ri";
//import {ProductSkeleton} from '../index';
//import  MoonLoader  from 'react-spinners/MoonLoader';


function Product() {
  const [data, setData] = useState();
  const {id, name} = useParams();
  const { qty, decQty, incQty, onAdd} = useStateContext(); 
  


  // Function to collect data
  const getApiData = async () => {
    //setIsLoading(true);
    const response = await fetch(
      //"https://api.click-n.com/find?find=&items=5000"
      `https://api.click-n.com/find_name?find=${name}
      `      
    ).then((response) => response.json());
    //setIsLoading(false);

    // update the state
    setData(response);
  };

  useEffect(() => {
  getApiData();
  // eslint-disable-next-line
}, []);

 
      return ( 
        <>
            <div >
                {data?.list.filter((product) => product.id === id).map((product, i) => (
                  <div key={i}>
                    <>
                      <div className='BreadCrumbs' key={product.id} style={{ marginTop: '170px',}}>
                        <NavLink to={`/`}> <h2 className='Path'> Home </h2> </NavLink> <RiArrowDropRightLine/>
                        <NavLink to={`/shopby/${product.category}`}> <h2 className='Path'> Shop By </h2> </NavLink> <RiArrowDropRightLine/>
                        <NavLink to={`/category/${product.category}/${product.store}`}> <h2 className='Path'>{product.category}</h2> </NavLink> <RiArrowDropRightLine/>
                        <h2 className='Page'>{product.name}</h2>                    
                      </div>    
                    </>          
                    <>

                      <div className='product-bigger-wrapper'>
                        <div className="product-list-wrapper"> 
                          <div className="product-detail-container">              
                            <div>                    
                              <div className="image-container">
                                <img src={product.image} className="product-detail-image" alt=""/>
                              </div>            
                            </div>
                            <div className="product-detail-desc">
                              <div className="product-detail-header-title">
                                <h1 className='product-name'>{product.name}</h1>
                              </div>
                              <p className='product-unit-title'> {product.measurement}</p>
                              <div className="product-unit-price-container">
                              
                                <p className='product-unit-price'> ${product.price}</p>

                                { product.sale?.old_price !== undefined ?
                                  <p className='product-unit-wasprice'> ${product.sale.old_price}</p>
                                  : null}

                                { product.sale?.old_price !== undefined ?
                                  <p className=' product-unit-price product-savings' style={{fontWeight: '400'}}> $ {(Math.round((product.sale.old_price - product.price)*100)/100)} Off</p>
                                  : null}  
                              </div>              
                              <h4 className='product-description-p'>Product Details: </h4>
                              <div className='description'>
                                <p className='product-description'>{product.description}</p>            
                              </div>
                              <div className='product-detail'>
                                {/*<div className='product-Brand'>
                                  <p className='product-p'> Brand:</p>
                                  <p className='product-description-brand'> {product.brand}</p>
                                </div>*/}
                                <div className='product-Brand'>
                                  <p className='product-p'> Store:</p>
                                  <p className='product-description-brand'> {product.store}</p>
                                </div>

                              </div>

                  
                              <div className="quantity">
                                <div className="quantity-desc">
                                  <button className="minus" onClick={decQty}><AiOutlineMinus /></button>
                                  <span className="num">{qty}</span>
                                  <button className="plus" onClick={incQty}><AiOutlinePlus /></button>
                                </div>
                              </div>
                              <div className="buttons">
                                <button type="button" className="buy-now" onClick={() => onAdd(product, qty)}>Add to Cart</button>
                                <NavLink to={`/compare/${product.id}/${product.category}/${product.store}`} >
                                <button type="button" className="add-to-cart">Compare Product</button> 
                                </NavLink>           
                              </div>
                            </div>
                          </div>
                        </div>
                    
                      </div>
                  </>
                  </div> 
                ))}       
            </div>  
        </> 
    )
}

export default Product