import React from 'react'
import CategoriesFilter from '../Filters/CategoriesFilter'
import StoresFilter from '../Filters/StoresFilter'
import ProductCard from '../Sliders/ProductCard'

const VersionTwoSkeleton = () => {
    return (
        <div>
          <div className='Products-page-Ads'style={{ marginTop: '170px',}}>
            <div className='Products-page-Bigbanner-skeleton' style={{width: '2200px'}}></div>
            <div className='Products-page-small skeleton-loading' style={{padding: '10px'}}></div>     
          </div>
    
        <div className='homepage-banner'></div>  
        <div className="Products-page-items">
          <h4 className='ProductCard-Bigtitle'> Shop By Categories</h4>
          <CategoriesFilter />
        </div>
        <div className="Products-page-carousel">
          <ProductCard />
        </div>    
        </div>
      )
}

export default VersionTwoSkeleton