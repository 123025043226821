export const menuItems = [
  {
    title: 'Grocery',
    url: '',
    submenu: [
      {
        title: 'Snacks & Candy',
        url: '/shopby/Snacks & Candy',        
        submenu: [
          {title: 'Candy', id: 301, name: 'Snacks & Candy, Candy', url: '/shopby/Snacks & Candy, Candy',},
          {title: 'Chocolate', id: 302, name: 'Snacks & Candy, Chocolate', url: '/shopby/Snacks & Candy, Chocolate',},
          {title: 'chips', id: 303, name: 'Snacks & Candy, Chips', url: '/shopby/Snacks & Candy, Chips',},
          {title: 'Popcorn & Pretzels', id: 304, name: 'Snacks & Candy, Popcorn & Pretzels', url: '/shopby/Snacks & Candy, Popcorn & Pretzels',},
          {title: 'Snack Bars', id: 305, name: 'Snacks & Candy, Snack Bars', url: '/shopby/Snacks & Candy, Snack Bars',},
          {title: 'Gum & Mints', id: 306, name: 'Snacks & Candy, Gum & Mints', url: '/shopby/Snacks & Candy, Gum & Mints',},
          {title: 'Cookies', id: 308, name: 'Snacks & Candy, Cookies', url: '/shopby/Snacks & Candy, Cookies',},
          {title: 'Jerky', id: 309, name: 'Snacks & Candy, Jerky', url: '/shopby/Snacks & Candy, Jerky',},
          {title: 'Nuts', id: 310, name: 'Snacks & Candy, Nuts', url: '/shopby/Snacks & Candy, Nuts',},
          //{title: 'Dips', id: 310, name: 'Snacks & Candy, Dips', url: '/shopby/Snacks & Candy, Dips',},
          {title: 'Snack cups', id: 311, name: 'Snacks & Candy, Snack Cups', url: '/shopby/Snacks & Candy, Snack Cups',},
          {title: 'Crispy', id: 311, name: 'Snacks & Candy, Crispy', url: '/shopby/Snacks & Candy, Crispy',},
          //{title: 'Crisps', id: 311, name: 'Snacks & Candy, Crisps', url: '/shopby/Snacks & Candy, Crisps',},
          {title: 'Fruit-Snacks', id: 312, name: 'Snacks & Candy, Fruit-Snacks', url: '/shopby/Snacks & Candy, Fruit-Snacks',},
          {title: 'Crackers', id: 313, name: 'Snacks & Candy, Crackers', url: '/shopby/Snacks & Candy, Crackers',},
          {title: 'Ice-Cream-Cones-and-Toppings', id: 314, name: 'Snacks & Candy, Ice-Cream-Cones-and-Toppings', url: '/shopby/Snacks & Candy, Ice-Cream-Cones-and-Toppings',},
          // {title: 'chips Crisps Crispy Crackers', id: 315, name: 'chips Crisps Crispy Crackers', url: '/shopby/chips Crisps Crispy Crackers',},
          {title: 'Miscellaneous', id: 315, name: 'Snacks & Candy, miscellaneous', url: '/shopby/Snacks & Candy, miscellaneous',}, 
          // {title: 'miscellaneous, Chocolate', id: 315, name: 'Snacks & Candy, miscellaneous, Chocolate', url: '/shopby/Snacks & Candy, miscellaneous, Chocolate',},   
          
        ],
        id: 2,
        category:"Snacks & Candy"             
      }, 
      {
        image: "https://i.ibb.co/wzNcbMk/10wk1-Grocery-Bubcat-Canned-Packaged-Foods-200901-1598994624706.png",
        title: "Pantry food",
        url: '/shopby/Pantry',        
        submenu: [
             {title: 'Canned & Pickeled', id: 801, name: 'Pantry, Canned & Pickeled', url: '/shopby/Pantry, Canned & Pickeled',},
             {title: 'Pasta & Pasta Sauce', id: 802, name: 'Pantry, Pasta & Pasta Sauce', url: '/shopby/Pantry, Pasta & Pasta Sauce',},
             {title: 'Baking Essentials', id: 803, name: 'Pantry, Baking Essentials', url: '/shopby/Pantry, Baking Essentials',},
             {title: 'Easy Meals & sides', id: 804, name: 'Pantry, Easy Meals & sides', url: '/shopby/Pantry, Easy Meals & sides',},
             {title: 'Cereal & Breakfast', id: 805, name: 'Pantry, Cereal & Breakfast', url: '/shopby/Pantry, Cereal & Breakfast',},
             {title: 'Honey, Syrup & spread', id: 806, name: 'Pantry, Honey, Syrup & spread', url: '/shopby/Pantry, Honey, Syrup & spread',},
             {title: 'Rice', id: 807, name: 'Pantry, Rice', url: '/shopby/Pantry, Rice',},
             {title: 'Oils & Vinegar', id: 808, name: 'Pantry, Oils & Vinegar', url: '/shopby/Pantry, Oils & Vinegar',},
             {title: 'Condiments', id: 809, name: 'Pantry, Condiments', url: '/shopby/Pantry, Condiments',},
             {title: 'Spices & Seasonings', id: 810, name: 'Pantry, Spices & Seasonings', url: '/shopby/Pantry, Spices & Seasonings',},
             {title: 'Bulk', id: 811, name: 'Pantry, Spices & Seasonings', url: '/shopby/Pantry, Bulk',}, 
             {title: 'Snack Cups', id: 811, name: 'Pantry, Snack Cups', url: '/shopby/Pantry, Snack Cups',},              
             {title: 'Dried Beans, Vegetables & Grains', id: 812, name: 'Pantry, Dried Beans, Vegetables & Grains', url: '/shopby/Pantry, Dried Beans, Vegetables & Grains',},
             {title: 'Condiments & Sauces', id: 812, name: 'Pantry, Condiments & Sauces', url: '/shopby/Pantry, Condiments & Sauces',},
             {title: 'Miscellaneous', id: 813, name: 'Pantry, miscellaneous', url: '/shopby/Pantry, miscellaneous',},
         ],     
        id: 10,
        category:"Pantry"            
      },           
      {
        image: "https://i.ibb.co/wzNcbMk/10wk1-Grocery-Bubcat-Canned-Packaged-Foods-200901-1598994624706.png",
        title: "Natural Foods",
        url: '/shopby/Natural Foods',        
        submenu: [
             {title: 'Condiments & sauces', id: 801, name: 'Natural Foods, Condiments & sauces', url: '/shopby/Natural Foods, Condiments & sauces',},
             {title: 'Chocolate, Gum & Candy', id: 802, name: 'Natural Foods, Chocolate, Gum & Candy', url: '/shopby/Natural Foods, Chocolate, Gum & Candy',},
             {title: 'Cereal & Granola', id: 803, name: 'Natural Foods, Cereal & Granola', url: '/shopby/Natural Foods, Cereal & Granola',},
             {title: 'Spread & Syrups', id: 804, name: 'Natural Foods, Spread & Syrups', url: '/shopby/Natural Foods, Spread & Syrups',},
             {title: 'Beverages', id: 805, name: 'Natural Foods, Beverages', url: '/shopby/Natural Foods, Beverages',},
             {title: 'Baking', id: 806, name: 'Natural Foods, Baking', url: '/shopby/Natural Foods, Baking',},
             {title: 'Canned', id: 807, name: 'Natural Foods, Canned', url: '/shopby/Natural Foods, Canned',},
             {title: 'Oil & Vinegar', id: 808, name: 'Natural Foods, Oil & Vinegar', url: '/shopby/Natural Foods, Oil & Vinegar',},
             {title: 'Easy prep & sides', id: 809, name: 'Natural Foods, Easy prep & sides', url: '/shopby/Natural Foods, Easy prep & sides',},
             {title: 'Snacks', id: 810, name: 'Natural Foods, Snacks', url: '/shopby/Natural Foods, Snacks',},
             //{title: 'Miscellaneous', id: 813, name: 'Natural Foods, Miscellaneous', url: '/shopby/Natural Foods, Miscellaneous',},
         ],     
        id: 13,
        category:"Natural Foods"            
      },      
      {
        title: 'Dairy & Eggs',
        url: '/shopby/Dairy & Eggs',
        submenu: [
          {title: 'Cheese', id: 101, name: 'Dairy & Eggs, Cheese', url: '/shopby/Dairy & Eggs, Cheese',},
          {title: 'Eggs', id: 102, name: 'Dairy & Eggs, Eggs', url: '/shopby/Dairy & Eggs, Eggs',},
          {title: 'Milk', id: 103, name: 'Dairy & Eggs, Milk', url: '/shopby/Dairy & Eggs, Milk',},
          {title: 'Yogurt', id: 104, name: 'Dairy & Eggs, Yogurt', url: '/shopby/Dairy & Eggs, Yogurt',},
          {title: 'Butter', id: 105, name: 'Dairy & Eggs, Butter', url: '/shopby/Dairy & Eggs, Butter',},
          {title: 'Cream', id: 106, name: 'Dairy & Eggs, Cream', url: '/shopby/Dairy & Eggs, Cream',},
          {title: 'Sour Cream', id: 107, name: 'Dairy & Eggs, Sour Cream & Dip', url: '/shopby/Dairy & Eggs, Sour Cream & Dip',},
          {title: 'Coffee Creamer', id: 108, name: 'Dairy & Eggs, Coffee Creamer', url: '/shopby/Dairy & Eggs, Coffee Creamer',},
          {title: 'Miscellaneous', id: 109, name: 'Dairy & Eggs, miscellaneous', url: '/shopby/Dairy & Eggs, miscellaneous',},     
        ],
        id: 3,
        category:"Dairy & Eggs"         
      },  
      {
        title: 'Deli',
        url: '/shopby/Deli',        
        submenu: [
          {title: 'Deli Meat', id: 601, name: 'Deli, Deli Meat', url: '/shopby/Deli, Deli Meat',},
          {title: 'Deli Cheese', id: 602, name: 'Deli, Deli Cheese', url: '/shopby/Deli, Deli Cheese',},
          {title: 'Antipasto, Dips, spreads', id: 603, name: 'Deli, Antipasto, Dips, Spreads', url: '/shopby/Deli, Antipasto, Dips, Spreads',},
          {title: 'Crackers & Condiments', id: 604, name: 'Deli, Crackers & Condiments', url: '/shopby/Deli, Crackers & Condiments',},
          {title: 'Vegan & Vegetarian', id: 605, name: 'Deli, Vegan & Vegetarian', url: '/shopby/Deli, Vegan & Vegetarian',},
          {title: 'Lunch & Snack Kits', id: 606, name: 'Deli, Lunch & Snack Kits', url: '/shopby/Deli, Lunch & Snack Kits',},
          {title: 'Party Trays', id: 607, name: 'Deli, Party Trays', url: '/shopby/Deli, Party Trays',},
          //{title: 'Miscellaneous', id: 609, name: 'Deli, miscellaneous', url: '/shopby/Deli, miscellaneous',},  
        ],
        id: 4,
        category:"Deli"
      },  
      {
        image: "https://i.ibb.co/VqTyvGj/9-Wk5-Beverages-CN-1190710-190823-1566596249823.png",
        title: "Drinks",
        url: '/shopby/Drinks',
        submenu: [
             {title: 'Sodas & Soft Drinks', id: 201, name: 'Beverages & Drinks, Sodas & Soft Drinks', url: '/shopby/Beverages & Drinks, Sodas & Soft Drinks',}, 
             {title: 'Sports & Energy Drinks', id: 202, name: 'Beverages & Drinks, Sports & Energy Drinks', url: '/shopby/Beverages & Drinks, Sports & Energy Drinks',}, 
             {title: 'Sports Drinks', id: 202, name: 'Beverages & Drinks, Sports Drinks', url: '/shopby/Beverages & Drinks, Sports Drinks',}, 
             {title: 'Coffee', id: 203, name: 'Beverages & Drinks, Coffee', url: '/shopby/Beverages & Drinks, Coffee',}, 
             {title: 'Water', id: 204, name: 'Beverages & Drinks, Water', url: '/shopby/Beverages & Drinks, Water',}, 
             {title: 'Juice', id: 206, name: 'Beverages & Drinks, Juice', url: '/shopby/Beverages & Drinks, Juice',}, 
             {title: 'Tea & Hot Drinks', id: 207, name: 'Beverages & Drinks, Tea & Hot Drinks', url: '/shopby/Beverages & Drinks, Tea & Hot Drinks',}, 
             {title: 'Tea', id: 207, name: 'Beverages & Drinks, Tea', url: '/shopby/Beverages & Drinks, Tea',}, 
             {title: 'Drink Mixes', id: 208, name: 'Beverages & Drinks, Drink Mixes', url: '/shopby/Beverages & Drinks, Drink Mixes',}, 
             {title: 'Soy, rice & Nut Drinks', id: 209, name: 'Beverages & Drinks, Soy, Rice & Nut Drinks', url: '/shopby/Beverages & Drinks, Soy, Rice & Nut Drinks',}, 
             {title: 'Non Alcoholic Drinks', id: 210, name: 'Beverages & Drinks, Non Alcoholic Drinks', url: '/shopby/Beverages & Drinks, Non Alcoholic Drinks',},
             //{title: 'Miscellaneous', id: 211, name: 'Beverages/Drinks, Miscellaneous', url: '/shopby/Drinks, Miscellaneous',},
         ],
        id: 5,
        category:"Beverages/Drinks"            
      }, 
      {
        image: "https://i.ibb.co/nkdJQgn/5wk4-Grocery-Cat-Navs-Frozen-Meats-200511-1589218368584.png",
        title: "Frozen food",
        url: '/shopby/Frozen',  
        submenu: [
        {title: 'Frozen Pizzas & Meals', id: 401, name: 'Frozen, Frozen Pizzas & Meals', url: '/shopby/Frozen, Frozen Pizzas & Meals',}, 
        {title: 'Frozen Ice Cream & Popsicles', id: 402, name: 'Frozen, Ice Cream & Popsicles', url: '/shopby/Frozen, Ice Cream & Popsicles',},
        {title: 'Frozen Fruits & Vegetables', id: 403, name: 'Frozen, Frozen Fruits & Vegetables', url: '/shopby/Frozen, Frozen Fruits & Vegetables',},
        {title: 'Frozen Breakfast', id: 404, name: 'Frozen, Frozen Breakfast', url: '/shopby/Frozen, Frozen Breakfast',},
        {title: 'Frozen Meat & Seafood', id: 405, name: 'Frozen, Frozen Meat & Seafood', url: '/shopby/Frozen, Frozen Meat & Seafood',},
        {title: 'Frozen Desserts', id: 406, name: 'Frozen, Frozen Desserts', url: '/shopby/Frozen, Frozen Desserts',},
        {title: 'Frozen Appetizers & Snacks', id: 407, name: 'Frozen, Frozen Appetizers & Snacks', url: '/shopby/Frozen, Frozen Appetizers & Snacks',}, 
        {title: 'Frozen Drinks & Ice', id: 409, name: 'Frozen, Frozen Drinks & Ice', url: '/shopby/Frozen, Frozen Drinks & Ice',},
        {title: 'Frozen Plant-based Proteins', id: 410, name: 'Frozen, Frozen Plant-based Proteins', url: '/shopby/Frozen, Frozen Plant-based Proteins',},
        {title: 'Frozen Breads & Doughs', id: 410, name: 'Frozen, Frozen Breads & Doughs', url: '/shopby/Frozen, Frozen Breads & Doughs',},
        //{title: 'Miscellaneous', id: 411, name: 'Frozen, Miscellaneous', url: '/shopby/Frozen, Miscellaneous',},
   ],       
      id: 6,
      category:"Frozen"            
      },
      {
        image: "https://i.ibb.co/bQy6KBv/5wk4-Grocery-Cat-Navs-Produce226138-200504-1588627319062.png",
        title: "Fruits & vegetables",
        url: '/shopby/Fruits & Vegetables',        
        submenu: [
            {title: 'Fresh Vegetables', id: 1301, name: 'Fruits & Vegetables, Fresh Vegetables', url: '/shopby/Fruits & Vegetables, Fresh Vegetables',},
            {title: 'Fresh Fruits', id: 1302, name: 'Fruits & Vegetables, Fresh Fruits', url: '/shopby/Fruits & Vegetables, Fresh Fruits',},
            {title: 'Package Salad & Dressing', id: 1303, name: 'Fruits & Vegetables, Package Salad & Dressing', url: '/shopby/Fruits & Vegetables, Package Salad & Dressing',},
            {title: 'Herbs', id: 1304, name: 'Fruits & Vegetables, Herbs', url: '/shopby/Fruits & Vegetables, Herbs',},
            {title: 'Fresh cut fruits & vegetables', id: 1305, name: 'Fruits & Vegetables, Fresh cut fruits & vegetables', url: '/shopby/Fruits & Vegetables, Fresh cut fruits & vegetables',},
            {title: 'Dried fruits', id: 1306, name: 'Fruits & Vegetables, Dried fruits', url: '/shopby/Fruits & Vegetables, Dried fruits',},
            {title: 'Fresh juice & smoothies', id: 1307, name: 'Fruits & Vegetables, Fresh juice & smoothies', url: '/shopby/Fruits & Vegetables, Fresh juice & smoothies',},
            //{title: 'Miscellaneous', id: 1308, name: 'Fruits & Vegetables, Miscellaneous', url: '/shopby/Fruits & Vegetables, Miscellaneous',},
            ],          
        id: 7,
        category:"Fruits & Vegetables"            
      },
      {
        image: "https://i.ibb.co/Zd2bmwS/9-Wk5-Grocery-Deli-CN-3190739-190917-1568750924949.png",
        title: "Prepared Foods",
        url: '/shopby/Prepared Foods',       
        submenu: [
            {title: 'Rotisserie & Fried chicken', id: 1001, name: 'Prepared Foods, Rotisserie & Fried chicken', url: '/shopby/Prepared Foods, Rotisserie & Fried chicken',},
            {title: 'Fresh Pasta & Sauce', id: 1002, name: 'Prepared Foods, Fresh Pasta & Sauce', url: '/shopby/Prepared Foods, Fresh Pasta & Sauce',},
            {title: 'Entrees & Appetizers', id: 1003, name: 'Prepared Foods, Entrees & Appetizers', url: '/shopby/Prepared Foods, Entrees & Appetizers',},
            {title: 'Salads & Soups', id: 1004, name: 'Prepared Foods, Salads & Soups', url: '/shopby/Prepared Foods, Salads & Soups',},
            {title: 'Sandwiches', id: 1005, name: 'Prepared Foods, Sandwiches', url: '/shopby/Prepared Foods, Sandwiches',},
            {title: 'Sushi', id: 1006, name: 'Prepared Foods, Sushi', url: '/shopby/Prepared Foods, Sushi',},
            {title: 'Fried & Sides', id: 1007, name: 'Prepared Foods, Fried & Sides', url: '/shopby/Prepared Foods, Fried & Sides',},
            {title: 'Quiches & Fies', id: 1008, name: 'Prepared Foods, Quiches & Pies', url: '/shopby/Prepared Foods, Quiches & Pies',},
            {title: 'Snacks & Dips', id: 1009, name: 'Prepared Foods, Snacks & Dips', url: '/shopby/Prepared Foods, Snacks & Dips',},
            //{title: 'Miscellaneous', id: 1010, name: 'Prepared Foods, Miscellaneous', url: '/shopby/Prepared Foods, Miscellaneous',},
        ],
        id: 8,
        category:"Prepared Foods"            
      },
      {
        title: 'Bakery',
        url: '/shopby/Bakery',
        submenu: [
          {title: 'Bagels, Buns & Rolls', id: 501, name: 'Bakery, Bagels, Buns & Rolls', url: '/shopby/Bakery, Bagels, Buns & Rolls',},
          {title: 'Cakes & Desserts', id: 502, name: 'Bakery, Cakes & Desserts', url: '/shopby/Bakery, Cakes & Desserts',},
          {title: 'Breakfast', id: 503, name: 'Bakery, Breakfast', url: '/shopby/Bakery, Breakfast',},
          {title: 'Wrap, Pitas & Flatbread', id: 504, name: 'Bakery, Wraps, Pitas & Flatbread', url: '/shopby/Bakery, Wraps, Pitas & Flatbread',},
          {title: 'Pizza Crusts & Crumbs', id: 505, name: 'Bakery, Pizza Crusts & Crumbs', url: '/shopby/Bakery, Pizza Crusts & Crumbs',},
          {title: 'Bread', id: 505, name: 'Bakery, Bread', url: '/shopby/Bakery, Bread',},
          //{title: 'Miscellaneous', id: 506, name: 'Bakery, Miscellaneous', url: '/shopby/Bakery, Miscellaneous',},
        ],
        id: 1,
        category:"Bakery"        
      }, 
      {
        image: "https://i.ibb.co/qWBBLMj/5wk4-Grocery-Cat-Navs-Pork-200507-1588873718508.png",
        title: "Meat",
        url: '/shopby/Meat',        
        submenu: [
            {title: 'Chiken & Turkey', id: 1101, name: 'Meat, Chicken & Turkey', url: '/shopby/Meat, Chicken & Turkey',},
            {title: 'Beef', id: 1102, name: 'Meat, Beef', url: '/shopby/Meat, Beef',},
            {title: 'Bacon & Sausage & hot dogs', id: 1103, name: 'Meat, Meat, Bacon & Sausage & hot dogs', url: '/shopby/Meat, Bacon & Sausage & hot dogs',},
            {title: 'Pork', id: 1104, name: 'Meat, Pork', url: '/shopby/Meat, Pork',},
            {title: 'Lamb & Veal', id: 1105, name: 'Meat, Lamb & Veal', url: '/shopby/Meat, Lamb & Veal',},
            {title: 'Kebabs & Marinated Meat', id: 1106, name: 'Meat, Kebabs & Marinated Meat', url: '/shopby/Meat, Kebabs & Marinated Meat',},
            {title: 'offals, Game Meat & fowl', id: 1107, name: 'Meat, offals, Game Meat & fowl', url: '/shopby/Meat, offals, Game Meat & fowl',},
            {title: 'Miscellaneous', id: 1108, name: 'Meat, miscellaneous', url: '/shopby/Meat, miscellaneous',},
        ],       
        id: 9,
        category:"Meat"            
      },
      {
        title: 'Fish & Sea food',
        image: 'https://i.ibb.co/g4jsdMQ/fish.png', 
        url: '/shopby/Fish & Sea food',        
        submenu: [
          {title: 'Shrimps', id: 601, name: 'Fish & Sea food, Shrimps', url: '/shopby/Fish & Sea food, Shrimps',},
          {title: 'Salmon', id: 602, name: 'Fish & Sea food, Salmon', url: '/shopby/Fish & Sea food, Salmon',},
          {title: 'Fish', id: 603, name: 'Fish & Sea food, Fish', url: '/shopby/Fish & Sea food, Fish',},
          {title: 'Smoked Fish', id: 604, name: 'Fish & Sea food, Smoked Fish', url: '/shopby/Fish & Sea food, Smoked Fish',},
          {title: 'Seafood Appetizers', id: 605, name: 'Fish & Sea food, Seafood Appetizers', url: '/shopby/Fish & Sea food, Seafood Appetizers',},
          {title: 'Shellfish', id: 606, name: 'Fish & Sea food, Shellfish', url: '/shopby/Fish & Sea food, Shellfish',},
          {title: 'Squid & Octopus', id: 606, name: 'Fish & Sea food, Squid & Octopus', url: '/shopby/Fish & Sea food, Squid & Octopus',},          
          {title: 'Miscellaneous', id: 609, name: 'Fish & Sea food, Miscellaneous', url: '/shopby/Fish & Sea food, Miscellaneous',},  
        ],
        id: 17,
        category:"Fish & Sea food"
      },  
      {
        image: "https://i.ibb.co/wzNcbMk/10wk1-Grocery-Bubcat-Canned-Packaged-Foods-200901-1598994624706.png",
        title: "International foods",
        url: '/shopby/International foods',        
        id: 12,
        category:"International foods",
        submenu: [
          {title: 'Asian', id: 601, name: 'International Foods, Asian', url: '/shopby/International Foods, Asian',},
          {title: 'European', id: 602, name: 'International Foods, European', url: '/shopby/International Foods, European',},
          {title: 'Indian & Middle Eastern', id: 603, name: 'International Foods, Indian & Middle Eastern', url: '/shopby/International Foods, Indian & Middle Eastern',},
          {title: 'Latin & Mexican', id: 604, name: 'International Foods, Latin & Mexican', url: '/shopby/International Foods, Latin & Mexican',},
          //{title: 'Miscellaneous', id: 609, name: 'International foods, miscellaneous', url: '/shopby/International foods, miscellaneous',},  
        ],           
      },
    ],
  },
];



