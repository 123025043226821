import React, {useState} from 'react'
import { ProfileSidebar, EditProfileMain } from '../Components'
import { AiOutlineUser, AiOutlineShopping, AiOutlineHeart } from "react-icons/ai";
import {  HiOutlineMenu } from "react-icons/hi";
import {NavLink} from 'react-router-dom';
//import { TiRssOutline } from 'react-icons/ti';


function EditProfile() {
  const [isOpen, setIsOpen] = useState(false);

  /*const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setIsOpen(false);
    } else {
      setIsOpen(false);
    }
  };*/

    const onMouseClick = () => {
    setIsOpen(true);
  };
  
  /*const handleDrawerToggle = () => {
    if (isOpen(true)) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };*/

  return (
    <div className='EditProfile-container'>
      <li className='menu-button-departement profile-hidden'
        onClick={onMouseClick}>
          <div className='edit-profile-mobile'>
            <div className='menu-button-profile'>
              <HiOutlineMenu className="btn-icon-profile"/>
              <h2 className='menu-btn-profile' 
              > Profile</h2> 
            </div> 
          {
            isOpen ?
            <div className='drop-down-modal-profile'>
              <ul className='header-profile-ul'>
                <li className='header-profile-li has-child'>
                  <NavLink to={`/profilesidebar/`} className='sidebar-profile'>
                        <AiOutlineUser className='Profile-sidebar-icon'/>
                        <h3 className='Profile-sidebar-title'> Account Settings</h3>
                  </NavLink>
                </li> 
                <li className='header-profile-li has-child'>
                  <NavLink to={`/orders`} className='sidebar-profile'>
                        <AiOutlineShopping className='Profile-sidebar-icon'/>
                        <h3 className='Profile-sidebar-title'> Orders</h3>
                  </NavLink>
                </li> 
                <li className='header-profile-li has-child'>
                <NavLink to={`/wishlist`} className='sidebar-profile'>
                        <AiOutlineHeart className='Profile-sidebar-icon'/>
                        <h3 className='Profile-sidebar-title'> Wishlist</h3>
                    </NavLink>
                </li>                                                
              </ul>
            </div>              
            : null
          }
          </div>
      </li>

      <div className='edit-profile-wrapper'>
        <div className='edit-profile-sidebar'>
          <ProfileSidebar/>
        </div>
        <div className='edit-profile-main'>
          <EditProfileMain/>
        </div>
      </div>
    </div>
  )
}

export default EditProfile