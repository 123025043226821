import React from 'react'

function terms() {
  return (
    <div className='terms-container'>
        <div className='terms-header'>
            <h1 className='terms-title'>Terms of Service</h1>
        </div>
        <div className='terms-main'>
            <h1 className='terms-sub-title'>Last updated: July 29, 2022</h1>
            <p className='terms-text'>
            Thank you for using ClickN! These Terms of Service (“Terms”) govern your use of the ClickN services, including any websites, mobile applications, devices, or APIs that are operated by ClickN or its subsidiaries and affiliates (collectively, the “Services”), and are entered into by you and Maplebear Inc. (d/b/a ClickN), a Delaware corporation, and its subsidiaries and affiliates (“ClickN”). This Agreement applies to all visitors, users, and other parties who access the Services (each, hereafter, a “user”).
            <br/><br/>
            By using the Services, you agree to be bound by these Terms and acknowledge and agree to the collection, use, and disclosure of your personal information in accordance with ClickN’s Privacy Policy. 
            <br/><br/>
            SECTION 15 (“DISPUTES & ARBITRATION”) OF THESE TERMS (THE “ARBITRATION AGREEMENT”) PROVIDES THAT ANY CLAIMS THAT YOU AND ClickN HAVE AGAINST EACH OTHER, INCLUDING, WITHOUT LIMITATION, ANY CLAIMS THAT AROSE OR WERE ASSERTED BEFORE THE EFFECTIVE DATE OF THESE TERMS, WILL, WITH LIMITED EXCEPTIONS, BE SUBMITTED TO BINDING AND FINAL ARBITRATION. UNLESS YOU OPT OUT OF THE ARBITRATION AGREEMENT, YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AND SEEK RELIEF AGAINST ClickN ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS 
            MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING. TO THE FULLEST EXTENT PERMITTED BY LAW, YOU ALSO WAIVE YOUR RIGHT TO SEEK RELIEF IN A COURT OF LAW AND TO HAVE A JURY TRIAL ON YOUR CLAIMS. PLEASE SEE SECTION 15 FOR MORE INFORMATION REGARDING THIS ARBITRATION AGREEMENT, THE POSSIBLE EFFECTS OF THIS ARBITRATION AGREEMENT, AND HOW TO OPT OUT OF THE ARBITRATION AGREEMENT.
            <br/><br/>
            The Services include a technology platform that presents you with a set of one or more retailer (each a “Retailer”) virtual storefronts from which you can select goods for picking and packing by one or more personal shoppers and delivery to your location or, if available, for you to pick up in-store. Depending on the Retailer from whom you purchase goods through the Services, picking and packing, and delivery services may be performed by third parties, which may include Retailer personnel, independent contractors, and 
            third-party logistics providers (collectively, “Third Party Providers”). 
            <br/><br/>
            You acknowledge that ClickN does not supervise, direct, or control the performance of services provided by Third Party Providers, and that Third Party Providers are neither employed by, nor in any partnership or joint venture or agency relationship with, ClickN.
            <br/><br/>
            When you use the Services to place an order for goods, you authorize the purchase of those goods from the Retailers you select and, if you have selected delivery services, the delivery of those goods by Third Party Providers. Unless otherwise specified, you acknowledge and agree that ClickN and the Third Party Provider are collectively acting as your agents in the ordering, picking, packing, and/or delivery of goods purchased by you and that the Retailer—not the Third Party Provider and not ClickN—is the seller 
            of the goods to you. You agree that your purchase is being made from the Retailer you have selected, that Retailer is the merchant of record, and that title to any goods passes to you when they are purchased at the applicable Retailer’s store. You agree that ClickN or the applicable Retailer will obtain an authorization for your credit card, debit card, or other payment method on file with ClickN to cover the cost of the goods you have purchased from the Retailer and any separate ClickN fees and optional tips, 
            and your payment method  will be charged for the goods purchased by you and any applicable fees, taxes and/or tips. 
            <br/><br/>
            ClickN may change the fees it charges for the Services, including but not limited to delivery fees, priority fees, service fees (including additional service fees and/or surcharges to help offset specific costs), alcohol service fees, heavy order fees, direct-to-consumer shipping fees, long distance fees (for deliveries outside the delivery area for a Retailer), and special handling fees. ClickN may vary certain fees based on demand, order attributes, and/or other factors.  Your payment instrument will be temporarily 
            authorized for an amount greater than the total amount of the purchase appearing in the original check out. This higher authorized amount will be disclosed during the purchase process and is a temporary authorization charge on your order, to deal with situations where your total purchase amount turns out to be higher than the original amount due to special requests, added items, replacement items, weight adjustments, or tips that you may elect to add after delivery. Retailers set the prices of the goods on the Services, and 
            some Retailers may set prices for goods on the Services that differ from in-store prices, differ between storefronts, or differ from the prices available on other online platforms or services. The prices displayed on the Services may not be the lowest prices at which the same goods or items are sold. Individual Retailers may operate multiple storefronts with different pricing, selection, and order fulfillment. You can view each Retailer’s pricing policies (which may change from time to time) on their storefront(s) on both the 
            website and in the ClickN app. 
            <br/><br/>
            You also acknowledge and agree that, except as expressly provided for otherwise in these Terms or a separate agreement between you and ClickN, ClickN does not form any employment or agency relationship with you and does not hold title to any goods that you order through the Services. 
            <br/><br/>
            Unless otherwise indicated, all prices and other amounts are in the currency of the jurisdiction where the delivery takes place. 
            <br/><br/>
            Occasionally there may be information on the Services that contains typographical errors, inaccuracies, or omissions that may relate to pricing, product descriptions, promotional offers, and product availability. ClickN reserves the right to correct any errors, inaccuracies or omissions and to change or update information or refuse or cancel orders if any information on the Services is inaccurate at any time without prior notice (including after you have submitted your order and/or your payment method has been charged). 
            </p>
        </div> 
        <div className='terms-main'>
            <h1 className='terms-sub-title'>1. Your Use of the Services</h1>
            <p className='terms-text'>
            ClickN grants you a limited, non-exclusive, non-transferable, and revocable license to use the Services for their intended purposes subject to your compliance with these Terms,  ClickN Community Guidelines for Customers, and any other ClickN policies. You may not copy, modify, distribute, sell, or lease any part of the Services. Unless such restriction is prohibited by law or you have ClickN’s written permission, you may not reverse engineer or attempt to extract the source code of the Services. You may only access 
            the Services through the interfaces that ClickN provides for that purpose (for example, you may not “scrape” the Services through automated means or “frame” any part of the Services), and you may not interfere or attempt to disrupt the Services. 
            <br/><br/>
            Some parts of the Services may allow you to upload or submit content (such as text, images, video, recipes, lists, links, and other materials). As between you and ClickN, you retain all rights in any content that you upload or submit, and are solely responsible for that content. By uploading content, you represent and warrant that you either own it or are authorized to grant ClickN the rights described in these Terms. You are responsible and liable if any of your content violates or infringes the intellectual property or 
            privacy rights of any third party.  You grant ClickN a non-exclusive, royalty-free, worldwide, transferable, sub licenseable, perpetual license to use, store, publicly display, publicly perform, reproduce, modify, create derivative works from, and distribute any such content for the purposes of operating, providing, and improving the Services. ClickN may, in its sole discretion, remove or take down any content that you upload or submit to the Services for any reason, including violation of these Terms, the ClickN Community 
            Guidelines for Customers, or any other policies.
            <br/><br/>
            You may have the option of accessing the Services through downloadable software and this software may update itself automatically on your device. Some software, or portions of software, in the Services may be governed by open source licenses. In that case, ClickN will make such licenses available to you and, in the case of conflict between such a license and these Terms, the open source license will control but only with respect to the software, or portion of the software, to which it applies.
            <br/><br/>
            By using the Services, you represent and warrant that you are of legal age in the jurisdiction in which you reside to form a binding contract with ClickN.  If you are using ClickN Services for its intended purposes on behalf of a business or other entity, you represent and warrant that you have the necessary authority to bind that business or entity to these Terms and that you are agreeing to these Terms on behalf of that business or entity. 
            <br/><br/>
            In order to use the Services, you may need to create a user account. You agree that you are responsible for all conduct and transactions that take place on or using your account and that you will take precautions to keep your password and other account information secure. You also agree that you will comply with all applicable laws when accessing or using the Services, you will adhere to the ClickN Community Guidelines for Customers (which may be updated from time to time) and any other applicable ClickN policies, and you will 
            respect those who you encounter in your use of the Services, including Third Party Providers, ClickN personnel, and individuals who support ClickN’s Help Center. ClickN reserves the right to decline orders, refuse partial or full delivery, terminate or restrict access to accounts or Services, and/or cancel orders at any time in its sole discretion. ClickN may periodically offer you certain incentives or benefits (e.g. trial ClickN+ membership, credit, coupon, promotion code, and/or discount code).  If ClickN suspects 
            or determines, in its sole discretion, any fraud, tampering, abuse, or violation of our Terms of Service in connection with any such offer, ClickN reserves the right to withhold, revoke, cancel, and/or terminate the incentive or benefit and to take other remedial measures.  
            <br/><br/>
            We’re constantly modifying and improving the Services. ClickN may introduce new features, change existing features, or remove features from the Services at any time and without notice. If you provide ClickN with any feedback on or comments regarding the Services, you grant ClickN the right to use such feedback or comments for any purpose without restriction or payment to you. 
            <br/><br/>
            If you have any requests for order cancellations, refunds, or returns, please visit your account to initiate such requests or review our Help Center articles for our policies regarding the same. Please note that once a shopper has begun shopping your order and/or delivery has begun, you may no longer have the option to reschedule or cancel the order. If we are able to reschedule or cancel your order, you may be charged a fee and/or may not be refunded for items that have been purchased on your behalf.
            </p>
        </div>
        <div className='terms-main'>
            <h1 className='terms-sub-title'>2. ClickN Communications</h1>
            <p className='terms-text'>
            By creating an ClickN user account, you agree to accept and receive communications from ClickN or Third Party Providers, including via email, text message, calls, and push notifications to the cellular telephone number you provided to ClickN. You understand and agree that you may receive communications generated by automatic telephone dialing systems and/or which will deliver prerecorded messages sent by or on behalf of ClickN, its affiliated companies, and/or Third Party Providers, including 
            but not limited to communications concerning orders placed through your account on the Services. Message and data rates may apply. If you do not wish to receive promotional emails, text messages, or other communications, you may opt out of such communications at any time in Your Account Settings or by using the unsubscribe mechanism included in the message, where applicable. You may also opt-out of receiving promotional text messages from ClickN by replying “STOP” from the mobile device receiving the messages.  
            <br/><br/>
            If you use the Services to order a prescription drug product (where available), you understand and agree that user inquiries must be conducted via telephone. You also understand and agree that a third party pharmacy and/or ClickN may send you unencrypted SMS messages, push notifications or other electronic notifications relating to prescription drug products that you order through the Services. There is some level of risk that information in such communications could be read by an unintended third party. By using 
            the Services to order a prescription drug product, you explicitly disclaim any liability, and waive and release any claim (to the maximum extent permitted by law), against ClickN, its affiliated companies, and Third Party Providers for any harm or damage arising out of or in connection with any SMS messages, push notifications and/or other electronic notifications. 
            </p>
        </div>
        <div className='terms-main'>
            <h1 className='terms-sub-title'>3. Transactions Involving Alcohol</h1>
            <p className='terms-text'>
            You may have the option to order alcohol products in some locations and from certain Retailers. You agree that you will comply with all applicable laws and not cause ClickN or any Third Party Provider (including any Retailer) to contravene any applicable laws. If you order alcohol products from a Retailer through the Services, you agree that you are of legal drinking age for purchasing, possessing, and consuming alcohol (i.e., 21 years of age or older in the United States, 18 years of age or older in Alberta, Manitoba, 
            and Quebec, and 19 years of age or older in all other Canadian provinces). You also agree that, upon delivery of alcohol products by the Third Party Provider, the recipient will provide to the Third Party Provider valid government-issued identification proving their age, and that Third Party Provider may scan this identification and may require a signature from the recipient.  You also agree that the recipient will not be intoxicated when receiving delivery of alcohol products, and that you will not purchase alcohol with 
            the intent to resell or provide it to someone who is not of legal drinking age. If the recipient is unable to present valid photo identification upon delivery, your order will be cancelled. You agree that if any applicable legal requirements for the delivery of alcohol are not met, ClickN reserves the right to cancel the alcohol-related portion of your order. Special requests or substitutions for the purchase of alcohol products will not be honored; all requests for the purchase of alcohol products must be made through 
            the catalog available through the Services at the time of submitting the order.   
            <br/><br/>
            Users who purchase alcohol through the Services for delivery by Third Party Providers within the State of Hawaii acknowledge that IT IS ILLEGAL: (1) FOR A PERSON UNDER 21 YEARS OLD TO PURCHASE OR CONSUME LIQUOR, (2) TO USE FALSE IDENTIFICATION TO OBTAIN LIQUOR, (3) TO USE ANOTHER PERSON’S IDENTIFICATION TO OBTAIN LIQUOR, OR (4) TO PURCHASE LIQUOR FOR A PERSON UNDER 21 YEARS OLD. (Sections 281-78 & 101.5, Hawaii Revised Statutes). 
            <br/><br/>
            All alcohol products ordered through the Services for delivery by Third Party Providers within the provinces of Ontario and Alberta will be delivered by Maplebear Delivery Canada Inc.
            </p>
        </div>  
        <div className='terms-main'>
            <h1 className='terms-sub-title'>4. Delivery of Prescription Drug Products</h1>
            <p className='terms-text'>
            In certain geographic areas, you may order prescription drug products through ClickN Rx. This service is not eligible for ClickN+ Benefits. You agree that ClickN is not a pharmacy and that we make no referrals or recommendations as to which pharmacies you should use for prescription processing. You also agree that you are solely responsible for the selection of the pharmacy dispensing the prescription. ClickN makes no warranties regarding the quality of the prescription drug products or the services provided by 
            the pharmacy. If you have any issues with the processing of your prescription, you should contact the pharmacy directly.
            <br/><br/>
            You agree that, upon delivery of prescription drug products by a Third Party Provider, the recipient will provide to the Third Party Provider valid government-issued identification proving their age, and that the Third Party Provider may scan this identification.  You also agree that the recipient will indicate their relationship to you (if the recipient is not you), and that any prescription drug product ordered through the Services has not been purchased with the intent to resell the prescription drug product or provide the 
            prescription drug product to someone other than to whom it is prescribed. 
            <br/><br/>
            You also agree that ClickN does not provide any medical advice, diagnosis or treatment, and that neither a pharmacy-patient relationship nor a physician-patient relationship exists between ClickN and you as a result of your use of the Services. We encourage you to consult with your healthcare professional or pharmacist regarding all health-related issues. 
            <br/><br/>
            By using the Services to order a prescription drug product for a child or other member of your household, you represent and warrant that it is your intention not to consume or use the medication but instead to transfer the medication to the ultimate consumer or user for their consumption. 
            <br/><br/>
            IF YOU ARE HAVING A MEDICAL EMERGENCY, CALL 911 OR CONTACT YOUR LOCAL EMERGENCY ASSISTANCE SERVICE IMMEDIATELY. 
            </p>
        </div>                                
    </div>
  )
}

export default terms