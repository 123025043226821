import React, { useState } from 'react'
import {NavLink} from 'react-router-dom'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useStateContext } from '../../context/StateContext';

const TinyCarousel = (props) => {
    const { data, title, loading } = props;
    const {onAdd} = useStateContext();
    const j = 6;
    const n = 1;

    const settings = {
        dots: false,
        autoplay: true,
        
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };

        return (
            <>
            {loading ? (
                <>
                    {[...Array(n)].map((e, i) =>  
                    <div key={i} style={{
                        maxWidth: '1200px',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}>
                        <div className='Carousel-home' style={{width: '20%', height:'30px', marginLeft: 'auto', marginRight: 'auto', marginTop: '60px', marginBottom: '30px'}}></div>
                        <div className='ProductCard-Slider-div'>
                        <Slider {...settings}>
                            {[...Array(j)].map((e, i) =>
                            <div className="ProductCard-item-list" style={{ marginBottom: '20px', width: '60px'}} key={i}>                              
                                <div className='item-car-header-carousel Carousel-home' style={{width: '80%', height:'100px'}}>                  
                                <div className="ProductCard-image Carousel-home"></div>        
                                </div>                     
                                <div className='ProductCard-details Carousel-home'style={{height: '15px', width: '80%', marginTop: '40px'}}></div>   
                                <div className='ProductCard-details Carousel-home'style={{height: '15px', width: '80%', marginTop: '10px'}}></div> 
                                <div className='ProductCard-details Carousel-home'style={{height: '15px', width: '40%', marginTop: '10px'}}></div>                            
                                <div className='product-addtocart-slider Carousel-home'style={{height: '35px'}} ></div>  
                            </div>  
                            )}                          
                        </Slider>
                        </div>  
                    </div>
                    )}
                </>
            )
            :
        (
            <div className='ProductCard-container' style={{
                maxWidth: '1200px',
                marginLeft: 'auto',
                marginRight: 'auto',
            }}>
            <div className='ProductCard-items'>                        
                <div className='ProductCard-title-header'>
                <h1 className='ProductCard-title'>{title}</h1>
                </div>
                <div className='ProductCard-Slider-div'>
                <Slider {...settings}>
                    {data?.list.map((product) => (
                    <div className="ProductCard-item-list"  key={product.category} style={{ marginBottom: '20px', width: '60px'}}>                              
                        <div className='item-car-header' >                  
                        <NavLink to={`/products/${product.id}/${product.name}`}> <img className="ProductCard-image"
                            src={product.image} alt='items'/>
                        </NavLink>
                        </div>                     
                        <div className='ProductCard-details'style={{height: '42px'}}>               
                        <p className="ProductCard-name" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}>{product.name.substring(0,40)}</p>                    
                        </div>                              
                        <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                        <p className="ProductCard-price" style={{fontSize: "13px"}}>${product.price}</p>
                        { product.sale?.old_price !== undefined ?
                            <p className='ProductCard-avg-price' style={{ fontSize: "13px", textDecoration: 'line-through'}}>${product.sale.old_price}</p> 
                        : null}  
                        </div> 
                        <button className='product-addtocart-slider' onClick={() => onAdd(product, 1)}> Add to cart</button>  
                        {/*<p className="productcard-unit" style={{fontSize: "10px", color: '#828282', marginTop:'-10px'}}>{product.unitOfSize.size} {product.unitOfSize.abbreviation} - {product.pricePerUnit}</p> */} 
                    </div>                              
                    ))}
                </Slider> 
                </div>
            </div>
            </div> 
        )}
        </> 
        )
    }


export default TinyCarousel