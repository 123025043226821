import React from "react";
import { NavLink } from "react-router-dom";
import { StoresData } from "./StoresData";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { useStateContext } from "../../context/StateContext";

function StoresFilter() {
  const { firstIndex = 0, lastIndex, setLastIndex, setFirstIndex } =
  useStateContext();
  return (
    <div className="Carousel-container">
      {firstIndex > 0 && (
        <button
          className="left-button"
          onClick={() => {
            setFirstIndex(firstIndex - 1);
            setLastIndex(lastIndex - 1);
          }}
        >
          <IoIosArrowBack />
        </button>
      )}

      <div className="Carousel-content">
        {StoresData.map((item, i) => {
          if (i >= firstIndex && i < lastIndex) {
            return (
              <div className="Categories-inner-item" key={item.id}>
                <NavLink
                  to={`/store/${item.store}`}
                  className="Categories-img-container"
                >
                  <img
                    className="Categories-img"
                    src={item.image}
                    alt={item.store}
                    key={item.id}
                  />
                </NavLink>
                <p className="Categories-title">{item.title}</p>
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>

      {lastIndex < StoresData.length && (
        <button
          className="right-button"
          onClick={() => {
            setFirstIndex(firstIndex + 1);
            setLastIndex(lastIndex + 1);
          }}
        >
          <IoIosArrowForward />
        </button>
      )}
    </div>
  );
}

export default StoresFilter;
