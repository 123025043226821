import React from 'react'

function ShopBySkeleton() {
  return (
    <>
      <div className='ShopBy-Container'>
        <div className='ShopBySkeleton_Header'>
          <div className='ShopBySkeleton_Title'> </div>
        </div>
      </div>
      <>
        <div className='ShopBy-Content-Header'>
          <div className='ShopBySkeleton-imagefit'>
            <div className="ProductCard-image"></div>
          </div>
          <div className='ShopBySkeleton-Seeall'></div>
        </div>
        <div className='ShopBy-Content-skeleton'>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
        </div> 
      </>
      <>
        <div className='ShopBy-Content-Header'>
          <div className='ShopBySkeleton-imagefit'>
            <div className="ProductCard-image"></div>
          </div>
          <div className='ShopBySkeleton-Seeall'></div>
        </div>
        <div className='ShopBy-Content-skeleton'>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
        </div> 
      </>
      <>
        <div className='ShopBy-Content-Header'>
          <div className='ShopBySkeleton-imagefit'>
            <div className="ProductCard-image"></div>
          </div>
          <div className='ShopBySkeleton-Seeall'></div>
        </div>
        <div className='ShopBy-Content-skeleton'>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
        </div> 
      </>
      <>
        <div className='ShopBy-Content-Header'>
          <div className='ShopBySkeleton-imagefit'>
            <div className="ProductCard-image"></div>
          </div>
          <div className='ShopBySkeleton-Seeall'></div>
        </div>
        <div className='ShopBy-Content-skeleton'>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
        </div> 
      </>
      <>
        <div className='ShopBy-Content-Header'>
          <div className='ShopBySkeleton-imagefit'>
            <div className="ProductCard-image"></div>
          </div>
          <div className='ShopBySkeleton-Seeall'></div>
        </div>
        <div className='ShopBy-Content-skeleton'>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
        </div> 
      </>
      <>
        <div className='ShopBy-Content-Header'>
          <div className='ShopBySkeleton-imagefit'>
            <div className="ProductCard-image"></div>
          </div>
          <div className='ShopBySkeleton-Seeall'></div>
        </div>
        <div className='ShopBy-Content-skeleton'>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
          <div className='ShopBy-Content-Content'>
            <div className="ProductCard-item-list"  style={{ marginBottom: '20px', width: '60px'}}>                              
              <div className='item-car-header' style={{ width: '80%'}}>                  
                <div className="ProductCard-image-skeleton"  style={{ width: '80%', objectFit: 'cover'}} alt='items'></div>
              </div>                     
              <div className='ProductCard-details'style={{height: '42px'}}>               
                <div className="ProductCard-name-skeleton" style={{fontSize: "13px", width: '75%', marginBottom: '20px'}}></div>                    
              </div>                             
              <div className='ProductCard-prices'style={{display:'flex', justifyContent:'space-between', width: '160px'}}>
                <div className="ProductCard-avg-price-skeleton" style={{fontSize: "13px"}}></div>
              </div> 
              <button className='product-addtocart-slider-skeleton'> Add to cart</button> 
              <button className='product-addtocart-slider-compare-skeleton'> Compare Prices</button>
            </div>                          
          </div>
        </div> 
      </>
    </>
    
  )
}

export default ShopBySkeleton