import React from 'react'
import { CarouselData } from '../Sliders/Data/CarouselData';

const PopularAisle = () => {
    return (
        <div className="aisles-container">
            <h2 className="aisles-title">Shop popular grocery aisles</h2>
            <div className="aisles-grid">
            
                {CarouselData.filter(
                    (category) => category.title.includes("Fruits & vegetables") || 
                    category.title.includes("Dairy & eggs") ||
                    category.title.includes("Meat") || category.title.includes("Fish & Sea food") ||
                    category.title.includes("Pantry food") || category.title.includes("Drinks") ||
                    category.title.includes("Frozen food" || category.title.includes("Household"))).map((category, index) => (
                    <div className="aisle-card" key={index}>
                        <img src={category.image} alt="Bakery aisle" className="aisle-image" />
                        <h3 className="aisle-name">{category.title}</h3>
                    </div>
                ))}
            </div>
        </div>
    );
};
export default PopularAisle