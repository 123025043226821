export const SliderData = [
    {
        image: "https://i.ibb.co/Gn4GPK4/Fruitsjuicy.jpg",
        title: "Refreshing Beverages",
        Description: "Find a variety selection of Beverages in a click",
        id: 1
    },
    {
        image: "https://i.ibb.co/Qnmwnm8/yummysnakss.jpg",
        title: " Yummy Snacks",
        Description: "Find a variety selection of Snaks in a click",
        id: 2
    },
    {
        image: "https://i.ibb.co/Jd0tvNV/Fruitsssssss.jpg",
        title: " Fresh Fruits",
        Description: "Find a variety selection of Fruits in a click",
        id: 3
    },
    {
        image: "https://i.ibb.co/LrRz2Hr/pantry.jpg",
        title: " Pantry Food",
        Description: "Find a variety selection of Pantry Food in a click",
        id: 3
    }    
]







