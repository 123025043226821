import {React, useState} from 'react'
import useCollapse from 'react-collapsed';
import { useStateContext } from '../../context/StateContext';
import CartItems from '.././Sliders/CartItems';
//import toast from 'react-hot-toast';
//import {useParams} from 'react-router';
import { useNavigate} from 'react-router-dom'
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";


function Section(props) {

    const config = {
        defaultExpanded: props.defaultExpanded || false,
        collapsedHeight: props.collapsedHeight || 0
    };
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);
  return (
    <div className="collapsible">
        <div className="header" {...getToggleProps()}>
            <div className="data-title">{props.title}</div>
            <div className="icon">
              {isExpanded ?
                <RiArrowDropUpLine/>
              :
                <RiArrowDropDownLine/>
              }
                
            </div>
        </div>
        <div {...getCollapseProps()}>
            <div className="content">
                {props.children}
            </div>
        </div>
    </div>
  );
}

const CARD_OPTIONS = {
	iconStyle: "solid",
	style: {
		base: {
			iconColor: "#828282",
			color: "#000",
			fontWeight: 500,
			fontFamily: "Inter, sans-serif",
			fontSize: "16px",
			fontSmoothing: "antialiased",
			":-webkit-autofill": { color: "#fce883" },
			"::placeholder": { color: "#828282" },
            border: '1px, solid, #000',
		},
		invalid: {
			iconColor: "#DE3634",
			color: "#DE3634"
		}
	}
}



function CheckoutMain() {
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [buisness, setBuisness] = useState('');
  const [zip, setZip] = useState(''); 
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [email, setEmail] = useState('');      
  const [instruction, setInstruction] = useState('');
  const [primarynumber, setPrimarynumber] = useState(''); 
  const [secondarynumber, setSecondarynumber] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);

  const {  totalPrice,  cartItems } = useStateContext();
  //const {_id} = useParams();

  const [success, setSuccess ] = useState(false)
  const stripe = useStripe()
  const elements = useElements()




  
  const navigate = useNavigate();
  const carts = { status: 'pending', items: (cartItems), address1, buisness, zip, instruction, primarynumber, secondarynumber, totalPrice}

    const handleCheckout = async () => {


    const response = await fetch('https://api.click-n.com/create_cart', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(carts),
    });

    if(response.statusCode === 500) return;
    // eslint-disable-next-line
    const data = await response.json();

    //toast.loading('Redirecting...');

    //stripe.redirectToCheckout({ sessionId: data.id });


    //navigate('/checkout/Payement/');
  }  

  /*========================================================*/

  const handleSubmit = async (e) => {
    
    e.preventDefault()
      
      const {error, paymentMethod} = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
          billing_details: {
              name: buisness,
              phone: primarynumber,
              email: email,
              address: {
                city: city,
                line1: address1,
                state: state,
                postal_code: zip
              }
            },
      })

      if(!error) {
          try {
              const {id} = paymentMethod
              
              const carts = { 
                id, 
                amount: totalPrice, 
                confirm: true, 
                currency: 'CAD'
              }

              setIsProcessing(true);
              
              const response = await fetch('https://api.click-n.com/create-payment-intent', {
                  method: 'POST',
                  headers: {
                  'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(carts),
              });


              if(response.status === 200) {
                  console.log(response.status)
                  console.log("Successful payment")
                  setSuccess(true)
              }

              const data = await response.json();

              if(response.status >= 400) {
                console.log(response.status)
                console.log(data.error.split(':').pop())
                setIsError(data.error)
            }

          } catch (error) {
              console.log("Error", error)
          }
      } else {
          console.log(error.message)

      }

      setIsProcessing(false)

      localStorage.clear();      
      navigate('/');
    }

  return (

      <div className='Checkout-main-content'>
        <div className='Checkout-main-content-inner'>
          <Section className='address-section first-secion' title=" Delivery Address" defaultExpanded="true">
            <div className='address-section-inner'>
              <div className='address'>
                <h3 className='address-section-lable'> Client full name <span className='required'>(required)</span> </h3>
                <input 
                  required
                  className='first-input' 
                  type="text"
                  name='buisness'
                  value={buisness}
                  placeholder='John Doe'
                  onChange={(e) => setBuisness(e.target.value)}
                />
              </div>
              <div className='address'>
                <h3 className='address-section-lable'> Email <span className='required'>(required)</span></h3>
                <input 
                  required
                  className='first-input' 
                  type="text"
                  name='email'
                  value={email}
                  placeholder='JohnDoe@example.com'
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>            
            </div>            
            <div className='address-section-inner'>
              <div className='address'>
                <h3 className='address-section-lable first-lable'> Address line 1 <span className='required'>(required)</span></h3>
                <input 
                  required
                  className='first-input' 
                  type="text"
                  name='address1'
                  value={address1}
                  placeholder='123 main street'
                  onChange={(e) => setAddress1(e.target.value)}
                /> 
              </div>
              <div className='address'>
                <h3 className='address-section-lable first-lable'> Address line 2 (optional)</h3>
                <input 
                  required
                  className=' first-input' 
                  type="text"
                  name='address2'
                  value={address2}
                  placeholder='123 main street'
                  onChange={(e) => setAddress2(e.target.value)}
                /> 
              </div>
            </div>
            <div className='address-section-inner'>
            <div className='address'>
              <h3 className='address-section-lable'> State <span className='required'>(required)</span></h3>
              <input 
                required
                  className='first-input' 
                  type="text"
                  name='state'
                  value={state}
                  placeholder='California'                
                  onChange={(e) => setState(e.target.value)}
              />
            </div>            
            <div className='address'>
              <h3 className='address-section-lable'> City <span className='required'>(required)</span></h3>
              <input 
                  required
                  className='first-input' 
                  type="text"
                  name='city'
                  value={city}
                  placeholder='Los Angeles'                
                  onChange={(e) => setCity(e.target.value)}
              />
            </div>          
            </div>              
            <div className='address'>
              <h3 className='address-section-lable'> Zip Code <span className='required'>(required)</span></h3>
              <input 
                  required
                  className='first-input' 
                  type="text"
                  name='zip'
                  value={zip}
                  placeholder='90001'                
                  onChange={(e) => setZip(e.target.value)}
              />
            </div>                                  
          </Section>
          <Section className='address-section' title="Contact Number">
            <div className='address-section-inner'>
              <div className='address'>
                <h3 className='address-section-lable first-lable'> Primary Number <span className='required'>(required)</span></h3>
                <input 
                  required
                  className=' first-input' 
                  type="tel" 
                  name='primarynumber'
                  value={primarynumber}
                  onChange={(e) => setPrimarynumber(e.target.value)}
                /> 
              </div>
              <div className='address'>
                <h3 className='address-section-lable first-lable'> Secondary Number (optional)</h3>
                <input 
                  className=' first-input' 
                  type="tel"
                  name='secondarynumber'
                  value={secondarynumber}
                  onChange={(e) => setSecondarynumber(e.target.value)}
                /> 
              </div>
            </div>
          </Section>

          <Section className='address-section' title="Delivery Instructions (optional)">
            <div className='address-section-inner'>
              <div className='address'>
                <h3 className='address-section-lable'> Instraction for delivery (optional)</h3>
                <input 
                  className=' instruction-input' 
                  type="text"
                  name='instruction'
                  value={instruction}
                  onChange={(e) => setInstruction(e.target.value)}
                />
              </div>
            </div>
          </Section>
          <Section className='address-section' title="Cart Items">
            <CartItems/>
          </Section>  
          <Section className='address-section' title="Payement Info">
          <h3 className='address-section-lable first-lable'> Card Information</h3>
            {!success ? 
            <form onSubmit={handleSubmit}>
                <fieldset className="FormGroup">
                    <div className="FormRow">
                        <CardElement options={CARD_OPTIONS}/>
                    </div>
                </fieldset>
                  <p className="error"> { isError } </p> 
                
                <button 
                  disabled={isProcessing 
                  || !email
                  || !address1
                  || !state
                  || !city
                  || !primarynumber
                  || !buisness
                  } 
                  
                  className='ButtonForm' onClick={() => {handleCheckout(); handleSubmit()} }>
                  {isProcessing ? 'Processing...' :
                  'Pay'
                  }
                </button>
                {!email || !address1 || !state || !city || !primarynumber || !buisness ?
                  <p className='ButtonForm-error'>Make sure all the required fields are filled</p> 
                : null }                    
            </form>
            :
          <div>
              <h4>Thank you for using ClickN</h4>
          </div> 
            }
          </Section>                       
        </div>    
      </div>
  )
}

export default CheckoutMain