import React from 'react';
import { HiPhone, HiOutlineMail, HiOutlineGlobe  } from "react-icons/hi";
import  logo  from "../../Assets/Images/Logo.png";

function Footer() {
  return (
    <div className='footer-container'>
      <div className='footer-left'>
        <div className='footer-logo'>
          <img src={logo} className="footer-logo-img" alt=''/>
        </div>
          <p className='footer-description'>
            We offers high-quality products, with a greate price range to help you save money grocery shopping.
          </p>
      </div>
      <div className='footer-center'>
        <h3 className='footer-center-title'> Useful Links </h3>
        <ul className='footer-center-list'>
          <li className='footer-center-listItem'> Home </li>
          <li className='footer-center-listItem'> About us </li>
          <li className='footer-center-listItem'> About team </li>
          <li className='footer-center-listItem'> Customer Support </li>
          <li className='footer-center-listItem'> Term of Use </li>
          <li className='footer-center-listItem'> Privacy & Security </li>                                                                
        </ul>        
      </div>
      <div className='footer-right'>
        <h3 className='footer-center-title'> Contact </h3>
        <div className='footer-center-contact'> <HiOutlineGlobe style={{marginRight:'10px'}}/> 4234 Calgary Trail NW Edemonton, AB T6J, canada</div>
        <div className='footer-center-contact'> <HiPhone style={{marginRight:'10px'}}/> +1-780-233-2181 </div>
        <div className='footer-center-contact'> <HiOutlineMail style={{marginRight:'10px'}}/> contact@clickn.com </div>                                                                               
      </div>               
    </div>
  )
}

export default Footer