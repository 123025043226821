import React from 'react';
//import "slick-carousel/slick/slick.css"; 
//import "slick-carousel/slick/slick-theme.css";
//import Slider from "react-slick";
import { CarouselData } from './Data/CarouselData'
import { useStateContext } from '../../context/StateContext';
import {IoIosArrowForward, IoIosArrowBack} from "react-icons/io";
import {useParams} from 'react-router';

function SubCategoriesSlider() {
    const {category} = useParams();
    const {firstIndex, lastIndex, setLastIndex, setFirstIndex} = useStateContext();

  return (
    <div className="Carousel-container">

        {firstIndex > 0 && (
                <button className='left-button' onClick={() => { setFirstIndex(firstIndex - 1); setLastIndex(lastIndex - 1);}}><IoIosArrowBack/></button>
        )}
        {/* eslint-disable-next-line */}
    {CarouselData.filter((products) => products.category === category).map((products, i) => {

        <div className="Carousel-content">
                {products.subCategory.map((subItem) => {
                    if (i >= firstIndex && i < lastIndex) {
                        return (                    
                            <div className="image-wrapper" key={subItem.id}>
                                <img className="Carousel-img"  src={subItem.image} key={subItem.id} alt={subItem.title}/>
                                <p className="Carousel-text"> {subItem.title}</p> 
                            </div>
                        );
                    } else {
                        return null;
                    }
            })}            
        </div>

    })} 
        {lastIndex < CarouselData.length && (               
            <button className='right-button' onClick={() => { setFirstIndex(firstIndex + 1); setLastIndex(lastIndex + 1);}}><IoIosArrowForward/></button>   
        )}  

    </div>
  )
}

export default SubCategoriesSlider