import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React from "react"
import PaymentForm from "./PaymentForm"

const PUBLIC_KEY = "pk_test_51LQrEwHiUlHcQpgzU0LtsqEUxgrOhvYwRhwlHypTEINfMyaAvQg9keEKvRhmsVynUxF8B9x6dh4g94Cz62JDvBGQ00JnhEskxv"

const stripeTestPromise = loadStripe(PUBLIC_KEY)

export default function StripeContainer() {
	return (
		<Elements stripe={stripeTestPromise}>
			<PaymentForm />
		</Elements>
	)
}