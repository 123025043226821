import { React, useState, useEffect } from "react";
import { useStateContext } from "../context/StateContext";
import { StoresData } from "../Components/Filters/StoresData";
import { CarouselData } from "../Components/Sliders/Data/CarouselData";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router";
import {
  RiHeartLine,
  RiArrowDropRightLine,
  RiArrowDropDownLine,
  RiArrowDropUpLine,
  RiAddLine,
} from "react-icons/ri";
import { AiOutlineMinus } from "react-icons/ai";
import useCollapse from "react-collapsed";
import ReactPaginate from "react-paginate";
import { CategoryPageSkeleton, Shop } from "../Components";

function Section(props) {
  const config = {
    defaultExpanded: props.defaultExpanded || false,
    collapsedHeight: props.collapsedHeight || 0,
  };
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);
  return (
    <div className="collapsible">
      <div className="header" {...getToggleProps()}>
        <div className="data-title-side">{props.title}</div>
        <div className="icon">
          {isExpanded ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
        </div>
      </div>
      <div {...getCollapseProps()}>
        <div className="content">{props.children}</div>
      </div>
    </div>
  );
}

function CategoryPage() {
  const { onAdd } = useStateContext();
  const [qtyState, setQtyState] = useState({});
  const [shop, setShop] = useState(false);
  const [currentQty, setCurrentQty] = useState(1);
  const [items, setItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  let limit = 40;
  const { category, store } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);
  const encodedCategory = encodeURIComponent(category);
  const categoryStr = category.substring(0, category.indexOf(","));
  const subCategoryStr = category.substring(category.indexOf(",") + 1);

  useEffect(() => {
    const getComments = async () => {
      setIsLoading(true);
      const res = await fetch(
        `https://api.click-n.com/find?store=${store}&category=${encodedCategory}&pages=0&items=${limit}`
      );
      const data = await res.json();
      const total = data.count;
      setPageCount(Math.ceil(total / limit));
      setItems(data.list);
      setIsLoading(false);
    };

    getComments();
  }, [store, encodedCategory, limit]);

  const fetchComments = async (page) => {
    const res = await fetch(
      `https://api.click-n.com/find?store=${store}&category=${encodedCategory}&pages=${page}&items=${limit}`
    );
    const data = await res.json();
    return data.list;
  };

  const handlePageClick = async (data) => {
    setIsLoading(true);
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
    const commentsFromServer = await fetchComments(selectedPage);
    setItems(commentsFromServer);
    setIsLoading(false);
  };

  useEffect(() => {
    const initialQtyState = {};
    items.forEach((product) => {
      initialQtyState[product.id] = 1;
    });
    setQtyState(initialQtyState);
  }, [items]);

  const incQty = (id) => {
    setQtyState((prevState) => ({
      ...prevState,
      [id]: prevState[id] + 1,
    }));
    setCurrentQty((prevState) => prevState + 1);
  };

  const decQty = (id) => {
    setQtyState((prevState) => ({
      ...prevState,
      [id]: prevState[id] > 1 ? prevState[id] - 1 : 1,
    }));
    setCurrentQty((prevState) => (prevState > 1 ? prevState - 1 : 1));
  };

  const handleShop = () => {
    setShop(true);
  };

  return (
    <>
      <div className="BreadCrumbs" style={{ marginTop: "170px" }}>
        <NavLink to={`/`}>
          {" "}
          <h2 className="Path"> Home </h2>{" "}
        </NavLink>{" "}
        <RiArrowDropRightLine />
        <NavLink to={`/shopby/${category}`}>
          {" "}
          <h2 className="Path"> Shop By </h2>{" "}
        </NavLink>{" "}
        <RiArrowDropRightLine />
        {categoryStr.length !== 0 ? (
          <>
            <NavLink to={`/category/${categoryStr}/${store}`}>
              {" "}
              <h2
                className="Path"
                onClick={() =>
                  (window.location = `/category/${categoryStr}/${store}`)
                }
              >
                {" "}
                {categoryStr}{" "}
              </h2>{" "}
            </NavLink>{" "}
            <RiArrowDropRightLine />
          </>
        ) : null}
        <NavLink to={`/category/${subCategoryStr}/${store}`}>
          {" "}
          <h2
            className="Page"
            onClick={() =>
              (window.location = `/category/${subCategoryStr}/${store}`)
            }
          >
            {" "}
            {subCategoryStr}
          </h2>{" "}
        </NavLink>
      </div>
      <div className="category-page-container">
        <div className="filterside">
          <div className="filter-sidebar-container">
            <div className="filter-store-logo">
              {StoresData.filter((img) => img.store === store).map(
                (img, index) => (
                  <div className="img-container-cat" key={index}>
                    <img
                      className="ProductCard-image-cat"
                      src={img.image}
                      style={{ width: "80%" }}
                      alt="items"
                    />
                  </div>
                )
              )}
            </div>
            {/*<div className='filter-sidebar-header'>
              <h2 className='filter-result-title'> Search by fitlers</h2>
            </ div>*/}
            <div className="filter-sidebar-item">
              <h1
                className="data-title-side"
                onClick={handleShop}
                style={{ cursor: "pointer" }}
              >
                Shop
              </h1>
              {CarouselData.map((item, index) => (
                <Section
                  className="Filter-section-collapce"
                  title={item.title}
                  key={index}
                >
                  <div className="filter-sidebar-title">
                    {/*<span>
                      <div className='filter-sidebar-flex' key={item.id}>
                        <button className='filter-sidebar-span' key={item.id} onClick={() => window.location = `/category/${item.name}/${store}`} style={{color: '#0AAD0A'}}>{item.title} </button>
                        {/*<RiArrowDownSLine className='icon-sidebar-span'/>
                      </div>
                    </span>*/}
                  </div>
                  <div
                    className="filter-sidebar-content"
                    style={{ overflow: "hidden" }}
                  >
                    {item.subCategory.map((subItem, index) => (
                      <span key={index}>
                        <div className="filter-sidebar-flex" key={subItem.id}>
                          <p
                            className="filter-sidebar-span"
                            onClick={() =>
                              (window.location = `/category/${subItem.name}/${store}`)
                            }
                          >
                            {subItem.title}{" "}
                          </p>
                        </div>
                      </span>
                    ))}
                  </div>
                </Section>
              ))}
            </div>
          </div>
        </div>
        {shop ? (
          <div>
            <div className="item-container" style={{ marginTop: "50px" }}>
              <Shop />
            </div>
          </div>
        ) : (
          <div style={{ width: "100%" }}>
            <div className="CategoryPage-category">
              <h1 className="CategoryPage-category-title">{category}</h1>
            </div>
            {isLoading ? (
              <CategoryPageSkeleton />
            ) : (
              <div className="item-container" style={{ marginTop: "50px" }}>
                {items.length > 0 ? (
                  <>
                    <div className="item-container">
                      {items.map((product, index) => (
                        <div className="item-card" key={index}>
                          <div className="item-card-div">
                            <div
                              className="wishlist-button"
                              title="wishlist-button"
                            >
                              <div className="sales-div">
                                {product.sale?.old_price !== undefined &&
                                product.sale?.old_price > product.price ? (
                                  <span className="sales-span-banner">
                                    {" "}
                                    Save Up to{" "}
                                    {Math.round(
                                      ((1 -
                                        product.price /
                                          product.sale.old_price) *
                                        100 *
                                        100) /
                                        100
                                    )}{" "}
                                    %
                                  </span>
                                ) : null}
                              </div>
                              <RiHeartLine className="sales-span-icon" />
                            </div>
                            {/*<div className="wishlist-button" title="wishlist-button">
                              <div className="sales-div">
                                { product.sale?.old_price !== undefined && product.sale?.old_price > product.price?
                                  <span className='sales-span-banner'> Save Up to $ {product.sale.old_price - product.price } </span>
                                : null}
                              </div>                  
                            </div>  */}
                            <div className="item-car-header">
                              <NavLink
                                to={`/products/${
                                  product.id
                                }/${encodeURIComponent(`${product.name}`)}`}
                              >
                                {" "}
                                <img
                                  className="item-image"
                                  src={product.image}
                                  alt="items"
                                  onClick={() =>
                                    (window.location = `/products/${
                                      product.id
                                    }/${encodeURIComponent(`${product.name}`)}`)
                                  }
                                />
                              </NavLink>
                            </div>
                            <span>{product.category}</span>
                            <div className="details-div">
                              <div className="item-prices">
                                <p className="product-price">
                                  ${product.price}
                                </p>
                                {product.sale?.old_price !== undefined &&
                                product.sale?.old_price > product.price ? (
                                  <p className="product-avg-price">
                                    ${product.sale.old_price}
                                  </p>
                                ) : null}
                              </div>
                              <div className="item-details">
                                <p className="item-name">
                                  {product.name.substring(0, 40)}{" "}
                                  <span className="item-unit">
                                    {product.measurement}
                                  </span>
                                </p>
                              </div>
                              <div className="item-prices">
                                <p className="product-store">{product.store}</p>
                              </div>
                            </div>
                          </div>
                          <div className="quantity-addtocart">
                            <div className="quantity-desc-addtocart">
                              <button
                                className="minus"
                                onClick={() => decQty(product.id)}
                              >
                                <AiOutlineMinus />
                              </button>
                              <span className="num">
                                {qtyState[product.id]}
                              </span>
                              <button
                                className="plus"
                                onClick={() => incQty(product.id)}
                              >
                                <RiAddLine />
                              </button>
                            </div>
                          </div>
                          <button
                            className="product-addtocart"
                            onClick={() => onAdd(product, qtyState[product.id])}
                          >
                            {" "}
                            Add to cart
                          </button>
                          <NavLink
                            to={`/compare/${product.id}/${product.category}/${product.store}`}
                          >
                            <button className="product-addtocart-slider-compare-width">
                              {" "}
                              Compare Prices
                            </button>
                          </NavLink>
                        </div>
                      ))}
                    </div>
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      forcePage={currentPage} // Set the current active page
                    />
                  </>
                ) : (
                  <div className="item-container">
                    <div style={{ width: "100%" }}>
                      <p>
                        No products found, please check back later or browse
                        other categories or stores for items of interest.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default CategoryPage;
