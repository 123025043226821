import React from 'react'
//import {  HiOutlineMenu } from "react-icons/hi";
import {NavLink} from 'react-router-dom';
//import {ProductData} from '../Sliders/Data/ProductData';
//import {CarouselData} from '../Sliders/Data/CarouselData';
//import DropdownButton from './Dropdown/DropdownButton';
import AddressFetcher from './AddressFetcher';
import { menuItems } from './Dropdown/menuItems';
import { secondMenuItems } from './Dropdown/secondMenuItems';
import MenuItems from './Dropdown/DropdownComponents/MenuItems';


function Header() {

  //const [isOpen, setIsOpen] = useState(false);

  // eslint-disable-next-line
  {/*const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setIsOpen(false);
    } else {
      setIsOpen(false);
    }
  };*/}



  return (
    <div className="header-main">
      <div className="list-container">
        <ul className="desktop-menu-category-list">

          {/*<li className='menu-button-departement'
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >

            <div className='menu-button'>
              <HiOutlineMenu className="btn-icon"/>
              <NavLink to={`/`} className='menu-btn' 
              > Departement</NavLink> 
            </div>
            {
                isOpen ?
            <div className='drop-down-modal-departement'>
              {CarouselData.map((item )=> (
                <ul className='header-departement-ul'>
                  <li className='header-departement-li has-child'>
                    <NavLink to={`/category/${item.category}`} ClassName='header-departement-info' onClick={() => window.location = `/category/${item.category}`}>
                      <div className='header-departement-modal' key={item.id}>
                        <img className='header-departement-img' src={item.image}  key={item.id} alt={item.title}/> 
                        <p className='header-departement-title'>{item.title}</p>
                      </div>
                    </NavLink>
                  </li>                
                </ul>
                ))}
            </div>  
                : null
                  }
                </li> */}

          {/*<li className="menu-category">
            <a href="#" className="menu-title">Stores</a>

                </li>*/}

          <ul className="menus">
            {menuItems.map((menu, index) => {
              const depthLevel = 0;
              return (
                <MenuItems
                  items={menu}
                  key={index}
                  depthLevel={depthLevel}
                />
              );
            })}
          </ul>

          <ul className="menus" style={{marginLeft: '2px'}}>
            {secondMenuItems.map((menu, index) => {
              const depthLevel = 0;
              return (
                <MenuItems
                  items={menu}
                  key={index}
                  depthLevel={depthLevel}
                />
              );
            })}
          </ul>   

          <li className="menu-category">
            <NavLink to={`/challenge`} className="menu-title" style={{color: '#fff'}}>Challenge</NavLink>
          </li>

          <li className="menu-category">
            <NavLink to={`/version-2`} className="menu-title" style={{color: '#fff'}}>Version 2</NavLink>
          </li>          

          <li className="menu-category unclicable">
            <NavLink to={`/`} className="menu-title">Savings</NavLink>
          </li>

          <li className="menu-category unclicable">
            <NavLink to={`/`} className="menu-title">Repeat Order</NavLink>
          </li>

          <li className="menu-category unclicable">
            <NavLink to={`/`} className="menu-title">Favourites</NavLink>
          </li>

          <li className="menu-category unclicable">
            <NavLink to={`/`} className="menu-title">Blog</NavLink>
          </li>

      
        </ul>
        <AddressFetcher className='fetcher-header'/>
      </div>
    </div>
  )
}

export default Header;