// Component List:
// ====================================================== //

// Authentification
export { default as Login} from './Authentification/Login';
export { default as Register} from './Authentification/Register';
export { default as Recover} from './Authentification/Recover';

// Filters
export {default as FiltersSidebar} from './Filters/FiltersSidebar';
export {default as CategoriesFilter} from './Filters/CategoriesFilter';
export {default as StoresFilter} from './Filters/StoresFilter';

// Products
export { default as Product } from './Products/Product';
export { default as Items } from './Products/Items';
export { default as Subcategories } from './Products/Subcategories';
export {default as MarqueeProducts} from './Products/MarqueeProducts';
export { default as Shop} from './Products/Shop';
export { default as PopularAisle} from './Products/PopularAisle'

//Shared Components
export { default as Cart } from './SharedComponents/Cart'; 
export { default as Navbar } from './SharedComponents/Navbar';
export { default as Header } from './SharedComponents/Header';
export { default as Sidebar } from './SharedComponents/Sidebar';
export { default as Footer } from './SharedComponents/Footer';
export { default as SearchBar } from './SharedComponents/SearchBar/SearchBar';
export { default as ProductSearch } from './SharedComponents/SearchBar/ProductSearch';
export { default as Newsletter } from './SharedComponents/Newsletter';
export { default as AddressFetcher} from './SharedComponents/AddressFetcher';


//Sliders
export { default as Carousel } from './Sliders/Carousel';
export { default as Slider } from './Sliders/Slider';
export { default as ProductCard } from './Sliders/ProductCard';
export { default as CartItems } from './Sliders/CartItems';
export { default as SubCategoriesSlider} from './Sliders/SubCategoriesSlider';
export { default as AdsBanner } from './Sliders/AdsBanner';
export {default as ItemsSlider} from './Sliders/ItemsSlider'; // This One
export { default as TinyCarousel} from './Sliders/TinyCarousel'

//Profile
export { default as ProfileSidebar } from './Profile/ProfileSidebar';
export { default as EditProfileMain } from './Profile/EditProfileMain';

//Checkout
export { default as CheckoutSide } from './Checkout/CheckoutSide';
export { default as CheckoutMain } from './Checkout/CheckoutMain';
export { default as StripeContainer} from './Checkout/StripeContainer';

//Admin Page
export { default as OrderList} from './Admin/OrderList';
export { default as DetailsOrders} from './Admin/DetailsOrders';
export { default as FetchedData} from './Admin/FetchedData';

//Stores
export { default as SubcategoryStores} from './Stores/SubcategoryStores'

//Skeleton
export { default as CategoryPageSkeleton } from './Skeleton/CategoryPageSkeleton';
export { default as ComparePricesSkeleton } from './Skeleton/ComparePricesSkeleton';
export { default as ProductSkeleton } from './Skeleton/ProductSkeleton';
export { default as ShopBySkeleton } from './Skeleton/ShopBySkeleton';
export { default as HomePageSkeleton} from './Skeleton/HomePageSkeleton';
export { default as VersionTwoSkeleton} from './Skeleton/VersionTwoSkeleton'
