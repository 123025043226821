import  {React} from 'react'
import { useStateContext } from '../../context/StateContext'
//import {ProductData} from '../Sliders/Data/ProductData';
//import {useParams} from 'react-router';
import {CarouselData} from '../Sliders/Data/CarouselData'

function FiltersSidebar() {

  //const {categoryName} = useParams();
  // eslint-disable-next-line
  const { filters, filterProduct} = useStateContext();
 
  return (
    <div className='filter-sidebar-container'>
      <div className='filter-sidebar-header'>
        <h2 className='filter-result-title'> Search by fitlers</h2>
      </ div>
      <div className='filter-sidebar-body'>
        <div className='filter-sidebar-body-item-category-body'>
          <h3 className='filter-sidebar-title'> Category:</h3>
          {CarouselData.map((item) => (
            <div>
                <div className='filter-sidebar-body-item-category-list' >
                {item.subCategory !== undefined ?
                <>
                {item.subCategory.map((subItem) => (  
                  <div>                  
                    <input className='filter-sidebar-checkbox' value={subItem.title} onClick={()=>filterProduct(subItem.name)} type="checkbox" />
                    <span className='filter-sidebar-span'>{subItem.title}</span>
                  </div>
                   ))} 
                </>
                : null}
                </div>
            </div>          
          ))}
        </div>
      </div>
    </div>
  )
}

export default FiltersSidebar