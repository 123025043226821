import React from 'react'
import { HiOutlineMail, HiOutlineLockClosed, HiOutlineEyeOff, HiX } from "react-icons/hi";
import { useStateContext} from '../../context/StateContext';


function Login() {
    const {  setShowRegister, setShowRecover, setShowLogin } = useStateContext();
    
    const handleSwitch = () => {
        setShowLogin(false)  
        setShowRegister(true)
    }

    const recoverSwich = () => {
        setShowLogin(false)  
        setShowRecover(true)
    }






  return (

    <div className="Authentification">
        <div className='AuthContainer'>
            <div className='Authforms'>
                <div className='Authform login'>
                    <div className='authentification-title'>
                        < span className='formTitle'>Login</span>
                        <button className='authentification-close' onClick={() => setShowLogin(false)}>
                                <HiX/>
                        </button>
                    </div>
                    <form action='#'>
                        <div className='input-field'>
                            <input 
                            type='text' 
                            placeholder='Enter your email'
                            value='email' 
                            required/>
                            <HiOutlineMail className="input-icon left-icon" />
                        </div>
                        <div className='input-field'>
                            <input 
                            type='password' 
                            placeholder='Enter your password' 
                            value='password'
                            required/>
                            <HiOutlineLockClosed className="input-icon left-icon"/>
                            <HiOutlineEyeOff className="input-icon showHidePw"/>
                        </div>  
                        <div className='checkbox-text'>
                            <div className='checkbox-content'>
                                <input type='checkbox' id='logcheck'/>
                                <label htmlFor='logcheck' className='text-check'>Remember me</label>
                            </div>
                            <span className='forgot-pw' onClick={() => recoverSwich(true)}>Forgot password?</span>
                        </div>
                        <div className='input-field button'>
                            <input type='button' value={'Login Now'}/>                            
                        </div>                  
                    </form>
                    <div className='login-signup'>
                        <span className='text-check'>Don't have an account? </span>
                        <span className='text-check signup-text' onClick={() => handleSwitch(true)}>Sign up</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Login