import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import TinyCarousel from '../Sliders/TinyCarousel';

const Shop = () => {

   const [healthData, setHealthData] = useState();
    const [FruitsData, setFruitsData] = useState();
    const [VegetablesData, setVegetablesData] = useState();
    const [petsData, setPetsData] = useState();  
    //const {onAdd} = useStateContext();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
  
    const {store} = useParams();
    const health = encodeURIComponent('Health & Beauty, Cosmetics')
    const fruits = encodeURIComponent('Fruits & Vegetables, Fresh Fruits')
    const Vegetables = encodeURIComponent('Fruits & Vegetables, Fresh Vegetables')    
    const pets = encodeURIComponent('Pets Supplies')

  
    const urls = [
      `https://api.click-n.com/find?&store=${store}&category=${health}&items=80`,
      `https://api.click-n.com/find?&store=${store}&category=${fruits}&items=80`,
      `https://api.click-n.com/find?&store=${store}&category=${Vegetables}&items=80`,      
      `https://api.click-n.com/find?&store=${store}&category=${pets}&items=80`,   
    ];
  
    const fetchData = async () => {
      setIsLoading(true);
      const requests = urls.map(url => fetch(url));
      try {
        const responses = await Promise.all(requests)
        const data = await Promise.all(responses.map(response => response.json()));
        setHealthData(data[0]);
        console.log('this is the data needed ', data[0])
        setFruitsData(data[1]);
        setVegetablesData(data[2]); 
        setPetsData(data[3]);                 
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setError(error);
        setIsLoading(false);
      }
    }

    
    useEffect(() => {
      fetchData();
      // eslint-disable-next-line
    }, []);
    
    return (
        <div style={{
            maxWidth: '1200px',
            marginLeft: 'auto',
            marginRight: 'auto',
        }}>
        {error && <div>An error occurred: {error.message}</div>}
          <>
            {healthData && <TinyCarousel data={healthData} title='Health & Beauty' loading={isLoading}/>}
            {FruitsData && <TinyCarousel data={FruitsData} title='Fresh Fruits' loading={isLoading}/>}
            {VegetablesData && <TinyCarousel data={VegetablesData} title='Fresh Vegetables' loading={isLoading}/>}
            {petsData && <TinyCarousel data={petsData} title='Pets Supplies' loading={isLoading}/>}
          </>
      </div>
    );
};

export default Shop