import React from 'react'

function privacy() {
  return (
    <div className='terms-container'>
    <div className='terms-header'>
        <h1 className='terms-title'>Privacy Policy</h1>
    </div>
    <div className='terms-main'>
        <h1 className='terms-sub-title'>I. Introduction and Overview</h1>
        <p className='terms-text'>
        Thank you for using the Instacart Services! We are committed to providing you the best online shopping and delivery experience possible. This Privacy Policy explains what information Maplebear Inc. d/b/a Instacart (“Instacart”, “we”, or “us”) collects, how that information is used, under what circumstances we share information, and the choices you can make about that information.  
        <br/><br/>
        This Privacy Policy applies whether you access the Instacart Services (as defined in our Terms of Service) through a browser, a mobile application, or any other method.
        <br/><br/>
        "Personal Information" or "PI" is information about you that may be used to identify you (such as your name, phone number, or address). Personal Information may also include health or medical information that relates to (a) your past, present, or future physical or mental health or condition, (b) the provision of health care to you, or (c) your past, present, or future payment for the provision of health care (“Personal Health Information” or “PHI”).
        <br/><br/>

        <h4 className='terms-additional-disclosures-title'>Additional Disclosures </h4>
        <p className='terms-text'>
        We may provide different or additional disclosures relating to the processing of Personal Information of individuals who are residents of certain countries, regions or states. Listed below are additional disclosures that may be applicable to you.
        </p>
        <ul className='terms-unordered-list'>
            <li className='terms-list-item'> 
                If you are a California resident, please see the additional privacy disclosures below in Disclosure For California Residents.
            </li>
            <li className='terms-list-item'> 
                If you are a Nevada resident, please see the additional privacy disclosures below in Disclosure For Nevada Residents.
            </li> 
            <li className='terms-list-item'> 
                If you are a resident of Canada, please see the additional privacy disclosures in Disclosure For Canada Residents.
            </li>                        
        </ul>
        </p>
    </div>
    <div className='terms-main'>
        <h1 className='terms-sub-title'>II. Information We Collect</h1>
        <h4 className='terms-additional-disclosures-title'>A. Information You Provide to Us or Allow Others to Provide to Us</h4>
        <p className='terms-text'>
        By creating an ClickN user account, you agree to accept and receive communications from ClickN or Third Party Providers, including via email, text message, calls, and push notifications to the cellular telephone number you provided to ClickN. You understand and agree that you may receive communications generated by automatic telephone dialing systems and/or which will deliver prerecorded messages sent by or on behalf of ClickN, its affiliated companies, and/or Third Party Providers, including 
        but not limited to communications concerning orders placed through your account on the Services. Message and data rates may apply. If you do not wish to receive promotional emails, text messages, or other communications, you may opt out of such communications at any time in Your Account Settings or by using the unsubscribe mechanism included in the message, where applicable. You may also opt-out of receiving promotional text messages from ClickN by replying “STOP” from the mobile device receiving the messages.  
        <br/><br/>
        If you use the Services to order a prescription drug product (where available), you understand and agree that user inquiries must be conducted via telephone. You also understand and agree that a third party pharmacy and/or ClickN may send you unencrypted SMS messages, push notifications or other electronic notifications relating to prescription drug products that you order through the Services. There is some level of risk that information in such communications could be read by an unintended third party. By using 
        the Services to order a prescription drug product, you explicitly disclaim any liability, and waive and release any claim (to the maximum extent permitted by law), against ClickN, its affiliated companies, and Third Party Providers for any harm or damage arising out of or in connection with any SMS messages, push notifications and/or other electronic notifications. 
        </p>
    </div>
    <div className='terms-main'>
        <h1 className='terms-sub-title'>3. Transactions Involving Alcohol</h1>
        <p className='terms-text'>
        You may have the option to order alcohol products in some locations and from certain Retailers. You agree that you will comply with all applicable laws and not cause ClickN or any Third Party Provider (including any Retailer) to contravene any applicable laws. If you order alcohol products from a Retailer through the Services, you agree that you are of legal drinking age for purchasing, possessing, and consuming alcohol (i.e., 21 years of age or older in the United States, 18 years of age or older in Alberta, Manitoba, 
        and Quebec, and 19 years of age or older in all other Canadian provinces). You also agree that, upon delivery of alcohol products by the Third Party Provider, the recipient will provide to the Third Party Provider valid government-issued identification proving their age, and that Third Party Provider may scan this identification and may require a signature from the recipient.  You also agree that the recipient will not be intoxicated when receiving delivery of alcohol products, and that you will not purchase alcohol with 
        the intent to resell or provide it to someone who is not of legal drinking age. If the recipient is unable to present valid photo identification upon delivery, your order will be cancelled. You agree that if any applicable legal requirements for the delivery of alcohol are not met, ClickN reserves the right to cancel the alcohol-related portion of your order. Special requests or substitutions for the purchase of alcohol products will not be honored; all requests for the purchase of alcohol products must be made through 
        the catalog available through the Services at the time of submitting the order.   
        <br/><br/>
        Users who purchase alcohol through the Services for delivery by Third Party Providers within the State of Hawaii acknowledge that IT IS ILLEGAL: (1) FOR A PERSON UNDER 21 YEARS OLD TO PURCHASE OR CONSUME LIQUOR, (2) TO USE FALSE IDENTIFICATION TO OBTAIN LIQUOR, (3) TO USE ANOTHER PERSON’S IDENTIFICATION TO OBTAIN LIQUOR, OR (4) TO PURCHASE LIQUOR FOR A PERSON UNDER 21 YEARS OLD. (Sections 281-78 & 101.5, Hawaii Revised Statutes). 
        <br/><br/>
        All alcohol products ordered through the Services for delivery by Third Party Providers within the provinces of Ontario and Alberta will be delivered by Maplebear Delivery Canada Inc.
        </p>
    </div>  
    <div className='terms-main'>
        <h1 className='terms-sub-title'>4. Delivery of Prescription Drug Products</h1>
        <p className='terms-text'>
        In certain geographic areas, you may order prescription drug products through ClickN Rx. This service is not eligible for ClickN+ Benefits. You agree that ClickN is not a pharmacy and that we make no referrals or recommendations as to which pharmacies you should use for prescription processing. You also agree that you are solely responsible for the selection of the pharmacy dispensing the prescription. ClickN makes no warranties regarding the quality of the prescription drug products or the services provided by 
        the pharmacy. If you have any issues with the processing of your prescription, you should contact the pharmacy directly.
        <br/><br/>
        You agree that, upon delivery of prescription drug products by a Third Party Provider, the recipient will provide to the Third Party Provider valid government-issued identification proving their age, and that the Third Party Provider may scan this identification.  You also agree that the recipient will indicate their relationship to you (if the recipient is not you), and that any prescription drug product ordered through the Services has not been purchased with the intent to resell the prescription drug product or provide the 
        prescription drug product to someone other than to whom it is prescribed. 
        <br/><br/>
        You also agree that ClickN does not provide any medical advice, diagnosis or treatment, and that neither a pharmacy-patient relationship nor a physician-patient relationship exists between ClickN and you as a result of your use of the Services. We encourage you to consult with your healthcare professional or pharmacist regarding all health-related issues. 
        <br/><br/>
        By using the Services to order a prescription drug product for a child or other member of your household, you represent and warrant that it is your intention not to consume or use the medication but instead to transfer the medication to the ultimate consumer or user for their consumption. 
        <br/><br/>
        IF YOU ARE HAVING A MEDICAL EMERGENCY, CALL 911 OR CONTACT YOUR LOCAL EMERGENCY ASSISTANCE SERVICE IMMEDIATELY. 
        </p>
    </div>                                
</div>
  )
}

export default privacy